import _ from 'lodash';
import { ElfElementTypes } from '@/types/slides';
import { VIEWPORT_SIZE } from '@/configs/canvas';
import { findElfByType } from '@/views/components/element/ElfElement/register';
import { useStore } from '@/store';
import { computed } from 'vue';

export default () => {
  const store = useStore();
  const viewportRatio = computed(() => store.state.viewportRatio);
  let count = 1;
  /**
   *
   * @param offsetX // 上一次的横偏移量
   * @param offsetY // 上一次的纵偏移量
   * @returns // 返回这次的偏移量
   */
  const calculateInsertPosition = (
    offsetX: number,
    offsetY: number,
    type: ElfElementTypes | string
  ) => {
    const config = _.cloneDeep(findElfByType(type));
    if (config) {
      if (config.initData.width <= 0) {
        config.initData.width = VIEWPORT_SIZE;
      }
      if (config.initData.height <= 0) {
        config.initData.height = VIEWPORT_SIZE * viewportRatio.value;
      }
    }
    let left = offsetX;
    let top = offsetY;
    if (config) {
      if (
        top + 50 >=
        VIEWPORT_SIZE * viewportRatio.value - config.initData.height
      ) {
        top = 0;
        left = count * 100;
        count++;
      }
    }
    return {
      left: left + 50,
      top: top + 50,
    };
  };

  return {
    calculateInsertPosition,
  };
};
