import {
	PPTImageElement,
	PPTElementOutline,
	PPTElementShadow,
	ImageElementClip
} from '@/types/slides'
import { createRandomCode } from '@/utils/common'
import _ from 'lodash'
import { getPosition, getThemeColor } from '../../attribute'
import useCompress from '@/hooks/useCompress'
import logger from '@evideo/logger'

const noSupportedImageType = ['emf', 'wmf']
const { compressImage } = useCompress()

const getImageData = async (
	slideIndex: number,
	pic: any,
	slideRelsObject: any,
	pptxFiles: any,
	upload: any
) => {
	const blip = pic?.blipFill?.blip
	const embedId = blip.attributes['r:embed']
	if (!embedId) {
		return
	}

	const relationships = slideRelsObject.Relationships.Relationship || []
	const imageRels = relationships.find((r: any) => r.attributes.Id === embedId)
	if (!imageRels) {
		return
	}

	const imagePath = imageRels.attributes.Target
	const image = pptxFiles[`ppt${imagePath.replace('..', '')}`]

	if (!image) {
		return
	}
	try {
		const imageArrayBuffer = await image.async('arraybuffer')
		const imageTypeArray = image.name.split('.')
		const imageType = `image/${imageTypeArray[imageTypeArray.length - 1]}`
		if (
			noSupportedImageType.indexOf(
				imageTypeArray[imageTypeArray.length - 1]
			) !== -1
		) {
			return 'noSupportImageType'
		}

		const imageBlob = new Blob([imageArrayBuffer], { type: imageType })

		const imageName = image.name.split('/').reverse()[0]
		let imageFile = new File([imageBlob], imageName, { type: imageType })
		if (imageFile && imageFile?.size > 1024 * 1024) {
			// 图片大小大于1M，压缩成700kb
			imageFile = await compressImage(imageFile, 700)
		}

		logger.time(`ppt-file parse image:(${imageName}) upload time`)
		const imageUploadData = await upload(imageFile)
		logger.timeEnd(`ppt-file parse image:(${imageName}) upload time`)
		return imageUploadData
	} catch (e) {
		logger.error(`ppt-file parse image upload failed`, e)
		throw new Error(`第${slideIndex}页PPT 图片上传失败，请重试`)
	}
}

export default async (
	slideIndex: number,
	pic: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFiles: any,
	upload: any
) => {
	const imageElement: PPTImageElement = {
		type: 'image',
		id: createRandomCode(),
		left: 0,
		top: 0,
		width: 0,
		height: 0,
		rotate: 0,
		fixedRatio: true,
		src: '',
		size: 0,
		md5: ''
	}
	const imageData = await getImageData(
		slideIndex,
		pic,
		slideRelsObject,
		pptxFiles,
		upload
	)
	if (imageData === 'noSupportImageType') {
		return 'noSupportImageType'
	}
	if (imageData) {
		imageElement.src = imageData.url
		imageElement.size = imageData.size
		imageElement.md5 = imageData.md5
	}

	if (!imageElement.src) {
		return
	}
	if (pic?.spPr?.xfrm?.attributes?.rot) {
		imageElement.rotate =
			pic.spPr.xfrm.attributes.rot / 60000 > 180
				? 180 - pic.spPr.xfrm.attributes.rot / 60000
				: pic.spPr.xfrm.attributes.rot / 60000
	}
	imageElement.flipH = pic?.spPr?.xfrm?.attributes?.flipH || false
	imageElement.flipV = pic?.spPr?.xfrm?.attributes?.flipV || false

	// 图片边框
	if (pic?.spPr?.ln) {
		if (!pic.spPr.ln?.noFill && !pic.spPr.ln?.miter) {
			const outline: PPTElementOutline = {
				style:
					pic.spPr.ln?.prstDash?.attributes?.val
						.toLowerCase()
						.indexOf('solid') === -1
						? 'dashed'
						: 'solid',
				width: (pic.spPr.ln?.attributes?.w || 9525) / 12700,
				color: `#000000`
			}
			if (pic.spPr.ln?.solidFill?.srgbClr?.attributes?.val) {
				outline.color = `#${pic.spPr.ln?.solidFill?.srgbClr?.attributes?.val}`
			} else if (pic.spPr.ln?.solidFill?.schemeClr?.attributes?.val) {
				const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
				const color = getThemeColor(
					slideMasterNum,
					pic.spPr.ln?.solidFill?.schemeClr?.attributes?.val
				)
				if (color) {
					outline.color = color
				}
			}
			imageElement.outline = outline
		}
	}
	// 图片阴影
	if (pic?.spPr?.effectLst?.outerShdw) {
		const shadow: PPTElementShadow = {
			blur: 1,
			color: `#000000`,
			h: (pic.spPr.effectLst.outerShdw?.attributes.sx || 12700) / 12700,
			v: (pic.spPr.effectLst.outerShdw?.attributes.sy || 12700) / 12700
		}
		if (pic.spPr?.effectLst?.outerShdw?.attributes?.blurRad) {
			shadow.blur =
				(pic.spPr.effectLst.outerShdw.attributes.blurRad / 12700 / 100) * 20
		}
		if (pic.spPr?.effectLst?.outerShdw?.srgbClr?.attributes?.val) {
			shadow.color = `#${pic.spPr.effectLst.outerShdw.srgbClr.attributes.val}`
		} else if (pic.spPr?.effectLst?.outerShdw?.schemeClr?.attributes?.val) {
			const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
			const color = getThemeColor(
				slideMasterNum,
				pic.spPr.effectLst.outerShdw.schemeClr.attributes.val
			)
			if (color) {
				shadow.color = color
			}
		}
		imageElement.shadow = shadow
	}
	if (pic?.blipFill?.srcRect) {
		const t = (pic.blipFill.srcRect?.attributes?.t || 0) / 1000
		const b = 100 - pic.blipFill.srcRect?.attributes?.b / 1000 || 100000 / 1000
		const l = (pic.blipFill.srcRect?.attributes?.l || 0) / 1000
		const r = 100 - pic.blipFill.srcRect?.attributes?.r / 1000 || 100000 / 1000
		const clip: ImageElementClip = {
			range: [
				[l, t],
				[r, b]
			],
			shape: 'rect'
		}
		imageElement.clip = clip
	}

	const position = await getPosition(pic, slideRelsObject, pptxFiles)
	_.merge(imageElement, position)
	return imageElement
}
