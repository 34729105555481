import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import {
	CSSProperties,
	onMounted,
	ref,
	watch,
	computed,
	withDefaults
} from 'vue'
import tippy, { Instance, Placement } from 'tippy.js'

import 'tippy.js/animations/scale.css'


export default _defineComponent({
  props: {
    value: { type: Boolean, required: false, default: false },
    trigger: { type: String, required: false, default: 'click' },
    placement: { type: null, required: false, default: 'bottom' },
    appendTo: { type: [String, null], required: false },
    contentStyle: { type: null, required: false },
    center: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["update:value"] as unknown as undefined,
  setup(__props: {
		value?: boolean
		trigger?: 'click' | 'mouseenter' | 'manual'
		placement?: typeof Placement
		appendTo?: string | HTMLElement | 'parent'
		contentStyle?: CSSProperties
		center?: boolean
	}, { emit }: { emit: ({
	(event: 'update:value', payload: boolean): void
}), expose: any, slots: any, attrs: any }) {

const props = __props




const instance = ref<typeof Instance>()
const triggerRef = ref<HTMLElement>()
const contentRef = ref<HTMLElement>()
const contentVisible = ref(false)

const contentStyle = computed(() => {
	return props.contentStyle || {}
})

watch(
	() => props.value,
	() => {
		if (!instance.value) return
		if (props.value) instance.value.show()
		else instance.value.hide()
	}
)

onMounted(() => {
	let appendTo: any = document.body
	if (typeof props.appendTo === 'string') {
		try {
			appendTo = document.querySelector(props.appendTo)
		} catch (e) {
			appendTo = undefined
		}
		if (!appendTo) {
			appendTo = props.appendTo
		}
	}
	instance.value = tippy(triggerRef.value!, {
		content: contentRef.value!,
		allowHTML: true,
		trigger: props.trigger,
		placement: props.placement,
		interactive: true,
		appendTo,
		maxWidth: 'none',
		offset: [0, 8],
		duration: 200,
		animation: 'scale',
		theme: 'popover',
		onShow() {
			contentVisible.value = true
		},
		onShown() {
			if (!props.value) emit('update:value', true)
		},
		onHidden() {
			if (props.value) emit('update:value', false)
			contentVisible.value = false
		}
	})
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["popover", { center: __props.center }]),
    ref: triggerRef
  }, [
    _createElementVNode("div", {
      class: "popover-content",
      style: _normalizeStyle(_unref(contentStyle)),
      ref: contentRef
    }, [
      (contentVisible.value)
        ? _renderSlot(_ctx.$slots, "content", { key: 0 })
        : _createCommentVNode("", true)
    ], 4),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})