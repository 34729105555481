import { SlideTheme } from '@/types/slides'

export const theme: SlideTheme = {
  themeColor: '#5b9bd5',
  fontColor: '#333',
  fontName: 'Sans-serif',
  fontSize: '40px',
  backgroundColor: '#fff',
  viewportRatio: 0.5625,

}