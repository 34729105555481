import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8d8dbbf8")
const _hoisted_1 = { class: "counter" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = () => { _ctx.countValue > _ctx.min && _ctx.countValue-- }),
      class: "cicle-btn"
    }, "-"),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.countValue = $event)),
      oninput: "value=value.replace(/\\D/g, '')",
      readonly: ""
    }, null, 512), [
      [_vModelText, _ctx.countValue]
    ]),
    _createElementVNode("div", {
      onClick: _cache[2] || (_cache[2] = () => { _ctx.countValue < _ctx.max && _ctx.countValue++ }),
      class: "cicle-btn"
    }, "+")
  ]))
}