
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    PropType,
    ref,
    watch,
    watchEffect
} from 'vue'
import { MutationTypes, useStore } from '@/store'
import { Slide } from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'
import ThumbnailElement from './ThumbnailElement.vue'
import {
    generateElementPreview,
    generateSlidePreviewStates,
    isImportPptGeneratePreview
} from '@/hooks/usePreviewImage'
import logger from '@evideo/logger'
import _ from 'lodash'
import useRunTime from '@/hooks/useRunTime'
import emitter, { EmitterEvents } from '@/utils/emitter'
import {
    TransparentPng,
    imageOssProcessSuffix,
    Base64ImageReg,
    compressImage
} from '@/utils/image'

export default defineComponent({
    name: 'thumbnail-slide',
    components: {
        ThumbnailElement
    },
    props: {
        slideIndex: {
            type: Number,
            required: true
        },
        slide: {
            type: Object as PropType<Slide>,
            required: true
        },
        size: {
            type: Number,
            required: true
        },
        isImportPptx: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {
        const store = useStore()
        const viewportRatio = computed(() => store.state.viewportRatio)
        const screening = computed(() => store.state.screening)
        const isImportingPptx = computed(() => props.isImportPptx)
        const { runtime } = useRunTime()
        // 只有放映模式允许截取预览图。从编辑模式切换到放映模式，不允许截取预览图
        const canPreview = computed(
            () =>
                (runtime?.state?.screenType !== 'edit-check' &&
                    screening.value) ||
                !screening.value
        )

        const background = computed(() => props.slide.background)

        const { backgroundStyle } = useSlideBackgroundStyle(background)

        const scale = computed(() => props.size / VIEWPORT_SIZE)

        /** 预览图截图*/
        const slidePreviewUrl = ref<string | undefined>(props.slide.preview)
        const compressSlidePreviewUrl = ref<string | undefined>(TransparentPng)
        watchEffect(() => {
            if (slidePreviewUrl.value) {
                if (slidePreviewUrl.value.startsWith('http')) {
                    compressSlidePreviewUrl.value = imageOssProcessSuffix(
                        slidePreviewUrl.value,
                        480
                    )
                } else if (Base64ImageReg.test(slidePreviewUrl.value)) {
                    compressImage(slidePreviewUrl.value, 480, 270).then(
                        (url) => {
                            compressSlidePreviewUrl.value = url
                        }
                    )
                } else {
                    compressSlidePreviewUrl.value = slidePreviewUrl.value
                }
            }
        })

        const thumbnailSlideRef = ref<HTMLDivElement>()
        const options = {
            subtree: true,
            childList: true,
            attributes: true,
            attributeOldValue: true,
            characterData: true
        }
        let generateSPTimeId: number | null = null
        let generateSPStatus = 0
        watch(
            () => _.cloneDeep([props.slide.background, props.slide.elements]),
            (newVal, oldVal) => {
                if (screening.value) {
                    return
                }
                if (!_.isEqual(newVal, oldVal)) {
                    removePreviewUrl()
                }
            },
            { deep: true }
        )

        watch(
            () => props.slide.preview,
            (newVal) => {
                if (newVal && isImportingPptx.value) {
                    slidePreviewUrl.value = props.slide.preview
                }
            }
        )

        const removePreviewUrl = (msg?: { slideId: string }) => {
            if (msg) {
                if (msg.slideId !== props.slide.id) {
                    return
                }
            }
            slidePreviewUrl.value = undefined
            store.commit(MutationTypes.UPDATE_SLIDE_PREVIEW, {
                slideIndex: props.slideIndex,
                slideId: props.slide.id,
                url: undefined,
                size: 0,
                md5: undefined
            })
        }

        const thumbnailSlidePreview = () => {
            if (generateSPTimeId) {
                clearTimeout(generateSPTimeId)
            }
            if (!canPreview.value) {
                return
            }
            generateSlidePreviewStates.value[props.slide.id] = 0
            generateSPStatus = 0
            generateSPTimeId = window.setTimeout(() => {
                generateSPStatus = 1
                generateSlidePreviewStates.value[props.slide.id] = 1
                if (!canPreview.value) {
                    return
                }
                window.requestIdleCallback(() => {
                    generateSPStatus = 2
                    generateSlidePreviewStates.value[props.slide.id] = 2
                    if (
                        thumbnailSlideRef.value?.firstChild?.textContent ===
                            'v-if' ||
                        !thumbnailSlideRef.value?.firstChild
                    ) {
                        return
                    }
                    if (!canPreview.value) {
                        return
                    }
                    generateElementPreview(
                        thumbnailSlideRef.value!.firstChild! as HTMLElement
                    )
                        .then((baseImageUrl) => {
                            generateSPStatus = 3
                            generateSlidePreviewStates.value[props.slide.id] = 3
                            if (generateSPStatus < 3) {
                                return
                            }
                            if (!canPreview.value) {
                                return
                            }

                            slidePreviewUrl.value = baseImageUrl
                            generateSPStatus = 4
                            generateSlidePreviewStates.value[props.slide.id] = 4
                            store.commit(MutationTypes.UPDATE_SLIDE_PREVIEW, {
                                slideIndex: props.slideIndex,
                                slideId: props.slide.id,
                                url: baseImageUrl,
                                size: 0,
                                md5: undefined
                            })
                        })
                        .finally(() => {
                            generateSPTimeId = null
                        })
                })
            }, 3000)
        }

        let observer: MutationObserver | undefined = undefined

        const initMutationObserver = () => {
            if (observer) {
                observer.disconnect()
                observer = undefined
            }
            observer = new MutationObserver(() => {
                const isUpdateBackground = () => {
                    logger.debug(
                        'isUpdateBackground isExit backgroundImage',
                        !!_.cloneDeep(
                            thumbnailSlideRef.value?.style.backgroundImage
                        )
                    )
                    if (
                        _.cloneDeep(
                            thumbnailSlideRef.value?.style.backgroundImage
                        ) &&
                        _.cloneDeep(
                            thumbnailSlideRef.value?.style.backgroundImage
                        ) !== 'none'
                    ) {
                        return true
                    }
                    return false
                }
                if (isUpdateBackground()) {
                    logger.debug('isUpdateBackground return')
                    return
                }
                thumbnailSlidePreview()
            })
        }

        const initThumbnailSlide = () => {
            emitter.on(EmitterEvents.GENERATE_CURRENT_PREVIEW, removePreviewUrl)
            initMutationObserver()
            observer && observer.observe(thumbnailSlideRef.value!, options)
            if (props.slide.preview) {
                generateSlidePreviewStates.value[props.slide.id] = 4
            } else if (props.slideIndex !== 0) {
                thumbnailSlidePreview()
            } else if (props.slideIndex === 0) {
                setTimeout(() => {
                    !store.state.slides[0]?.preview && thumbnailSlidePreview()
                }, 1000)
            }
        }

        watch(
            () => [
                props.isImportPptx,
                isImportPptGeneratePreview.value,
                store.state.pageLoading
            ],
            (newVal) => {
                if (!newVal[0] && !newVal[1] && !store.state.pageLoading) {
                    slidePreviewUrl.value = props.slide.preview
                    setTimeout(() => {
                        initThumbnailSlide()
                    }, 0)
                }
            },
            { immediate: true }
        )

        onBeforeUnmount(() => {
            generateSPTimeId && clearTimeout(generateSPTimeId)
            observer && observer.disconnect()
            generateSlidePreviewStates.value?.[props.slide.id] !== undefined &&
                delete generateSlidePreviewStates.value[props.slide.id]
            emitter.off(
                EmitterEvents.GENERATE_CURRENT_PREVIEW,
                removePreviewUrl
            )
        })
        return {
            scale,
            screening,
            backgroundStyle,
            VIEWPORT_SIZE,
            viewportRatio,
            thumbnailSlideRef,
            slidePreviewUrl,
            compressSlidePreviewUrl,
            isImportingPptx
        }
    }
})
