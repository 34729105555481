import _ from 'lodash'
import dom2image, { Options } from '@evideo/dom-to-image'

const dom2Image = _.debounce(
    async (html: Node, options: Options) => {
        console.time('dom2Image')
        const url = await dom2image.toJpeg(html, options)
        console.timeEnd('dom2Image')
        return url
    },
    100,
    { leading: true, maxWait: 2000 }
)

const generatePreview = async (html: HTMLElement, options?: Options) => {
    const _options = _.merge(
        {
            height: html.clientHeight,
            width: html.clientWidth,
            style: {
                inset: '',
                insetBlock: '',
                insetInline: '',
                left: '0px',
                top: '0px'
            },
            quality: 0.3
        },
        options || {}
    )
    const url = await dom2Image(html, _options)
    return url
}

export { generatePreview, dom2Image }
