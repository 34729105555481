import { slides } from '@/mocks/slides'
import { theme } from '@/mocks/theme'
import { CreatingElement } from '@/types/edit'
import { PptState } from '@/types/pptState'
import { Slide, SlideTheme } from '@/types/slides'
import { ToolbarState } from '@/types/toolbar'
import { TextAttrs, defaultRichTextAttrs } from '@/utils/prosemirror/utils'
import { v4 as uuidv4 } from 'uuid'

export type ScreenType = 'preview' | 'demonstrate' | 'edit-check'
interface Fonts {
  label: string
  value: string
  accept: string
  fontFamily?: string
}
export interface State {
  token: string | null
  uuid: string
  pptState: PptState
  activeElementIdList: string[]
  handleElementId: string
  activeGroupElementId: string
  canvasPercentage: number
  canvasScale: number
  thumbnailsFocus: boolean
  editorAreaFocus: boolean
  disableHotkeys: boolean
  showGridLines: boolean
  creatingElement: CreatingElement | null
  availableFonts: Fonts[]
  toolbarState: ToolbarState
  theme: SlideTheme
  viewportRatio: number
  slides: Slide[]
  slideIndex: number
  selectedSlidesIndex: number[]
  snapshotCursor: number
  snapshotLength: number
  ctrlKeyState: boolean
  shiftKeyState: boolean
  screening: boolean
  clipingImageElementId: string
  richTextAttrs: TextAttrs
  selectedTableCells: string[]
  isScaling: boolean
  showTopIframe: boolean
  topIframeSrc: string
  editingShapeElementId: string
  pageLoading: boolean
  pageLoadingText: string
  screenType: ScreenType
  editMode: 'normal' | 'knowledgePoints'
  pptUrl?: string
  loadPNPPTSign: boolean
  editingScreening: boolean
  animationIndex: number
  nextActionStepIndex: number
  nextActionPlayedIds: string[]
  showScreenBtn: boolean
  showScreenControls: boolean,
}

export const state: State = {
  token: null,
  pptUrl: undefined,
  uuid: uuidv4(), // ppt的id 新ppt:自动生成 载入ppt:载入ppt的uuid
  pptState: PptState.NEW, // 新的ppt:PptState.NEW 载入的ppt:PptState.LOAD
  activeElementIdList: [], // 被选中的元素ID集合，包含 handleElementId
  handleElementId: '', // 正在操作的元素ID
  activeGroupElementId: '', // 组合元素成员中，被选中可独立操作的元素ID
  canvasPercentage: 90, // 画布可视区域百分比
  canvasScale: 1, // 画布缩放比例（基于宽度1000px）
  thumbnailsFocus: false, // 左侧导航缩略图区域聚焦
  editorAreaFocus: false, //  编辑区域聚焦
  disableHotkeys: false, // 禁用快捷键
  showGridLines: false, // 显示网格线
  creatingElement: null, // 正在插入的元素信息，需要绘制插入的元素需要（文字、形状、线条）
  availableFonts: [], // 当前环境可用字体
  toolbarState: 'slideDesign', // 右侧工具栏状态
  viewportRatio: 0.5625, // 可是区域比例，默认16:9
  theme: theme, // 主题样式
  slides: slides, // 幻灯片页面数据
  slideIndex: 0, // 当前页面索引
  selectedSlidesIndex: [], // 当前被选中的页面索引集合
  snapshotCursor: -1, // 历史快照指针
  snapshotLength: 0, // 历史快照长度
  ctrlKeyState: false, // ctrl键按下状态
  shiftKeyState: false, // shift键按下状态
  screening: false, // 是否进入放映状态
  clipingImageElementId: '', // 当前正在裁剪的图片ID
  richTextAttrs: defaultRichTextAttrs, // 富文本状态
  selectedTableCells: [], // 选中的表格单元格
  isScaling: false, // 正在进行元素缩放
  showTopIframe: false,
  topIframeSrc: '',
  editingShapeElementId: '', // 当前正处在编辑文字状态的形状ID
  pageLoading: false,
  pageLoadingText: '加载中...',
  screenType: 'edit-check',
  editMode: 'normal',
  // exitScreenType: undefined
  editingScreening: false, // 是否处于全屏编辑状态
  loadPNPPTSign: false, // 是否处于自动加载上一下份ppt标记
  animationIndex: 0, // 放映时当页的动画下标
  nextActionStepIndex: 0, // 放映时当页的下一步所有动作播放下标
  nextActionPlayedIds: [], // 已经播放完的下一步的动作的元素id合集
  showScreenBtn: false,
  showScreenControls: true, // 是否可以显示放映控件
}
