import useQianKun from '@/hooks/useQianKun'

interface ImageSize {
    width: number
    height: number
}

/**
 * 获取图片的原始宽高
 * @param src 图片地址
 */
export const getImageSize = (src: string): Promise<ImageSize> => {
    const { document } = useQianKun()
    return new Promise((resolve, reject) => {
        let img: HTMLImageElement | null = new Image()
        img.style.opacity = '0'
        document.body.appendChild(img)

        img.onload = () => {
            const imgWidth = img!.clientWidth
            const imgHeight = img!.clientHeight

            img!.onload = null
            img!.onerror = null

            document.body.removeChild(img!)
            img = null
            resolve({ width: imgWidth, height: imgHeight })
        }

        img.onerror = (e) => {
            img!.onload = null
            img!.onerror = null
            img = null
            reject(e)
        }
        img.src = src
    })
}

/**
 * 读取图片文件的dataURL
 * @param file 图片文件
 */
export const getImageDataURL = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            resolve(reader.result as string)
        })
        reader.readAsDataURL(file)
    })
}

export const getImageBase64DataURL = (
    url: string,
    width: number,
    height: number
): Promise<string> => {
    return new Promise((resolve) => {
        const img = window.document.createElement('img')
        img.setAttribute('crossOrigin', 'Anonymous')
        img.src = url
        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            ctx!.drawImage(img, 0, 0, width, height)
            const ext = img.src
                .substring(img.src.lastIndexOf('.') + 1)
                .toLowerCase()
            const dataURL = canvas.toDataURL('image/' + ext)
            resolve(dataURL)
        }

        img.onerror = () => {
            img.onload = null
            img.onerror = null
        }
    })
}

/**
 * 处理阿里 oss 的图片裁剪
 * @param url 地址
 * @param w 宽度
 * @param h 高度
 * @param q 质量 默认80%
 * @returns
 */
export const imageOssProcessSuffix = (
    url: string,
    w?: number,
    h?: number,
    q = 80
) => {
    const reg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i
    if (reg.test(url)) {
        return url
    }
    if (url.indexOf('?x-oss-process') > -1) {
        return url
    }
    return (
        `${url}?x-oss-process=image/resize,m_mfit` +
        (w ? `,w_${w}` : '') +
        (h ? `,h_${h}` : '') +
        `/quality,q_${q}`
    )
}
export const Base64ImageReg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i

export const TransparentPng =
    'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw=='
/**
 *
 * @param url
 * @param t
 * @param w
 * @param h
 * @returns
 */

export const videoSnapshotOssProcessSuffix = (
    url: string,
    t = 1000,
    w = 0,
    h = 0
) => {
    if (Base64ImageReg.test(url)) {
        return url
    }
    if (url.indexOf('?x-oss-process') > -1) {
        return url
    }
    // ?x-oss-process=video/snapshot,t_2000,f_jpg,w_0,h_0,m_fast
    return (
        `${url}?x-oss-process=video/snapshot,m_fast` +
        (w ? `,w_${w}` : '') +
        (h ? `,h_${h}` : '') +
        `,t_${t}` +
        ',f_jpg'
    )
}

/**
 * 利用canvas进行图片简单压缩
 * @param url 图片地址
 * @param width 宽
 * @param height 高
 * @param quality 1-100
 * @returns
 */
export const compressImage = (
    url: string,
    width: number,
    height: number,
    quality = 30
) => {
    return new Promise<string>((resolve) => {
        console.time('compressImage')
        let image: HTMLImageElement | null = new Image()
        image.onload = () => {
            let canvas: HTMLCanvasElement | null = document.createElement(
                'canvas'
            )
            const ctx = canvas.getContext('2d') as any
            canvas.width = width
            canvas.height = height
            ctx?.drawImage(
                image as HTMLImageElement,
                0,
                0,
                canvas.width,
                canvas.height
            )
            resolve(canvas.toDataURL('image/jpeg', quality / 100))
            image = null
            canvas = null
            console.timeEnd('compressImage')
        }
        image.src = url
    })
}
