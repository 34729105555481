export const ELEMENT_TYPE_ZH = {
    text: '文本',
    image: '图片',
    shape: '形状',
    line: '线条',
    chart: '图表',
    table: '表格',
    'link-button': '超链接按钮',
    'elf-audio': '音频',
    'elf-video': '视频',
    'elf-emsemble-practice': '钢琴合奏',
    'elf-sing-play': '曲谱',
    'elf-enjoy': '欣赏歌曲',
    'elf-carouse-image': '轮播图',
    'elf-music-rhythm': '音乐律动',
    'elf-questions-bank': '题库',
    'elf-pk-questions': '答题PK',
}

export const MIN_SIZE = {
    text: 20,
    image: 20,
    shape: 15,
    chart: 200,
    table: 20
}
