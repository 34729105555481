import { nodes } from 'prosemirror-schema-basic'
import { Node, NodeSpec } from 'prosemirror-model'
import { orderedList, bulletList, listItem } from 'prosemirror-schema-list'

const _orderedList: NodeSpec = {
	...orderedList,
	content: 'list_item+',
	group: 'block'
}

const _bulletList: NodeSpec = {
	...bulletList,
	content: 'list_item+',
	group: 'block'
}

const _listItem: NodeSpec = {
	...listItem,
	attrs: {
		fontSize: {
			default: '40px'
		}
	},
	content: 'paragraph block*',
	group: 'block',
	parseDOM: [
		{
			tag: 'li',
			getAttrs: (dom) => {
				const { fontSize } = (dom as HTMLElement).style
				const _fontSize = fontSize ? fontSize : '40px'
				return { fontSize: _fontSize }
			}
		}
	],
	toDOM: (node: Node) => {
		const { fontSize } = node.attrs
		let style = ''
		if (fontSize) style += `font-size: ${fontSize};`

		return ['li', { style }, 0]
	}
}

const paragraph: NodeSpec = {
	attrs: {
		align: {
			default: ''
		}
	},
	content: 'inline*',
	group: 'block',
	parseDOM: [
		{
			tag: 'p',
			getAttrs: (dom) => {
				const { textAlign } = (dom as HTMLElement).style
				let align =
					(dom as HTMLElement).getAttribute('align') || textAlign || ''
				align = /(left|right|center|justify)/.test(align) ? align : ''

				return { align }
			}
		}
	],
	toDOM: (node: Node) => {
		const { align } = node.attrs
		let style = ''
		if (align && align !== 'left') style += `text-align: ${align};`

		return ['p', { style }, 0]
	}
}
const _hardBreak: NodeSpec = {
	inline: true,
	group: 'inline',
	selectable: false,
	parseDOM: [{ tag: 'br' }],
	toDOM() {
		return ['i', 0] // 保证编辑时，切换时，不会自主加一个br
	}
} as NodeSpec

export default {
	...nodes,
	ordered_list: _orderedList,
	bullet_list: _bulletList,
	list_item: _listItem,
	paragraph,
	hard_break: _hardBreak
}
