import { SYS_FONTS } from '@/configs/font'
import { slides } from '@/mocks/slides'
import { theme } from '@/mocks/theme'
import { CreatingElement } from '@/types/edit'
import { PptState } from '@/types/pptState'
import { PPTAnimation, PPTElement, Slide, SlideTheme } from '@/types/slides'
import { ToolbarState } from '@/types/toolbar'
import { TextAttrs } from '@/utils/prosemirror/utils'
import logger from '@evideo/logger'
import _ from 'lodash'
import { MutationTree } from 'vuex'
import { MutationTypes } from './constants'
import { State } from './state'
import useElectron from '@/hooks/useElectron'

const { getSystemFonts } = useElectron()

interface RemoveElementPropData {
	id: string
	propName: string | string[]
}

interface UpdateElementData {
	id: string | string[]
	props: Partial<PPTElement>
}

export const mutations: MutationTree<State> = {
	// reset
	[MutationTypes.RESET](state) {
		state.slideIndex = 0
		state.handleElementId = ''
		state.activeElementIdList = []
		state.handleElementId = ''
		state.activeGroupElementId = ''
		state.theme = _.cloneDeep(theme)
		state.slides = _.cloneDeep(slides)
	},

	// uuid
	[MutationTypes.SET_TOKEN](state, token: string) {
		state.token = token
	},

	// uuid
	[MutationTypes.SET_UUID](state, uuid: string) {
		state.uuid = uuid
	},

	// pptState
	[MutationTypes.SET_PPT_STATE](state, pptState: PptState) {
		state.pptState = pptState
	},

	// editor

	[MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST](
		state,
		activeElementIdList: string[]
	) {
		if (activeElementIdList.length === 1) {
			state.handleElementId = activeElementIdList[0]
		} else state.handleElementId = ''

		state.activeElementIdList = activeElementIdList
	},

	[MutationTypes.SET_HANDLE_ELEMENT_ID](state, handleElementId: string) {
		state.handleElementId = handleElementId
	},

	[MutationTypes.SET_ACTIVE_GROUP_ELEMENT_ID](
		state,
		activeGroupElementId: string
	) {
		state.activeGroupElementId = activeGroupElementId
	},

	[MutationTypes.SET_CANVAS_PERCENTAGE](state, percentage: number) {
		state.canvasPercentage = percentage
	},

	[MutationTypes.SET_CANVAS_SCALE](state, scale: number) {
		state.canvasScale = scale
	},

	[MutationTypes.SET_THUMBNAILS_FOCUS](state, isFocus: boolean) {
		state.thumbnailsFocus = isFocus
	},

	[MutationTypes.SET_EDITORAREA_FOCUS](state, isFocus: boolean) {
		state.editorAreaFocus = isFocus
	},

	[MutationTypes.SET_DISABLE_HOTKEYS_STATE](state, disable: boolean) {
		state.disableHotkeys = disable
	},

	[MutationTypes.SET_GRID_LINES_STATE](state, show: boolean) {
		state.showGridLines = show
	},

	[MutationTypes.SET_CREATING_ELEMENT](state, element: CreatingElement | null) {
		state.creatingElement = element
	},

	[MutationTypes.SET_AVAILABLE_FONTS](state) {
		const isMac = /macintosh|mac os x/i.test(navigator.userAgent)
		state.availableFonts = SYS_FONTS.filter((font) =>
			font.accept.includes(isMac ? 'mac' : 'win')
		)
		const fontList = [
			'Wingdings',
			'Webdings',
			'Wingdings 2',
			'Wingdings 3',
			'Euclid Extra',
			'Bookshelf Symbol 7',
			'Fences',
			'Marlett',
			'MS Reference Specialty',
			'MT Extra',
			'MT Extra Tiger',
			'Symbol',
			'Symbol Tiger',
			'Symbol Tiger Expert',
			'Euclid Symbol',
			'Euclid Fraktur',
			'Fences Plain',
			'NumberOnly'
		] // 用于存储显示不易辨识的字体

		getSystemFonts()
			.then((result) => {
				if (!Array.isArray(result)) {
					console.error('result is not an array')
					return
				}

				result.forEach((font) => {
					let fontLabel: string, fontValue: string, fontFamily

					if (font.includes(',')) {
						const [label, value] = font.split(',')
						fontLabel = label
						fontValue = value
						fontFamily = !fontList.includes(value)
					} else {
						fontLabel = font
						fontValue = ['Wingdings 2', 'Wingdings 3'].includes(font)
							? `'${font}'`
							: font
						fontFamily = !fontList.includes(font)
					}

					const accept = isMac ? 'mac' : 'win'

					const fontExists = state.availableFonts.some(
						(item) => item.value === fontValue || item.label === fontLabel
					)

					if (!fontExists) {
						state.availableFonts.push({
							label: fontLabel,
							value: fontValue,
							accept: accept,
							fontFamily: fontFamily ? fontValue : ''
						})
					}
				})

				state.availableFonts.sort((a, b) => {
					const isAChinese = /[\u4e00-\u9fa5]/.test(a.label)
					const isBChinese = /[\u4e00-\u9fa5]/.test(b.label)

					if (isAChinese && !isBChinese) return -1
					if (!isAChinese && isBChinese) return 1
					return a.label.localeCompare(b.label)
				})
			})
			.catch((err) => {
				console.error(err)
			})
	},

	[MutationTypes.SET_TOOLBAR_STATE](state, toolbarState: ToolbarState) {
		state.toolbarState = toolbarState
	},

	[MutationTypes.SET_CLIPING_IMAGE_ELEMENT_ID](state, elId: string) {
		state.clipingImageElementId = elId
	},

	[MutationTypes.SET_RICHTEXT_ATTRS](state, attrs: TextAttrs) {
		state.richTextAttrs = attrs
	},

	[MutationTypes.SET_SELECTED_TABLE_CELLS](state, cells: string[]) {
		state.selectedTableCells = cells
	},

	[MutationTypes.SET_SCALING_STATE](state, isScaling: boolean) {
		state.isScaling = isScaling
	},

	[MutationTypes.SET_EDITING_SHAPE_ELEMENT_ID](state, ellId: string) {
		state.editingShapeElementId = ellId
	},

	// slides

	[MutationTypes.SET_THEME](state, themeProps: Partial<SlideTheme>) {
		state.theme = { ...state.theme, ...themeProps }
	},

	[MutationTypes.SET_VIEWPORT_RATIO](state, viewportRatio: number) {
		state.viewportRatio = viewportRatio
		state.theme.viewportRatio = viewportRatio
	},

	[MutationTypes.SET_SLIDES](state, slides: Slide[]) {
		state.slides = slides
	},

	[MutationTypes.ADD_SLIDE](state, slide: Slide | Slide[]) {
		const slides = Array.isArray(slide) ? slide : [slide]
		const addIndex = state.slideIndex + 1
		state.slides.splice(addIndex, 0, ...slides)
		state.slideIndex = addIndex
	},

	[MutationTypes.UPDATE_SLIDE](state, props: Partial<Slide>) {
		const slideIndex = state.slideIndex
		state.slides[slideIndex] = { ...state.slides[slideIndex], ...props }
	},

	[MutationTypes.UPDATE_SLIDE_PREVIEW](
		state,
		{ slideIndex, slideId, url, size, md5 } = {}
	) {
		const slide = state.slides[slideIndex]
		if (!slide) {
			return
		}
		if (slideId && slideId !== slide.id) {
			const oldsSlide = state.slides.find((i) => i.id === slideId)
			if (oldsSlide) {
				oldsSlide.preview = url
			} else {
				logger.info('截图时，当前页并不是截图当时的页，不赋值')
			}
			return
		}
		// if (!url) {
		//   return
		// }
		slide.preview = url
		slide.previewSize = size
		slide.previewMd5 = md5
	},

	[MutationTypes.DELETE_SLIDE](state, slideId: string | string[]) {
		const slidesId = Array.isArray(slideId) ? slideId : [slideId]

		const deleteSlidesIndex = []
		for (let i = 0; i < slidesId.length; i++) {
			const index = state.slides.findIndex((item) => item.id === slidesId[i])
			deleteSlidesIndex.push(index)
		}
		let newIndex = Math.min(...deleteSlidesIndex)

		const maxIndex = state.slides.length - slidesId.length - 1
		if (newIndex > maxIndex) newIndex = maxIndex

		state.slideIndex = newIndex
		state.slides = state.slides.filter((item) => !slidesId.includes(item.id))
	},

	[MutationTypes.UPDATE_SLIDE_INDEX](state, index: number) {
		state.slideIndex = index
	},

	[MutationTypes.UPDATE_SELECTED_SLIDES_INDEX](
		state,
		selectedSlidesIndex: number[]
	) {
		state.selectedSlidesIndex = selectedSlidesIndex
	},

	[MutationTypes.ADD_ELEMENT](state, element: PPTElement | PPTElement[]) {
		const elements = Array.isArray(element) ? element : [element]
		const currentSlideEls = state.slides[state.slideIndex].elements
		const newEls = [...currentSlideEls, ...elements]
		state.slides[state.slideIndex].elements = newEls
	},

	[MutationTypes.UPDATE_ELEMENT](state, data: UpdateElementData) {
		const { id, props } = data
		const elIdList = typeof id === 'string' ? [id] : id

		const slideIndex = state.slideIndex
		if (slideIndex === undefined) {
			return
		}
		const slide = state.slides[slideIndex]
		const elements =
			slide?.elements?.map((el) => {
				return elIdList.includes(el.id) ? { ...el, ...props } : el
			}) || []
		state.slides[slideIndex].elements = elements as PPTElement[]
	},
	[MutationTypes.DELETE_ELEMENT](state, elementId: string | string[]) {
		const elementIdList = Array.isArray(elementId) ? elementId : [elementId]
		const currentSlideEls = state.slides[state.slideIndex].elements
		const newEls = currentSlideEls.filter(
			(item) => !elementIdList.includes(item.id)
		)
		state.slides[state.slideIndex].elements = newEls
	},

	[MutationTypes.REMOVE_ELEMENT_PROPS](state, data: RemoveElementPropData) {
		const { id, propName } = data
		const propsNames = typeof propName === 'string' ? [propName] : propName

		const slideIndex = state.slideIndex
		const slide = state.slides[slideIndex]
		const elements = slide.elements.map((el) => {
			return el.id === id ? _.omit(el, propsNames) : el
		})
		state.slides[slideIndex].elements = elements as PPTElement[]
	},

	[MutationTypes.REMOVE_ELEMENT_PROPS_IN_SLIDES](
		state,
		data: RemoveElementPropData
	) {
		const { id, propName } = data
		const propsNames = typeof propName === 'string' ? [propName] : propName

		state.slides.forEach((slide: Slide, index: number) => {
			const elements =
				slide.elements.map((el) => {
					return el.id === id ? _.omit(el, propsNames) : el
				}) || []
			state.slides[index].elements = elements as PPTElement[]
		})
	},

	// snapshot
	[MutationTypes.SET_SNAPSHOT_CURSOR](state, cursor: number) {
		state.snapshotCursor = cursor
	},

	[MutationTypes.SET_SNAPSHOT_LENGTH](state, length: number) {
		state.snapshotLength = length
	},

	// keyboard
	[MutationTypes.SET_CTRL_KEY_STATE](state, isActive: boolean) {
		state.ctrlKeyState = isActive
	},
	[MutationTypes.SET_SHIFT_KEY_STATE](state, isActive: boolean) {
		state.shiftKeyState = isActive
	},

	// screen

	[MutationTypes.SET_SCREENING](
		state,
		data: {
			screening: boolean
			screenType: typeof state.screenType
			editMode?: typeof state.editMode
		}
	) {
		state.screening = data.screening
		if (data.screenType) {
			state.screenType = data.screenType
		}
		if (data.editMode) {
			state.editMode = data.editMode
		}
	},

	[MutationTypes.SET_EDITING_FULL_SCREENING](state, show: boolean) {
		state.editingScreening = show
	},

	[MutationTypes.SET_SHOW_SCREEN_CONTROLS](state, show: boolean) {
		state.showScreenControls = show
	},
	// top ifrmae
	[MutationTypes.SET_TOP_IFRAME](state, data: { url?: string; show: boolean }) {
		state.topIframeSrc = data.url || ''
		state.showTopIframe = data.show
	},

	[MutationTypes.LOAD_PPT](
		state,
		data: {
			uuid: string
			slides: Slide[]
			theme: SlideTheme
			pptUrl: string
			slideIndex: number
		}
	) {
		// 兼容之前的ppt和新建的ppt没有最佳比例情况
		if (theme && !theme?.bestViewportRatio) {
			theme.bestViewportRatio = theme.viewportRatio
		}
		if (data.theme && !data.theme?.bestViewportRatio) {
			data.theme.bestViewportRatio = data.theme.viewportRatio
		}
		// 目前PPT的默认字体大小都为40px
		if (data.theme && data.theme?.fontSize !== ' 40px') {
			data.theme.fontSize = '40px'
		}

		// 兼容之前的ppt删除元素但未删除动画问题
		if (data?.slides) {
			data.slides.forEach((slide) => {
				if (slide?.animations) {
					const elements = slide.elements
					const _animations: PPTAnimation[] = []
					slide.animations.forEach((animation) => {
						let isExitElement = false
						elements.forEach((el) => {
							if (el.id === animation.elId) {
								isExitElement = true
							}
						})
						if (isExitElement) {
							_animations.push(animation)
						}
					})
					slide.animations = _animations
				}
			})
		}

		state.uuid = data.uuid || state.uuid
		state.pptState = !data.uuid ? PptState.NEW : PptState.LOAD
		state.theme = data.theme || _.cloneDeep(theme)
		state.slides = data.slides || _.cloneDeep(slides)
		state.slideIndex = data.slideIndex || 0
		state.handleElementId = ''
		state.activeElementIdList = []
		state.handleElementId = ''
		state.activeGroupElementId = ''
		state.canvasScale = 1
		state.viewportRatio = state.theme?.viewportRatio || 0.5625
		state.pptUrl = data.pptUrl
		state.loadPNPPTSign = false
	},
	[MutationTypes.SET_LOADPNPPTSIGN](state, sign) {
		state.loadPNPPTSign = sign
	},
	[MutationTypes.PAGE_LOADING](state, loading) {
		state.pageLoading = loading
	},
	[MutationTypes.PAGE_LOADING_TEXT](state, loadingText) {
		state.pageLoadingText = loadingText
	},
	[MutationTypes.SET_ANIMATION_INDEX](state, animationIndex) {
		state.animationIndex = animationIndex
	},
	[MutationTypes.SET_NEXT_ACTION_STEP_INDEX](state, nextActionStepIndex) {
		state.nextActionStepIndex = nextActionStepIndex
	},
	[MutationTypes.SET_NEXT_ACTION_PLAYED_IDS](state, nextActionPlayedIds) {
		state.nextActionPlayedIds = nextActionPlayedIds
	}
}
