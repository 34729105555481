export type ToolbarState = 'symbol' | 'elAnimation' | 'elStyle' | 'elPosition' | 'slideDesign' | 'slideAnimation' | 'multiPosition' | 'lessonPlan' | 'speaklesson'

export const ToolbarStates = {
  SYMBOL: 'symbol',
  EL_ANIMATION: 'elAnimation',
  EL_STYLE: 'elStyle',
  EL_POSITION: 'elPosition',
  SLIDE_DESIGN: 'slideDesign',
  SLIDE_ANIMATION: 'slideAnimation',
  MULTI_POSITION: 'multiPosition',
  LESSON_PLAN: 'lessonPlan',
  SPEAK_LESSON: 'speaklesson'
}