import _ from 'lodash'
import { PPTElement, Slide } from '@/types/slides'
import * as minimatch from 'minimatch'
import changeTexts from '../elements/text'
import changeMedia from '../elements/media'
import { createRandomCode } from '@/utils/common'
import {
	getBackGroundColor,
	getColorMap,
	getDefaultTextSize,
	getGrpSPPosition,
	getPosition,
	getSlidBackground
} from '../attribute'
import logger from '@evideo/logger'
import { PptxToJsonOption } from '..'

const pptxFilePath = {
	ContentTypesPath: '[Content_Types].xml',
	PresentationRelsPath: 'ppt/_rels/presentation.xml.rels',
	SlidePath: 'ppt/slides/slide[0-9]*.xml',
	SlideRelsPath: 'ppt/slides/_rels/slide[0-9]*.xml.rels',
	MediaPath: 'ppt/media/**/*.**',
	themePath: 'ppt/theme/theme[0-9]*.xml',
	PresentationPath: 'ppt/presentation.xml',
	SlideLayoutRelsPath: 'ppt/slideLayouts/_rels/slideLayout[0-100]*.xml.rels',
	SlideMasterPath: 'ppt/slideMasters/slideMaster[0-9]*.xml'
}

// 紧凑型，元素按类型分类，无层级
const xmlToJsOption = {
	compact: true,
	ignoreDeclaration: true,
	attributesKey: 'attributes',
	elementNameFn: (s: string) => {
		return s.replace('p:', '').replace('a:', '')
	}
}

// 非紧凑型，元素按层级排序
const xmlToJsOptionNoCompact = {
	compact: false,
	ignoreDeclaration: true,
	attributesKey: 'attributes',
	elementNameFn: (s: string) => {
		return s.replace('p:', '').replace('a:', '')
	}
}
// 元素内容可能来自幻灯片 、 版式 、 母版
const spTreeFrom = {
	slide: 0,
	layout: 1,
	master: 2
}

// 元素层级
const changeElementLevel = (
	elementsObject: any,
	spTreeEntryNoCompact: any,
	graphicFrameCNvPrId?: string
) => {
	let sortElements: PPTElement[] = []
	if (spTreeEntryNoCompact) {
		if (spTreeEntryNoCompact.name === 'spTree') {
			spTreeEntryNoCompact = spTreeEntryNoCompact?.elements.filter(
				(item: any) =>
					item.name === 'pic' ||
					item.name === 'sp' ||
					item.name === 'grpSp' ||
					item.name === 'graphicFrame'
			)
		} else {
			spTreeEntryNoCompact = spTreeEntryNoCompact?.filter(
				(item: any) =>
					item.name === 'pic' ||
					item.name === 'sp' ||
					item.name === 'grpSp' ||
					item.name === 'graphicFrame'
			)
		}

		const graphicFrameIds: string[] = []
		elementsObject.forEach((el: any) => {
			if (el?.graphicFrameId) {
				graphicFrameIds.push(el.graphicFrameId)
			}
		})
		if (spTreeEntryNoCompact.length > 0) {
			for (let i = 0; i < spTreeEntryNoCompact.length; i++) {
				if (spTreeEntryNoCompact[i].name === 'pic') {
					const nvPicPr = spTreeEntryNoCompact[i].elements?.find(
						(item: any) => item.name === 'nvPicPr'
					)
					const cNvPr = nvPicPr?.elements?.find(
						(item: any) => item.name === 'cNvPr'
					)
					const id = cNvPr?.attributes?.id
					// 兼容graphicFrame类型的图片id相同的情况，以graphicFrame类型的id区分
					const elementMap = elementsObject?.find(
						(item: any) =>
							(!graphicFrameCNvPrId && item.id === id) ||
							(graphicFrameCNvPrId &&
								item.id === id &&
								graphicFrameCNvPrId === item?.graphicFrameId)
					)
					if (elementMap) {
						sortElements.push(elementMap.element)
					}
				} else if (spTreeEntryNoCompact[i].name === 'sp') {
					const nvSpPr = spTreeEntryNoCompact[i].elements?.find(
						(item: any) => item.name === 'nvSpPr'
					)
					const cNvPr = nvSpPr?.elements?.find(
						(item: any) => item.name === 'cNvPr'
					)
					const id = cNvPr?.attributes?.id
					const elementMap = elementsObject?.find((item: any) => item.id === id)
					if (elementMap) {
						sortElements.push(elementMap.element)
					}
				} else if (spTreeEntryNoCompact[i].name === 'grpSp') {
					const tempSorElements = _.cloneDeep(sortElements)
					sortElements = []
					const elements =
						changeElementLevel(
							elementsObject,
							spTreeEntryNoCompact[i].elements
						) || []
					sortElements.push(...tempSorElements, ...elements)
				} else if (spTreeEntryNoCompact[i].name === 'graphicFrame') {
					let graphicFrameFlag = false
					let graphicFrameCNvPrId = ''
					spTreeEntryNoCompact[i].elements.forEach((graphic: any) => {
						if (graphic.name === 'nvGraphicFramePr') {
							graphic.elements.forEach((graphicFrameCNvPr: any) => {
								if (graphicFrameCNvPr.name === 'cNvPr') {
									graphicFrameCNvPrId = graphicFrameCNvPr.attributes.id
									if (
										graphicFrameCNvPrId &&
										graphicFrameIds.includes(graphicFrameCNvPrId)
									) {
										graphicFrameFlag = true
									}
								}
							})
						}
					})
					if (graphicFrameFlag) {
						spTreeEntryNoCompact[i].elements.forEach((graphic: any) => {
							if (graphic.name === 'graphic') {
								graphic.elements.forEach((graphicData: any) => {
									if (graphicData.name === 'graphicData') {
										graphicData.elements.forEach((graphicContent: any) => {
											if (graphicContent.name === 'mc:AlternateContent') {
												graphicContent.elements.forEach(
													(graphicFallBack: any) => {
														if (graphicFallBack.name === 'mc:Fallback') {
															graphicFallBack.elements.forEach(
																(graphicOleObj: any) => {
																	if (graphicOleObj.name === 'oleObj') {
																		const tempSorElements = _.cloneDeep(
																			sortElements
																		)
																		sortElements = []
																		const elements =
																			changeElementLevel(
																				elementsObject,
																				graphicOleObj.elements,
																				graphicFrameCNvPrId
																			) || []
																		sortElements.push(
																			...tempSorElements,
																			...elements
																		)
																	}
																}
															)
														}
													}
												)
											}
										})
									}
								})
							}
						})
					}
				}
			}
		}
		return sortElements
	}
}

// 存放组合类型文本框填充色
let grpSpBgClr: string[] = []
// 存放组合元素位置
let grpSpPrPosition: any[] = []

const changeElements = async (
	slideIndex: number,
	spTreeFromNum: number,
	spTreeEntry: any,
	spTreeEntryNoCompact: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFiles: any,
	options: PptxToJsonOption
) => {
	const elements: any = []
	for (let i = 0; i < spTreeEntry.length; i++) {
		const [key, value]: any = spTreeEntry[i]
		options.beforeParseElement &&
			options.beforeParseElement(
				spTreeEntry[i],
				elements[i],
				i + 1,
				spTreeEntry.length
			)
		switch (key) {
			// 文本
			case 'sp':
				{
					try {
						const textElements =
							(await changeTexts(
								value,
								spTreeFromNum,
								spTreeEntryNoCompact,
								slideRelsObject,
								slideMaster,
								pptxFiles
							)) || []
						textElements.forEach((item: any) => {
							elements.push(item)
						})
					} catch (e:any) {
						throw new Error(e.message)
					}
				}
				break
			// 组合类型
			case 'grpSp': {
				if (value) {
					const grpSpEntry = Object.entries(value)

					try {
						if (grpSpEntry[0][0] === '0') {
							for (let j = 0; j < grpSpEntry.length; j++) {
								const [, value]: any = grpSpEntry[j]
								const grpSpEntryValue = Object.entries(value)

								const grpSpPr = grpSpEntryValue.filter(
									(item) => item[0] === 'grpSpPr'
								)
								// 组合元素基本位置
								const grpSpPosition = await getPosition(
									grpSpPr[0][1],
									slideRelsObject,
									pptxFiles
								)
								const grpSpElements = await changeElements(
									slideIndex,
									spTreeFromNum,
									grpSpEntryValue,
									spTreeEntryNoCompact,
									slideRelsObject,
									slideMaster,
									pptxFiles,
									options
								)

								const groupId = createRandomCode()

								const leftOff: number[] = []
								const topOff: number[] = []
								const grpPosition = grpSpPrPosition.pop()
								grpSpElements.forEach((item: any) => {
									if (grpPosition) {
										const position = getGrpSPPosition(grpPosition, {
											left: item.element.left,
											top: item.element.top,
											width: item.element.width,
											height: item.element.height
										})
										// 兼容解析出来的元素宽高很小，其实际位置可能是需要按照比例与组合元素基本位置计算
										if (item.element.width < 1 || item.element.height < 1) {
											item.element.left = position.left
											item.element.top = position.top
											item.element.width = position.width
											item.element.height = position.height
										}
									}
									// if (!item.element?.groupId) {
										if (leftOff?.[0]) {
											leftOff.push(item.element.left - leftOff[0])
											topOff.push(item.element.top - topOff[0])
										} else {
											leftOff.push(item.element.left)
											topOff.push(item.element.top)
										}
									// }
								})
								leftOff[0] = 0
								topOff[0] = 0
								const bgColor = grpSpBgClr.pop()

								let minTop = -1
								let minLf = -1
								grpSpElements.forEach((item: any) => {
									if (minLf < 0) {
										minLf = item.element.left
										minTop = item.element.top
									}
									if (minLf > item.element.left) {
										minLf = item.element.left
									}
									if (minTop > item.element.top) {
										minTop = item.element.top
									}
								})
								// 计算组合中元素相对于基本位置的偏移量
								const lOff = grpSpPosition?.left - minLf
								const tOff = grpSpPosition?.top - minTop
								grpSpElements.forEach((item: any) => {
									if (grpSpElements.length > 1) {
										item.element.left = item.element.left + lOff
										item.element.top = item.element.top + tOff
									}
									item.element.groupId = groupId
									if (item.element.type === 'text') {
										if (bgColor) {
											item.element.fill = bgColor
										}
									}
									// 音频控件宽高固定，不放大或缩小
									if (
										item.element.type === 'elf' &&
										item.element.subtype === 'elf-audio'
									) {
										item.element.width = 400
										item.element.height = 50
									}
									elements.push(item)
								})
							}
						} else {
							const grpSpPr = grpSpEntry.filter((item) => item[0] === 'grpSpPr')
							// 组合元素基本位置
							const grpSpPosition = await getPosition(
								grpSpPr[0][1],
								slideRelsObject,
								pptxFiles
							)
							const grpSpElements = await changeElements(
								slideIndex,
								spTreeFromNum,
								grpSpEntry,
								spTreeEntryNoCompact,
								slideRelsObject,
								slideMaster,
								pptxFiles,
								options
							)
							const groupId = createRandomCode()

							const bgColor = grpSpBgClr.pop()
							const grpPosition = grpSpPrPosition.pop()

							let minTop = -1
							let minLf = -1
							grpSpElements.forEach((item: any) => {
								if (minLf < 0) {
									minLf = item.element.left
									minTop = item.element.top
								}
								if (minLf > item.element.left) {
									minLf = item.element.left
								}
								if (minTop > item.element.top) {
									minTop = item.element.top
								}
							})

							// 计算组合中元素相对于基本位置的偏移量
							const lOff = grpSpPosition?.left - minLf
							const tOff = grpSpPosition?.top - minTop
							grpSpElements.forEach((item: any) => {
								if (grpPosition) {
									const position = getGrpSPPosition(grpPosition, {
										left: item.element.left,
										top: item.element.top,
										width: item.element.width,
										height: item.element.height
									})
									if (grpSpElements.length > 1) {
										item.element.left = item.element.left + lOff
										item.element.top = item.element.top + tOff
									}
									item.element.groupId = groupId
									item.element.left = position.left
									item.element.top = position.top
									item.element.width = position.width
									item.element.height = position.height
								}
								if (item.element.type === 'text') {
									if (bgColor) {
										item.element.fill = bgColor
									}
								}
								// 音频控件宽高固定，不放大或缩小
								if (
									item.element.type === 'elf' &&
									item.element.subtype === 'elf-audio'
								) {
									item.element.width = 400
									item.element.height = 50
								}
								elements.push(item)
							})
						}
					} catch (e:any) {
						throw new Error(e.message)
					}
				}
				break
			}
			case 'grpSpPr': {
				if (value?.solidFill) {
					if (value?.solidFill?.schemeClr?.attributes?.val) {
						const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
						const fillColor = getBackGroundColor(
							slideMasterNum,
							value.solidFill.schemeClr.attributes.val,
							value.solidFill.schemeClr?.lumMod?.attributes?.val,
							value.solidFill.schemeClr?.lumOff?.attributes?.val
						)
						if (fillColor) {
							grpSpBgClr.push('#' + fillColor)
						}
					} else if (value?.solidFill?.srgbClr) {
						grpSpBgClr.push('#' + value.solidFill.srgbClr.attributes.val)
					}
				} else {
					grpSpBgClr.push('')
				}
				if (value?.xfrm) {
					const position = value
					grpSpPrPosition.push(position)
				}
				break
			}
			// 媒体
			case 'pic':
				{
					const picsArray = []
					if (!Array.isArray(value)) {
						picsArray.push(value)
					} else {
						picsArray.push(...value)
					}
					for (let j = 0; j < picsArray.length; j++) {
						try {
							const mediaElements =
								(await changeMedia(
									slideIndex,
									picsArray[j],
									slideRelsObject,
									slideMaster,
									pptxFiles,
									options.upload
								)) || []
							mediaElements.forEach((item: any) => {
								if (item) {
									elements.push(item)
								}
							})
						} catch (e:any) {
							throw new Error(e.message)
						}
					}
				}

				break
			case 'graphicFrame':
				{
					const graphicFrameArray = []
					if (!Array.isArray(value)) {
						graphicFrameArray.push(value)
					} else {
						graphicFrameArray.push(...value)
					}
					const picFromGraphicFrame: any[] = []
					graphicFrameArray.forEach((graphicFrame: any) => {
						if (
							graphicFrame?.graphic?.graphicData?.['mc:AlternateContent']?.[
								'mc:Fallback'
							]?.oleObj?.pic
						) {
							picFromGraphicFrame.push(
								graphicFrame?.graphic?.graphicData?.['mc:AlternateContent']?.[
									'mc:Fallback'
								]?.oleObj?.pic
							)
							picFromGraphicFrame[picFromGraphicFrame.length - 1].id =
								graphicFrame?.nvGraphicFramePr?.cNvPr?.attributes?.id
						}
					})
					if (picFromGraphicFrame) {
						const picsArray: any[] = []
						if (!Array.isArray(picFromGraphicFrame)) {
							picsArray.push(picFromGraphicFrame)
						} else {
							picsArray.push(...picFromGraphicFrame)
						}
						for (let j = 0; j < picsArray.length; j++) {
							try {
								const mediaElements =
									(await changeMedia(
										slideIndex,
										picsArray[j],
										slideRelsObject,
										slideMaster,
										pptxFiles,
										options.upload
									)) || []
								mediaElements.forEach((item: any) => {
									if (item) {
										item.graphicFrameId = picsArray[j].id
										elements.push(item)
									}
								})
							} catch (e:any) {
								throw new Error(e.message)
							}
						}
					}
				}
				break
			default:
				break
		}
		options.afterParseElement &&
			options.afterParseElement(
				spTreeEntry[i],
				elements[i],
				i + 1,
				spTreeEntry.length
			)
	}
	return elements
}

const changeSlide = async (
	slideIndex: number,
	slideFile: any,
	slideObject: any,
	slideNoCompactObject: any,
	pptxFiles: any,
	options: PptxToJsonOption,
	slideTotalCount: number
) => {
	const elementsObject: any = []
	// 幻灯片相关
	const slideName = slideFile.name.replace('ppt/slides/', '')
	const slideRelsFile = pptxFiles[`ppt/slides/_rels/${slideName}.rels`]
	const slideRelsContent = await slideRelsFile.async('string')
	const slideRelsObject = window.xml2js(slideRelsContent, xmlToJsOption)

	let slideLayoutName = ''
	const slideRelsObjectArr = []
	if (!Array.isArray(slideRelsObject.Relationships.Relationship)) {
		slideRelsObjectArr.push(slideRelsObject.Relationships.Relationship)
	} else {
		slideRelsObjectArr.push(...slideRelsObject.Relationships.Relationship)
	}
	slideRelsObjectArr?.forEach((rel: any) => {
		const target: string = rel.attributes?.Target as string | ''
		if (target.indexOf('slideLayout') !== -1) {
			slideLayoutName = target.replace('../slideLayouts/', '')
		}
	})

	let slideMaster = 'slideMaster1.xml'
	let slideLayoutObject
	let slideLayoutRelsObject
	let slideLayoutNoCompactObject
	if (slideLayoutName) {
		// 幻灯片采用的版式相关
		const slideLayoutRelsFile =
			pptxFiles[`ppt/slideLayouts/_rels/${slideLayoutName}.rels`]
		const slideLayoutRelsContent = await slideLayoutRelsFile.async('string')
		slideLayoutRelsObject = window.xml2js(slideLayoutRelsContent, xmlToJsOption)
		const slideLayoutFile = pptxFiles[`ppt/slideLayouts/${slideLayoutName}`]
		const slideLayoutFileContent = await slideLayoutFile.async('string')
		slideLayoutObject = window.xml2js(slideLayoutFileContent, xmlToJsOption)
		slideLayoutNoCompactObject = window.xml2js(
			slideLayoutFileContent,
			xmlToJsOptionNoCompact
		)

		const slideLayoutRelsArr = []
		if (!Array.isArray(slideLayoutRelsObject.Relationships.Relationship)) {
			slideLayoutRelsArr.push(slideLayoutRelsObject.Relationships.Relationship)
		} else {
			slideLayoutRelsArr.push(
				...slideLayoutRelsObject.Relationships.Relationship
			)
		}

		slideLayoutRelsArr.forEach((rel: any) => {
			const target: string = rel.attributes?.Target as string | ''
			if (target.indexOf('slideMaster') !== -1) {
				slideMaster = target.replace('../slideMasters/', '')
			}
		})
	}

	const background = await getSlidBackground(
		pptxFiles,
		options,
		slideObject,
		slideRelsObject,
		slideLayoutObject,
		slideLayoutRelsObject,
		slideMaster
	)
	const slide: Slide = {
		id: createRandomCode(8),
		elements: [],
		background: background
	}
	options.beforeParseSlide &&
		options.beforeParseSlide(slideFile, slide, slideIndex, slideTotalCount)
	const spTreeEntry = Object.entries(slideObject.sld.cSld.spTree)

	let elementsMaster: any[] = []
	// 兼容隐藏背景图形的情况
	if (slideLayoutObject.sldLayout.attributes?.showMasterSp === '0') {
		// 若版式上隐藏背景图形，则不解析母版元素
		elementsMaster = []
	} else {
		// 解析母版
		let spTreeEntryMasterNoCompact
		const slideMasterRelsFile =
			pptxFiles[`ppt/slideMasters/_rels/${slideMaster}.rels`]
		const slideMasterRelsContent = await slideMasterRelsFile.async('string')
		const slideMasterRelsObject = window.xml2js(
			slideMasterRelsContent,
			xmlToJsOption
		)
		const slideMasterPath = pptxFiles[`ppt/slideMasters/${slideMaster}`]
		const slideMasterContent = await slideMasterPath.async('string')
		const slideMasterObject = window.xml2js(slideMasterContent, xmlToJsOption)
		const slideMasterNoCompactObject = window.xml2js(
			slideMasterContent,
			xmlToJsOptionNoCompact
		)
		slideMasterNoCompactObject?.elements.forEach((elements: any) => {
			if (elements.name === 'sldMaster') {
				elements.elements?.forEach((item: any) => {
					if (item.name === 'cSld') {
						spTreeEntryMasterNoCompact = item.elements?.find(
							(item: any) => item.name === 'spTree'
						)
					}
				})
			}
		})
		const masterSpTree = Object.entries(
			slideMasterObject.sldMaster.cSld?.spTree
		)
		const elementsMasterResult = await changeElements(
			slideIndex,
			spTreeFrom.master,
			masterSpTree,
			slideMasterNoCompactObject,
			slideMasterRelsObject,
			slideMaster,
			pptxFiles,
			options
		)
		elementsMaster =
			changeElementLevel(elementsMasterResult, spTreeEntryMasterNoCompact) || []
	}
	// 解析版式
	let elementsLayout: any[] = []
	// 兼容隐藏背景图形的情况
	if (slideObject.sld.attributes?.showMasterSp === '0') {
		// 若幻灯片上隐藏背景图形，则不解析版式和母版元素
		elementsLayout = []
		elementsMaster = []
	} else {
		let spTreeEntryLayoutNoCompact
		slideLayoutNoCompactObject?.elements.forEach((elements: any) => {
			if (elements.name === 'sldLayout') {
				elements.elements?.forEach((item: any) => {
					if (item.name === 'cSld') {
						spTreeEntryLayoutNoCompact = item.elements?.find(
							(item: any) => item.name === 'spTree'
						)
					}
				})
			}
		})
		const layoutSpTree = Object.entries(
			slideLayoutObject.sldLayout.cSld?.spTree
		)
		const elementsLayoutResult = await changeElements(
			slideIndex,
			spTreeFrom.layout,
			layoutSpTree,
			slideLayoutNoCompactObject,
			slideLayoutRelsObject,
			slideMaster,
			pptxFiles,
			options
		)
		elementsLayout =
			changeElementLevel(elementsLayoutResult, spTreeEntryLayoutNoCompact) || []
	}

	grpSpBgClr = []
	grpSpPrPosition = []

	// 解析幻灯片
	let spTreeEntryNoCompact
	slideNoCompactObject?.elements.forEach((elements: any) => {
		if (elements.name === 'sld') {
			elements.elements?.forEach((item: any) => {
				if (item.name === 'cSld') {
					spTreeEntryNoCompact = item.elements?.find(
						(item: any) => item.name === 'spTree'
					)
				}
			})
		}
	})
	const elementsResult =
		(await changeElements(
			slideIndex,
			spTreeFrom.slide,
			spTreeEntry,
			spTreeEntryNoCompact,
			slideRelsObject,
			slideMaster,
			pptxFiles,
			options
		)) || []
	elementsResult.forEach((element: any) => {
		elementsObject.push(element)
	})
	const elements = changeElementLevel(elementsObject, spTreeEntryNoCompact)
	if (elements) {
		// 合并幻灯片、版式、母版元素
		slide.elements = [...elementsMaster, ...elementsLayout, ...elements]
	}

	options.afterParseSlide &&
		options.afterParseSlide(slideFile, slide, slideIndex, slideTotalCount)
	return slide
}

export default async (pptxFiles: any, options: PptxToJsonOption) => {
	const slides: Slide[] = []
	const allFilesNames = Object.keys(pptxFiles)
	let slidesFiles =
		minimatch.match(allFilesNames, pptxFilePath.SlidePath, {
			matchBase: true
		}) || []
	const themeFiles =
		minimatch.match(allFilesNames, pptxFilePath.themePath, {
			matchBase: true
		}) || []
	const slideMasterFiles =
		minimatch.match(allFilesNames, pptxFilePath.SlideMasterPath, {
			matchBase: true
		}) || []
	slidesFiles = _.sortBy(slidesFiles, (key) => {
		const reg = /\d+/g
		const num = reg.exec(key)
		if (num) {
			return parseInt(num[0])
		}
	})
	await getColorMap(themeFiles, pptxFiles, slideMasterFiles)
	await getDefaultTextSize(pptxFiles, 'ppt/presentation.xml')
	for (let i = 0; i < slidesFiles.length; i++) {
		logger.info(`start parse ppt ${i + 1} silde`)
		const slideFile = pptxFiles[slidesFiles[i]]
		const slideContent = await slideFile.async('string')
		const slideObject = window.xml2js(slideContent, xmlToJsOption)
		const slideNoCompactObject = window.xml2js(
			slideContent,
			xmlToJsOptionNoCompact
		)
		const slide = await changeSlide(
			i + 1,
			slideFile,
			slideObject,
			slideNoCompactObject,
			pptxFiles,
			options,
			slidesFiles.length
		)
		slides.push(slide)
		logger.info(`end parse ppt ${i + 1} silde`)
	}
	return slides
}
