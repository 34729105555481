import Dexie from 'dexie'
import { Slide } from '@/types/slides'

export interface Snapshot {
    uuid: string
    index: number
    slides: Slide[]
    viewportRatio: number
}

export default class SnapshotDatabase extends Dexie {
    private _uuid = 'snapshots'
    private _snapshots: Dexie.Table<Snapshot, number>

    public constructor(uuid: string) {
        super('SnapshotDatabase')
        this._uuid = uuid
        this.version(1).stores({
            snapshots: '++id'
        })
        this._snapshots = this.table('snapshots')
    }
    public get uuid(): string {
        return this._uuid
    }
    public get snapshots() {
        return this._snapshots
    }
    public setSnapshotsUuid(uuid: string) {
        this._uuid = uuid
    }
    public async clear() {
        const keys = await this.snapshots
            .filter((snapshot) => snapshot.uuid === this._uuid)
            .keys()
        this.snapshots.bulkDelete(keys)
    }
    public async clearByUuid(uuid: string) {
        const keys = await this.snapshots
            .filter((snapshot) => snapshot.uuid === uuid)
            .keys()
        await this.snapshots.bulkDelete(keys)
    }
}
