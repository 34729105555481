// https://iconpark.bytedance.com/official

import {
	AddText,
	Airplay,
	AlignBottom,
	AlignHorizontally,
	AlignLeft,
	AlignRight,
	AlignTextBottomOne,
	AlignTextCenter,
	AlignTextLeft,
	AlignTextMiddleOne,
	AlignTextRight,
	AlignTextTopOne,
	AlignTop,
	AlignVertically,
	ArrowCircleLeft,
	AudioFile,
	Back,
	BackgroundColor,
	Bookshelf,
	BringToFront,
	BringToFrontOne,
	ChartHistogram,
	ChartHistogramOne,
	ChartLine,
	ChartLineArea,
	ChartPie,
	ChartProportion,
	ChartRing,
	ChartScatter,
	Classroom,
	Clear,
	Click,
	Close,
	CloseSmall,
	Code,
	ColorFilter,
	Connection,
	Down,
	DownOne,
	Edit,
	Effects,
	Erase,
	Fill,
	FlipHorizontally,
	FlipVertically,
	FolderClose,
	FontSize,
	Format,
	FullScreen,
	FullScreenTwo,
	Fullwidth,
	Github,
	GraphicDesign,
	Group,
	Helpcenter,
	InsertTable,
	Left,
	LeftTwo,
	List,
	Lock,
	Log,
	Logout,
	Minus,
	MoreFour,
	MoreOne,
	MultiPictureCarousel,
	Music,
	Next,
	Notes,
	OrderedList,
	Pause,
	Performance,
	Piano,
	Picture,
	Platte,
	PlayOne,
	Plus,
	Ppt,
	Quote,
	Right,
	RightTwo,
	Rotate,
	RowHeight,
	Save,
	SendToBack,
	SentToBack,
	Strikethrough,
	Tailoring,
	Text,
	TextBold,
	TextItalic,
	TextRotationDown,
	TextRotationNone,
	TextUnderline,
	Theme,
	Transform,
	Undo,
	Ungroup,
	Unlock,
	UpOne,
	Video,
	Write,
	VolumeNotice
} from '@icon-park/vue-next'
import { App } from 'vue'

export default {
	install(app: App) {
		// 插入元素
		app.component('IconFontSize', FontSize)
		app.component('IconPicture', Picture)
		app.component('IconGraphicDesign', GraphicDesign)
		app.component('IconConnection', Connection)
		app.component('IconChartProportion', ChartProportion)
		app.component('IconInsertTable', InsertTable)
		app.component('IconAudioFile', AudioFile)
		app.component('IconPiano', Piano)
		app.component('IconMusic', Music)
		app.component('IconAirPlay', Airplay)
		app.component('IconVideo', Video)
		app.component('IconPerformance', Performance)
		app.component('IconClassRoom', Classroom)
		app.component('IconMultiPictureCarousel', MultiPictureCarousel)

		// 锁定与解锁
		app.component('IconLock', Lock)
		app.component('IconUnlock', Unlock)

		// 全屏
		app.component('IconFullScreen', FullScreen)
		app.component('IconFullScreenTwo', FullScreenTwo)

		// 撤销重做
		app.component('IconBack', Back)
		app.component('IconNext', Next)

		// 对齐
		app.component('IconAlignTop', AlignTop)
		app.component('IconAlignLeft', AlignLeft)
		app.component('IconAlignRight', AlignRight)
		app.component('IconAlignBottom', AlignBottom)
		app.component('IconAlignVertically', AlignVertically)
		app.component('IconAlignHorizontally', AlignHorizontally)

		// 层级
		app.component('IconBringToFront', BringToFront)
		app.component('IconSentToBack', SentToBack)
		app.component('IconBringToFrontOne', BringToFrontOne)
		app.component('IconSendToBack', SendToBack)

		// 组合
		app.component('IconGroup', Group)
		app.component('IconUngroup', Ungroup)

		// 通用元素编辑
		app.component('IconFill', Fill)
		app.component('IconBackgroundColor', BackgroundColor)
		app.component('IconPlatte', Platte)

		// 图片编辑
		app.component('IconTailoring', Tailoring)
		app.component('IconColorFilter', ColorFilter)
		app.component('IconFlipVertically', FlipVertically)
		app.component('IconFlipHorizontally', FlipHorizontally)

		// 文字编辑
		app.component('IconText', Text)
		app.component('IconAddText', AddText)
		app.component('IconAlignTextLeft', AlignTextLeft)
		app.component('IconAlignTextRight', AlignTextRight)
		app.component('IconAlignTextCenter', AlignTextCenter)
		app.component('IconRowHeight', RowHeight)
		app.component('IconFullwidth', Fullwidth)
		app.component('IconCode', Code)
		app.component('IconTextBold', TextBold)
		app.component('IconTextItalic', TextItalic)
		app.component('IconTextUnderline', TextUnderline)
		app.component('IconStrikethrough', Strikethrough)
		app.component('IconQuote', Quote)
		app.component('IconList', List)
		app.component('IconOrderedList', OrderedList)
		app.component('IconUpOne', UpOne)
		app.component('IconDownOne', DownOne)
		app.component('IconFormat', Format)
		app.component('IconAlignTextTopOne', AlignTextTopOne)
		app.component('IconAlignTextBottomOne', AlignTextBottomOne)
		app.component('IconAlignTextMiddleOne', AlignTextMiddleOne)

		// 箭头与符号
		app.component('IconDown', Down)
		app.component('IconLeftTwo', LeftTwo)
		app.component('IconRightTwo', RightTwo)
		app.component('IconPlus', Plus)
		app.component('IconMinus', Minus)
		app.component('IconClose', Close)
		app.component('IconCloseSmall', CloseSmall)

		// 图表
		app.component('IconChartHistogram', ChartHistogram)
		app.component('IconChartHistogramOne', ChartHistogramOne)
		app.component('IconChartLine', ChartLine)
		app.component('IconChartLineArea', ChartLineArea)
		app.component('IconChartScatter', ChartScatter)
		app.component('IconChartPie', ChartPie)
		app.component('IconChartRing', ChartRing)

		// 其他
		app.component('IconPlayOne', PlayOne)
		app.component('IconPpt', Ppt)
		app.component('IconHelpcenter', Helpcenter)
		app.component('IconGithub', Github)
		app.component('IconWrite', Write)
		app.component('IconErase', Erase)
		app.component('IconEffects', Effects)
		app.component('IconRotate', Rotate)
		app.component('IconEdit', Edit)
		app.component('IconUndo', Undo)
		app.component('IconTransform', Transform)
		app.component('IconClick', Click)
		app.component('IconTheme', Theme)
		app.component('IconArrowCircleLeft', ArrowCircleLeft)
		app.component('IconLogout', Logout)
		app.component('IconClear', Clear)
		app.component('IconFolderClose', FolderClose)
		app.component('IconSave', Save)
		app.component('IconBookshelf', Bookshelf)
		app.component('IconMoreFour', MoreFour)
		app.component('IconMoreOne', MoreOne)
		app.component('IconLeft', Left)
		app.component('IconRight', Right)
		app.component('IconTextRotationNone', TextRotationNone)
		app.component('IconTextRotationDown', TextRotationDown)
		app.component('IconNotes', Notes)
		app.component('IconPause', Pause)
		app.component('IconVolumeNotice', VolumeNotice)
		app.component('IconLog', Log)
	}
}
