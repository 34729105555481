// eslint-disable
import xgplayer from 'xgplayer'

const {
  hasClass
} = xgplayer.util

const play = function () {
  // eslint-disable-next-line
  const player = this

  function onPlayBtnClick() {
    if (!player.config.allowPlayAfterEnded && player.ended) {
      return
    }
    if (hasClass(player.root, 'xgplayer-nostart') || player.ended) {
      player.start()
    }
    if (player.paused) {
      const playPromise = player.play()
      if (playPromise !== undefined && playPromise) {
        playPromise.catch(err => {
          //
        })
      }
    } else {
      player.pause()
    }
  }

  player.on('playBtnClick', onPlayBtnClick)

  function onDestroy() {
    player.off('playBtnClick', onPlayBtnClick)
    player.off('destroy', onDestroy)
  }

  player.once('destroy', onDestroy)
}

export default play