export interface LinePoolItem {
	path: string
	style: string
	points: [string, string]
	viewBox: [number, number]
	isBroken?: boolean
	isCurve?: boolean
	isCustom?: boolean
	customPath?: string
	custom?: { x: number; y: number }[]
}

export const LINE_LIST = [
	{
    type: '直线',
    elementType: 'line',
		children: [
			{
				path: 'M 0 0 L 20 20',
				style: 'solid',
				points: ['', ''],
				viewBox: [20, 20]
			},
			{ path: 'M 0 0 L 20 20', style: 'dashed', points: ['', ''],
      viewBox: [20, 20]},
			{ path: 'M 0 0 L 20 20', style: 'solid', points: ['', 'arrow'],
      viewBox: [20, 20]},
			{ path: 'M 0 0 L 20 20', style: 'dashed', points: ['', 'arrow'],
      viewBox: [20, 20]},
			{ path: 'M 0 0 L 20 20', style: 'solid', points: ['', 'dot'],
      viewBox: [20, 20]}
    ],

	},
	{
    type: '折线、曲线',
    elementType: 'line',
		children: [
			{
				path: 'M 0 0 L 0 20 L 20 20',
				style: 'solid',
				points: ['', 'arrow'],
				isBroken: true,
				viewBox: [20, 20]
			},
			{
				path: 'M 0 0 Q 0 20 20 20',
				style: 'solid',
				points: ['', 'arrow'],
				isCurve: true,
				viewBox: [20, 20]
			},
			{
				path: 'M20,0 Q 4,0,0,4 C0,10,6,8,18,13 C20,15,20,20,0,20 ',
				style: 'solid',
				points: ['', ''],
				isCustom: true,
				viewBox: [20, 20]
			}
		]
	}
]
