
import useUpLoadResource, { UpLoadReply } from '@/hooks/useUpLoadResource'
import logger from '@evideo/logger'
import { computed, defineComponent, ref } from "vue"
export default defineComponent({
  name: 'upload-progress',
  props: {
    filename: {
      type: String
    },
    file: {
      type: File,
      require: true
    },
    uploadType: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleClose() {
      this.$emit('cancel')
    },
    handleOk() {
      this.$emit('ok', this.reply)
    },
    async handleCancel() {
      if (this.checkpoint.uploadId) {
        await this.abort(this.checkpoint.name, this.checkpoint.uploadId)
      }
      this.$emit('cancel')
    },
  },
  // computed: {
  //   complete(): boolean {
  //     return this.percent === 100 && this.reply.url
  //   }
  // },
  setup(props: any) {
    const percent = ref<number>(0)
    const reply = ref<UpLoadReply>()
    const checkpoint: any = ref<any>({})
    const isError = ref<boolean>(false)

    const { getResourceUrl, abort } = useUpLoadResource();

    (async () => {
      if (props.file) {
        const progress = (p: number, _checkpoint: any) => {
          checkpoint.value = _checkpoint
          percent.value = Math.floor(p * 100)
        }
        try {
          reply.value = await getResourceUrl(props.file, progress)
          percent.value = 100
        } catch (e: any) {
          if (e.status === 0) {
            logger.debug('取消上传成功')
          } else {
            logger.error(e)
            isError.value = true
          }
        }
      }
    })()

    const complete = computed(() => {
      return percent.value === 100 && reply.value?.url
    })
    return {
      percent,
      reply,
      checkpoint,
      abort,
      complete,
      isError
    }
  }
})
