
import { computed, defineComponent, PropType, provide } from 'vue'
import { ElementTypes, PPTElement } from '@/types/slides'

import BaseImageElement
  from '@/views/components/element/ImageElement/BaseImageElement.vue'
import BaseTextElement
  from '@/views/components/element/TextElement/BaseTextElement.vue'
import BaseShapeElement
  from '@/views/components/element/ShapeElement/BaseShapeElement.vue'
import BaseLineElement
  from '@/views/components/element/LineElement/BaseLineElement.vue'
import BaseChartElement
  from '@/views/components/element/ChartElement/BaseChartElement.vue'
import BaseTableElement
  from '@/views/components/element/TableElement/BaseTableElement.vue'
import BaseElfElement
  from '@/views/components/element/ElfElement/BaseElfElement.vue'
import BaseLinkButtonElement
  from '@/views/components/element/LinkButtonElement/BaseLinkButtonElement.vue'

export default defineComponent({
  name: 'base-element',
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    elementIndex: {
      type: Number,
      required: true,
    },
    previewSign: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props) {
    const currentElementComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.IMAGE]: BaseImageElement,
        [ElementTypes.TEXT]: BaseTextElement,
        [ElementTypes.SHAPE]: BaseShapeElement,
        [ElementTypes.LINE]: BaseLineElement,
        [ElementTypes.CHART]: BaseChartElement,
        [ElementTypes.TABLE]: BaseTableElement,
        [ElementTypes.ELF]: BaseElfElement,
        [ElementTypes.LINKBUTTON]: BaseLinkButtonElement
      }
      return elementTypeMap[props.elementInfo.type] || null
    })
    provide('previewSign', props.previewSign)

    return {
      currentElementComponent,
    }
  },
})
