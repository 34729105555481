(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("dexie"), require("jszip"), require("xgplayer"), require("lodash"), require("pptxgenjs"), require("@antv/g2"), require("svg-pathdata"), require("@evideo/web-music-grade"), require("crypto-js"), require("sortablejs"), require("pako"), require("moment"), require("axios"), require("@antv/x6"), require("@better-scroll/core"), require("ali-oss"));
	else if(typeof define === 'function' && define.amd)
		define(["dexie", "jszip", "xgplayer", "lodash", "pptxgenjs", "@antv/g2", "svg-pathdata", "@evideo/web-music-grade", "crypto-js", "sortablejs", "pako", "moment", "axios", "@antv/x6", "@better-scroll/core", "ali-oss"], factory);
	else if(typeof exports === 'object')
		exports["mohuan-ppt"] = factory(require("dexie"), require("jszip"), require("xgplayer"), require("lodash"), require("pptxgenjs"), require("@antv/g2"), require("svg-pathdata"), require("@evideo/web-music-grade"), require("crypto-js"), require("sortablejs"), require("pako"), require("moment"), require("axios"), require("@antv/x6"), require("@better-scroll/core"), require("ali-oss"));
	else
		root["mohuan-ppt"] = factory(root["Dexie"], root["JSZip"], root["xgplayer"], root["_"], root["PptxGenJS"], root["G2"], root["svgpathdata"], root["WebMusicGrade"], root["CryptoJS"], root["Sortable"], root["pako"], root["moment"], root["axios"], root["X6"], root["BScroll"], root["OSS"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0c53__, __WEBPACK_EXTERNAL_MODULE__5877__, __WEBPACK_EXTERNAL_MODULE__5de4__, __WEBPACK_EXTERNAL_MODULE__60bb__, __WEBPACK_EXTERNAL_MODULE__6388__, __WEBPACK_EXTERNAL_MODULE__7c93__, __WEBPACK_EXTERNAL_MODULE__9d5c__, __WEBPACK_EXTERNAL_MODULE_a15b0__, __WEBPACK_EXTERNAL_MODULE_a2c5__, __WEBPACK_EXTERNAL_MODULE_a352__, __WEBPACK_EXTERNAL_MODULE_a4b3__, __WEBPACK_EXTERNAL_MODULE_c32d__, __WEBPACK_EXTERNAL_MODULE_cebe__, __WEBPACK_EXTERNAL_MODULE_d15b__, __WEBPACK_EXTERNAL_MODULE_e29a__, __WEBPACK_EXTERNAL_MODULE_eebb__) {
return 