import { IBarChartOptions, ILineChartOptions, IPieChartOptions } from 'chartist'

export type TurningMode =
	| 'no'
	| 'fade'
	| 'slideX'
	| 'slideY'
	| 'random'
	| 'slideX3D'
	| 'slideY3D'
	| 'rotate'
	| 'scaleY'
	| 'scaleX'
	| 'scale'
	| 'scaleReverse'

export type AnimationAudioType =
	| ''
	| 'Applause'
	| 'Arrow'
	| 'Bomb'
	| 'Camera'
	| 'Cashreg'
	| 'Chimes'
	| 'Coin'
	| 'Drumroll'
	| 'Laser'
	| 'Wind'

export interface PPTElementShadow {
	h: number
	v: number
	blur: number
	color: string
}

export const enum ShapePathFormulasKeys {
	ROUND_RECT = 'roundRect',
	ROUND_RECT_DIAGONAL = 'roundRectDiagonal',
	ROUND_RECT_SINGLE = 'roundRectSingle',
	ROUND_RECT_SAMESIDE = 'roundRectSameSide',
	CUT_RECT_DIAGONAL = 'cutRectDiagonal',
	CUT_RECT_SINGLE = 'cutRectSingle',
	CUT_RECT_SAMESIDE = 'cutRectSameSide',
	MESSAGE = 'message',
	ROUND_MESSAGE = 'roundMessage',
	L = 'L',
	RING_RECT = 'ringRect',
	PLUS = 'plus',
	TRIANGLE = 'triangle',
	PARALLELOGRAM_LEFT = 'parallelogramLeft',
	PARALLELOGRAM_RIGHT = 'parallelogramRight',
	TRAPEZOID = 'trapezoid',
	BULLET = 'bullet',
	INDICATOR = 'indicator'
}

export const enum ElementTypes {
	TEXT = 'text',
	IMAGE = 'image',
	SHAPE = 'shape',
	LINE = 'line',
	CHART = 'chart',
	TABLE = 'table',
	ELF = 'elf',
	LINKBUTTON = 'link-button'
}

export interface PPTElementOutline {
	style?: 'dashed' | 'solid'
	width?: number
	color?: string
}

export interface PPTElementLink {
	type: 'web-url' | 'slide'
	target: string // 类型slide的target为 slideIndex-slideId
}

export type NextStepEvents = {
	command: string
	data?: any
}

interface PPTBaseElement {
	id: string
	left: number
	top: number
	lock?: boolean
	groupId?: string
	width: number
	height: number
	link?: PPTElementLink
	focusOrder?: number
	title?: string
	nextStepEvents?: any
}
interface PPTFileBaseElement {
	src: string
	size?: number
	md5?: string
}

export interface PPTElfElement extends PPTBaseElement {
	type: 'elf'
	subtype: string
	editPreview: boolean
	scaleLock?: boolean
	focusOrder?: number
}

export interface PPTElfAudioElement extends PPTElfElement, PPTFileBaseElement {
	name: string
	src: string
	duration?: number
	clipStart?: number
	clipEnd?: number
}

export interface PPTElfVideoElement extends PPTElfElement, PPTFileBaseElement {
	loop?: boolean
	name: string
	src: string
	poster: string
	videoSize: number
	posterSize: number
	duration?: number
	clipStart?: number
	clipEnd?: number
}

export interface PPTElfProjectionElement extends PPTElfElement {
	content: string
}

// deprecated
export interface PPTElfSheetMusicElement extends PPTElfElement {
	src: string
	displayMode: 'wuxianpu' | 'jianpu' | 'jianxian'
	scale: number
}

export function isIntenalKey(key: string) {
	return key.startsWith('_')
}
export interface PPTElfQuestionsElement extends PPTElfElement {
	sid: string
	dataFrom: string
	defaultAnswer?: boolean // 显示答案
	defaultBackground?: boolean // 启用默认背景
	backgroundImage?: string // 背景图片地址（①本地提供的背景：填充key值，如imageBg1、imageBg2；②上传地址，则填充上传的图片地址）
	useBackground?: boolean // 启用背景
	// 详见：http://192.168.74.37:3000/project/34/interface/api/1031
	_meta?: any
}

export interface PPTElfPKQuestionsElement extends PPTElfElement {
	sid: string
	backgroundImage?: string
	questionSetting?: { aIEnable?: boolean; mode?: string }
	_meta?: any
}

// 演唱、演奏歌曲（谱例歌曲）
export interface PPTElfSongElement extends PPTElfElement {
	sid: string
	dataFrom: string
	source: string
	displayMode?: string | undefined // inset-嵌入 full-铺满
	useInsetPlayControl?: boolean // 启用嵌入曲谱播放开关
	insetPlaySetting?: {
		// 嵌入播放配置参数
		speed: number
		sourceSpeed: number
		displayMode: number
		playMode: string
		isBeat: boolean
		fullSheet: boolean
	}
	songParams?: {
		playMode?: string
		closeAreaRange?: number[] | undefined
		playScoreParams?: {
			score?: boolean
			scorePart?: number
			scoreType?: number
			scoreLevel?: string
			pitchBar?: boolean
			level?: string
		}
		playParams?: {
			closeAreaSelection?: boolean
			colvin?: boolean
			fullSheet?: boolean
			isBeat?: boolean
			isNoVolume?: boolean
			isPreReadyTime?: boolean
			isRhythm?: boolean
			keySignature?: string
			meodly?: string
			partInfo?: []
			pitchSemitones?: number
			rollCall?: boolean
			showLyrics?: boolean
			showMetronomeSignature?: boolean
			speed?: number
			tempo?: number
		}
	}
	songConfig?: {
		background?: {
			color?: string
			imageUrl?: string // （①本地提供的背景：填充key值，如imageBg1、imageBg2；②上传地址，则填充上传的图片地址）
		}
	}
	sheetConfig?: {
		colvinMelody?: {
			melodyLineColor?: string // 旋律线颜色填充 默认#8c6ffb
			melodyLineWidth?: number // 旋律线宽度 默认3
			melodyLineImage?: string // 旋律线颜色光标图案 （①本地提供的图片：填充key值，如melodyImage1、melodyImage2...；②上传地址，则填充上传的图片地址）
		}
	}
	// 详见：http://192.168.74.37:3000/project/34/interface/api/1031
	_meta?: {
		id: number
		name: string
		song_type: number
		sheet_type: number
		speed: number
		picture_url: string[]
		evxml_file_url: string // 曲谱文件地址
		ori_file_url: string // 原唱文件地址
		acc_file_url: string // 伴唱文件地址
		acc_speed: number // 伴唱速度
	}
}

// 欣赏歌曲
export interface PPTElfEnjoyElement extends PPTElfElement {
	sid: string
	dataFrom: string

	// 如果本地调试的时候需要mock数据，可以在元素的index.ts里填充_meta
	// 详见: http://192.168.74.37:3000/project/34/interface/api/1070
	_meta?: {
		id: number
		name: string
		picture_url: string[]
		mp3_file_config: {
			song_name: string
			mp3_file_url: string
			para_info: {
				name: string
				start_time: number
				color: string
			}[] // 分段信息
		}[] // mp3文件配置信息
	}
}

// 律动歌曲
export interface PPTElfMusicRhythmElement extends PPTElfElement {
	sid: string
	dataForm: string

	// 详见: http://192.168.74.37:3000/project/34/interface/api/5647
	_meta?: {
		id: number
		name: string
		mp3_file_url: string
		json_file_url: any
	}
}
export interface PPTElfSheetMusic {
	name?: string
	path?: string
	md5?: string
	size?: number
}
export interface PPTElfEnsemblePracticeElement extends PPTElfElement {
	sheetMusics: Array<PPTElfSheetMusic & { upload?: boolean }>
	accompanimentUrl: string
	accompanimentSize: number
	accompanimentMd5: string
	accompanimentSpeed: number
	timeSignature: string
	size: number
}
export interface PPTElfCarouseImagelElement extends PPTElfElement {
	images: Array<{
		name: string
		url: string
		size: number
	}>
	direction: 'horizontal' | 'vertical' // 方向
	speed: number // 切换速度
	autoPlay: {
		enable: boolean // 是否启动自动播放
		delay: number // 自动切换的时间间隔，单位ms
		disableOnInteraction: boolean // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
	}
	pagination: {
		enable: boolean // 启动分页
		type: 'bullets' | 'fraction' | 'progressbar' // 分页样式
		clickable: boolean // 点击分页器的指示点分页器会控制Swiper切换。
		position: 'top' | 'bottom' | 'left' | 'right'
	}
	navigation: boolean // 启动箭头分页
	loop: boolean // 循环播放
	size: number
}

export const enum ElfElementTypes {
	NONE = 'elf-none',
	AUDIO = 'elf-audio',
	SHEET_MUSIC = 'elf-sheetmusic',
	PROJECTION = 'elf-projection',
	VIDEO = 'elf-video',
	ENSEMBLE_PRACTICE = 'elf-emsemble-practice',
	SING_PLAY = 'elf-sing-play',
	ENJOY = 'elf-enjoy',
	CAROUSE_IMAGE = 'elf-carouse-image',
	MUSIC_RHYTHM = 'elf-music-rhythm',
	QUESTIONS_BANK = 'elf-questions-bank',
	PK_QUESTIONS = 'elf-pk-questions'
}

export interface PPTTextElement extends PPTBaseElement {
	type: 'text'
	content: string
	rotate: number
	defaultFontName: string
	defaultColor: string
	outline?: PPTElementOutline
	fill?: string
	lineHeight?: number
	wordSpace?: number
	opacity?: number
	shadow?: PPTElementShadow
	vertical?: boolean
}

export interface ImageOrShapeFlip {
	flipH?: boolean
	flipV?: boolean
}
export interface ImageElementFilters {
	blur?: string
	brightness?: string
	contrast?: string
	grayscale?: string
	saturate?: string
	'hue-rotate'?: string
	opacity?: string
}
export interface ImageElementClip {
	range: [[number, number], [number, number]]
	shape: string
}
export interface PPTImageElement extends PPTBaseElement, PPTFileBaseElement {
	type: 'image'
	fixedRatio: boolean
	src: string
	rotate: number
	outline?: PPTElementOutline
	filters?: ImageElementFilters
	clip?: ImageElementClip
	flipH?: boolean
	flipV?: boolean
	shadow?: PPTElementShadow
}

export interface ShapeGradient {
	type: 'linear' | 'radial'
	color: [string, string]
	rotate: number
}
export interface ShapeText {
	content: string
	defaultFontName: string
	defaultColor: string
	align: 'top' | 'middle' | 'bottom'
}
export interface PPTShapeElement extends PPTBaseElement {
	type: 'shape'
	viewBox: [number, number] | number
	path: string
	fixedRatio: boolean
	fill: string
	gradient?: ShapeGradient
	rotate: number
	outline?: PPTElementOutline
	opacity?: number
	flipH?: boolean
	flipV?: boolean
	shadow?: PPTElementShadow
	special?: boolean
	text?: ShapeText
	pathFormula?: ShapePathFormulasKeys
	keypoint?: number
}

export interface PPTLineElement extends Omit<PPTBaseElement, 'height'> {
	type: 'line'
	start: [number, number]
	end: [number, number]
	style: string
	color: string
	points: [string, string]
	path?: string
	customPath?: string
	shadow?: PPTElementShadow
	broken?: [number, number]
	curve?: [number, number]
	custom?: { x: number; y: number }[]
}

export type ChartType = 'bar' | 'line' | 'pie'
export interface ChartData {
	labels: string[]
	series: number[][]
}
export interface PPTChartElement extends PPTBaseElement {
	type: 'chart'
	fill?: string
	chartType: ChartType
	data: ChartData
	options?: ILineChartOptions & IBarChartOptions & IPieChartOptions
	outline?: PPTElementOutline
	themeColor: string
	gridColor?: string
}

export interface TableCellStyle {
	bold?: boolean
	em?: boolean
	underline?: boolean
	strikethrough?: boolean
	color?: string
	backcolor?: string
	fontsize?: string
	fontname?: string
	align?: 'left' | 'center' | 'right'
}
export interface TableCell {
	id: string
	colspan: number
	rowspan: number
	text: string
	style?: TableCellStyle
}
export interface TableTheme {
	color: string
	rowHeader: boolean
	rowFooter: boolean
	colHeader: boolean
	colFooter: boolean
}
export interface PPTTableElement extends PPTBaseElement {
	type: 'table'
	outline: PPTElementOutline
	theme?: TableTheme
	colWidths: number[]
	data: TableCell[][]
}

export interface PPTLinkButtonElement extends PPTBaseElement {
	type: 'link-button'
}

export type PPTElement =
	| PPTTextElement
	| PPTImageElement
	| PPTShapeElement
	| PPTLineElement
	| PPTChartElement
	| PPTTableElement
	| PPTElfAudioElement
	| PPTElfProjectionElement
	| PPTElfSheetMusicElement
	| PPTElfElement
	| PPTElfVideoElement
	| PPTLinkButtonElement

export type PPTAnimationGroup = 'in' | 'out' | 'attention' | 'other'

export type PPTAnimationTrigger = 'click' | 'meantime' | 'auto'

export interface PPTAnimation {
	id: string
	elId: string
	type: string
	group: PPTAnimationGroup
	trigger: PPTAnimationTrigger
	duration: number
	delay?: number
	runTimes?: number
	audio?: string
	triggerSource?: string
	path?: {
		path: string
		points: any
		width: number
		height: number
	}
}

export interface FormatedAnimation {
	animations: PPTAnimation[]
	autoNext: boolean
}
export interface TriggeredAnimation {
	[key: string]: {
		formatedAnimation: FormatedAnimation[]
		animationIndex: number
		isReset: boolean
	}
}

export interface SlideBackground {
	type: 'solid' | 'image' | 'gradient'
	color?: string
	image?: string
	size?: number
	imageSize?: 'cover' | 'contain' | 'repeat' | 'initial' | '100% 100%'
	gradientType?: 'linear' | 'radial'
	gradientColor?: [string, string]
	gradientRotate?: number
}

export interface Slide {
	id: string
	elements: PPTElement[]
	remark?: string
	background?: SlideBackground
	animations?: PPTAnimation[]
	turningMode?:
		| 'no'
		| 'fade'
		| 'slideX'
		| 'slideY'
		| 'random'
		| 'slideX3D'
		| 'slideY3D'
		| 'rotate'
		| 'scaleY'
		| 'scaleX'
		| 'scale'
		| 'scaleReverse'
	preview?: string
	previewSize?: number
	previewMd5?: string
}

export interface SlideTheme {
	backgroundColor: string
	themeColor: string
	fontColor: string
	fontName: string
	fontSize: string
	viewportRatio: number
	bestViewportRatio?: number
}

export interface PptJson {
	slides: Slide[]
	theme: SlideTheme
}
