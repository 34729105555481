import Player, { IPlayerOptions } from 'xgplayer'
import Clip from './plugin/clip'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Play from './plugin/play'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Progress from './plugin/progress'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SPlay from './plugin/s_play'

export type VideoPlayer = Player & {
  config: any
  poster: string
  __start__: (...args: any[]) => void;
  _replay: () => void
  clipStart: number
  clipEnd: number
  videoConfig: any
  isProgressMoving: any
  dash: any
  video: HTMLVideoElement
  isSeeking: boolean
  seeking: boolean
}
Player.install('s_clip_progress', Progress)
Player.install('clip', Clip)
Player.install('clip_play', Play)
Player.install('s_clip_play', SPlay)


export default (
  el: HTMLDivElement,
  options: IPlayerOptions & {
    clip?: boolean,
    clipStart?: number,
    getClipStart?: () => number | undefined,
    clipEnd?: number
    getClipEnd?: () => number | undefined,
    [key: string]: any
  } = {
    url: ''
  }
) => {
  if (!el || !options.url) {
    console.warn('xgplayer: el or url is not defined')
    return
  }
  return new Player({
    el,
    lang: 'zh-cn',
    // maxBufferLength: 5, // 设置最大缓冲区时长，默认5秒,
    // tickInSeconds: 0.2, // 设置请求间隔，默认0.2秒
    fluid: true,
    ignores: ['i18n', 'volume', 'progress', 'play'], // 忽略了进度条，使用自定义
    keyShortcut: 'off',
    pip: true,
    closeInactive: true, // 控制台常驻
    clip: false,
    ...options
  }) as VideoPlayer
}
