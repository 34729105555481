
import {
	computed,
	defineComponent,
	inject,
	PropType,
	provide,
	Ref,
	ref,
	watch
} from 'vue'
import { useStore } from '@/store'
import {
	ElementTypes,
	PPTElement,
	PPTAnimation,
	TriggeredAnimation,
	Slide
} from '@/types/slides'
import useLink from '@/hooks/useLink'
import useTurnPageSlide from '../EvScreen/hooks/useTurnPageSlide'

import BaseImageElement from '@/views/components/element/ImageElement/BaseImageElement.vue'
import BaseTextElement from '@/views/components/element/TextElement/BaseTextElement.vue'
import BaseShapeElement from '@/views/components/element/ShapeElement/BaseShapeElement.vue'
import BaseLineElement from '@/views/components/element/LineElement/BaseLineElement.vue'
import ScreenChartElement from '@/views/components/element/ChartElement/ScreenChartElement.vue'
import BaseTableElement from '@/views/components/element/TableElement/BaseTableElement.vue'
import BaseElfElement from '@/views/components/element/ElfElement/BaseElfElement.vue'
import BaseLinkButtonElement from '@/views/components/element/LinkButtonElement/BaseLinkButtonElement.vue'
import ElementLinkIcon from '@/views/components/element/ElementLinkIcon.vue'

export default defineComponent({
	name: 'screen-element',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElement>,
			required: true
		},
		elementIndex: {
			type: Number,
			required: true
		}
	},
	components: { ElementLinkIcon },
	setup(props) {
		const store = useStore()
		const { openWebLink } = useLink()

		const { turnSlideToIndex, runAnimation } = useTurnPageSlide()



		const triggeredAnimations: Ref<TriggeredAnimation> | undefined = inject(
			'triggeredAnimations'
		)
		const currentSlide = computed<Slide>(() => store.getters.currentSlide)

		const needWaitAnimation = ref(false)
		const currentElementAnimation = ref<PPTAnimation[]>([])
		const firstAnimation = ref<PPTAnimation>()

		const currentElementComponent = computed(() => {
			const elementTypeMap = {
				[ElementTypes.IMAGE]: BaseImageElement,
				[ElementTypes.TEXT]: BaseTextElement,
				[ElementTypes.SHAPE]: BaseShapeElement,
				[ElementTypes.LINE]: BaseLineElement,
				[ElementTypes.CHART]: ScreenChartElement,
				[ElementTypes.TABLE]: BaseTableElement,
				[ElementTypes.ELF]: BaseElfElement,
				[ElementTypes.LINKBUTTON]: BaseLinkButtonElement
			}
			return elementTypeMap[props.elementInfo.type] || null
		})

		const theme = computed(() => store.state.theme)
		const linkTip = computed(() => {
			if (props.elementInfo.link) {
				if (props.elementInfo.link.type === 'web-url') {
					return props.elementInfo.link.target
				}
				const linkId = props.elementInfo.link.target?.split('-')?.[0]
				return `第${+linkId + 1}页幻灯片页面 `
			}
			return ''
		})
		// 元素动画触发情况映射
		const elementAnimationIndexTriggerSourceMap = ref<{
			[key: string]: {
				index: number
				animationIndex: number
			}
		}>({})

		// 检查元素是否需要隐藏
		const checkNeedWaitAnimation = () => {
			if (!triggeredAnimations?.value) {
				return
			}
			// 重新获取元素动画映射情况
			for (const key in triggeredAnimations.value) {
				const formatedAnimations =
					triggeredAnimations.value[key].formatedAnimation
				const animationIndex = triggeredAnimations.value[key].animationIndex

				for (let i = 0; i < formatedAnimations.length; i++) {
					const isExit = formatedAnimations[i].animations.find(
						(animation) => animation.elId === props.elementInfo.id
					)
					if (isExit) {
						elementAnimationIndexTriggerSourceMap.value[key] = {
							index: i,
							animationIndex
						}
						break
					}
				}
			}

			/**
			 * 判断元素是否在动画前需要隐藏
			 * 若元素第一个动画是入场动画：
			 * 1、初始显示时，，需要隐藏
			 * 2、返回上一步，若撤销回到第一个动画时，需要隐藏
			 */
			if (
				firstAnimation.value?.triggerSource &&
				triggeredAnimations.value[firstAnimation.value.triggerSource].isReset
			) {
				needWaitAnimation.value = false
				return
			}
			const firstTriggerSource =
				firstAnimation.value?.triggerSource || 'next_page'

			let _needWaitAnimation =
				elementAnimationIndexTriggerSourceMap.value[firstTriggerSource].index <
				elementAnimationIndexTriggerSourceMap.value[firstTriggerSource]
					.animationIndex
					? false
					: true
			Object.keys(elementAnimationIndexTriggerSourceMap.value).forEach(
				(key: string) => {
					const { index, animationIndex } =
						elementAnimationIndexTriggerSourceMap.value[key] || {}
					if (animationIndex > index) {
						_needWaitAnimation = false
					}
				}
			)
			needWaitAnimation.value = _needWaitAnimation
		}
		/** 监听动画播放情况 */
		watch(
			() => triggeredAnimations,
			() => {
				if (!triggeredAnimations?.value) {
					return
				}
				const isExit = currentSlide.value.elements?.find(
					(el) => el.id === props.elementInfo.id
				)
				if (!isExit) {
					return
				}
				if (!firstAnimation.value || firstAnimation.value?.group !== 'in') {
					return
				}
				checkNeedWaitAnimation()
			},
			{ deep: true }
		)

		const handleClickScreenElement = (e: Event) => {
			// e.stopPropagation()
			if (props.elementInfo.link) {
				e.stopPropagation()

				if (props.elementInfo.link.type === 'slide') {
					const linkId = props.elementInfo.link.target?.split('-')?.[0]
					// store.commit(MutationTypes.UPDATE_SLIDE_INDEX, +linkId)
					turnSlideToIndex(+linkId)
				} else if (props.elementInfo.link.type === 'web-url') {
					openWebLink(props.elementInfo.link.target)
				}
			}
			// 点击触发动画
			if (
				currentElementAnimation.value?.length > 0 &&
				triggeredAnimations?.value &&
				triggeredAnimations.value[props.elementInfo.id].animationIndex <
					triggeredAnimations.value[props.elementInfo.id].formatedAnimation
						.length
			) {
				e.stopPropagation()
				runAnimation(props.elementInfo.id)

				// 播放完最后触发源动画后，索引重置
				if (
					triggeredAnimations.value[props.elementInfo.id].animationIndex ===
					triggeredAnimations.value[props.elementInfo.id].formatedAnimation
						.length
				) {
					triggeredAnimations.value[props.elementInfo.id].isReset = true
					setTimeout(() => {
						triggeredAnimations.value[props.elementInfo.id].animationIndex = 0
					}, 100)
				}
			}
		}
		provide("handleClickScreenElement", handleClickScreenElement)

		const component = ref()
		const doAction = () => {
			component.value?.doAction?.call()
		}

		const initScreenElementAnimation = () => {
			const currentSlide = store.state.slides[store.state.slideIndex]
			if (!currentSlide?.animations) {
				return
			}

			const els = currentSlide.elements
			const elIds = els.map((el) => el.id)
			const animations = currentSlide.animations.filter((animation) =>
				elIds.includes(animation.elId)
			)
			if (animations?.length === 0) {
				return
			}
			firstAnimation.value = animations.find(
				(animation) => animation?.elId === props.elementInfo.id
			)
			currentElementAnimation.value = animations.filter(
				(animation) => animation?.triggerSource === props.elementInfo.id
			)
			if (firstAnimation.value?.group === 'in') {
				needWaitAnimation.value = true
			}
		}

		const updateNeedWaitAnimation = () => {
			if (firstAnimation.value) {
				needWaitAnimation.value = firstAnimation.value?.group === 'in'
			}
		}
		const elementAnimationLineData = computed<any>(() => {
			const animations = currentSlide.value?.animations || []

			const animation = animations.find(
				(animation) =>
					animation.elId === props.elementInfo.id &&
					animation?.type === 'custom'
			)
			if (!animation) {
				return {}
			}
			const { points, width, height } = animation?.path || {}
			let _path = ''

			const pointX: number[] = []
			const pointY: number[] = []
			points.forEach((point: any, index: number) => {
				if (index === 0) {
					pointX.push(0)
					pointY.push(0)
				} else {
					pointX.push(point[0])
					pointY.push(point[1])
				}
			})
			const offsetLeft =
				(props.elementInfo?.width || 0) / 2 +
				Math.min(...pointX) +
				props.elementInfo.left
			const offsetTop =
				((props.elementInfo as any)?.height || 0) / 2 +
				Math.min(...pointY) +
				props.elementInfo.top
			const offsetX = Math.min(...pointX)
			const offsetY = Math.min(...pointY)
			pointX.forEach((_x, index) => {
				if (index === 0) {
					_path += `M${0 - offsetX}, ${0 - offsetY} `
				} else {
					_path += `L${0 + _x - offsetX},${0 + pointY[index] - offsetY} `
				}
			})
			return {
				...animation,
				path: _path,
				svgWidth: width || 0,
				svgHeight: height || 0,
				offsetLeft,
				offsetTop,
				offsetX: Math.min(...pointX),
				offsetY: Math.min(...pointY)
			}
		})
		watch(
			() => [store.state.slideIndex, store.state.screenType],
			() => {
				if (store.state.screenType === 'preview') {
					return
				}
				const isExit = currentSlide.value?.elements?.find(
					(el) => el.id === props.elementInfo.id
				)
				if (isExit) {
					initScreenElementAnimation()
				}
			},
			{ immediate: true }
		)

		return {
			component,
			currentElementComponent,
			needWaitAnimation,
			theme,
			handleClickScreenElement,
			doAction,
			linkTip,
			updateNeedWaitAnimation,
			elementAnimationLineData
		}
	}
})
