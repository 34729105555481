export const millisecondsFormat = (milliseconds: number) => {
  let seconds: string | number = parseInt(String((milliseconds / 1000) % 60)),
    minutes: string | number = parseInt(String((milliseconds / (1000 * 60)) % 60)),
    hours: string | number = parseInt(String((milliseconds / (1000 * 60 * 60)) % 24));

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}
