export const SYS_FONTS = [
    // 暂时屏蔽，
    { label: 'Sans-serif', value: 'Sans-serif', accept: 'win mac' },
    { label: 'Serif', value: 'Serif', accept: 'win mac' },
    { label: '仿宋', value: 'FangSong', accept: 'win' },
    { label: '宋体', value: 'SimSun', accept: 'win' },
    { label: '宋体', value: 'Songti SC', accept: 'mac' },
    { label: '微软雅黑', value: 'Microsoft Yahei', accept: 'win' },
    { label: '微软雅黑 Light', value: 'Microsoft Yahei Light', accept: 'win' },
    { label: '新宋体', value: 'NSimSun', accept: 'win' },
    { label: '楷体', value: 'KaiTi', accept: 'win' },

    { label: '黑体', value: 'SimHei', accept: 'win' },
    { label: 'Arial', value: 'Arial', accept: 'win mac' },
    { label: 'Arial Black', value: 'Arial Black', accept: 'win mac' },
    { label: 'Times New Roman', value: 'Times New Roman', accept: 'win mac' },
    { label: 'Courier New', value: 'Courier New', accept: 'win mac' },
    { label: 'Tahoma', value: 'Tahoma', accept: 'win mac' },
    { label: 'Verdana', value: 'Verdana', accept: 'win mac' }
    // isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    // { label: '苹方', value: 'PingFang SC' },
    // { label: '华文黑体', value: 'STHeiti' },
    // { label: '华文楷体', value: 'STKaiti' },
    // { label: '华文宋体', value: 'STSong' },
    // { label: '华文仿宋', value: 'STFangSong' },
    // { label: '华文中宋', value: 'STZhongSong' },
    // { label: '华文琥珀', value: 'STHupo' },
    // { label: '华文新魏', value: 'STXinwei' },
    // { label: '华文隶书', value: 'STLiti' },
    // { label: '华文行楷', value: 'STXingkai' },
    // { label: '冬青黑体', value: 'Hiragino Sans GB' },
    // { label: '兰亭黑', value: 'Lantinghei SC' },
    // { label: '偏偏体', value: 'Hanzipen SC' },
    // { label: '手札体', value: 'Hannotate SC' },

    // { label: '娃娃体', value: 'Wawati SC' },
    // { label: '行楷', value: 'Xingkai SC' },
    // { label: '圆体', value: 'Yuanti SC' },
    // { label: '华文细黑', value: 'STXihei' },
    // { label: '幼圆', value: 'YouYuan' },
    // { label: '隶书', value: 'LiSu' },
]

export const WEB_FONTS = [
    // 暂时屏蔽，因为dom-to-image会重新fetch这些字体，导致生成缩略图很慢
    // { label: '仓耳小丸子', value: '仓耳小丸子' },
    // { label: '优设标题黑', value: '优设标题黑' },
    // { label: '峰广明锐体', value: '峰广明锐体' },
    // { label: '摄图摩登小方体', value: '摄图摩登小方体' },
    // { label: '站酷快乐体', value: '站酷快乐体' },
    // { label: '字制区喜脉体', value: '字制区喜脉体' },
    // { label: '素材集市康康体', value: '素材集市康康体' },
    // { label: '联盟起艺卢帅正锐黑体', value: '联盟起艺卢帅正锐黑体' },
    // { label: '素材集市酷方体', value: '素材集市酷方体' },
    // { label: '途牛类圆体', value: '途牛类圆体' },
    // { label: '锐字真言体', value: '锐字真言体' },
    // { label: '阿里汉仪智能黑体', value: '阿里汉仪智能黑体' },
]

export const FONT_SIZE_OPTIONS = [
    16,
    18,
    20,
    24,
    28,
    30,
    32,
    36,
    40,
    44,
    48,
    54,
    60,
    66,
    72,
    76,
    80,
    88,
    96,
    104,
    108,
    112,
    120,
    128
]
