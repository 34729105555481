import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d6fab8f0")
const _hoisted_1 = ["onClick"]
_popScopeId()

import { withDefaults, CSSProperties } from 'vue'

interface TabItem {
    key: string
    label: string
    color?: string
}


export default _defineComponent({
  props: {
    value: { type: String, required: true },
    tabs: { type: Array, required: true },
    card: { type: Boolean, required: false, default: false },
    tabsStyle: { type: null, required: false },
    tabStyle: { type: null, required: false },
    spaceAround: { type: Boolean, required: false, default: false },
    spaceBetween: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["update:value"] as unknown as undefined,
  setup(__props: {
        value: string
        tabs: TabItem[]
        card?: boolean
        tabsStyle?: CSSProperties
        tabStyle?: CSSProperties
        spaceAround?: boolean
        spaceBetween?: boolean
    }, { emit }: { emit: ({
    (event: 'update:value', payload: string): void
}), expose: any, slots: any, attrs: any }) {





return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tabs", {
            card: __props.card,
            'space-around': __props.spaceAround,
            'space-between': __props.spaceBetween
        }]),
    style: _normalizeStyle(__props.tabsStyle || {})
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tabs, (tab) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["tab", { active: tab.key === __props.value }]),
        key: tab.key,
        style: _normalizeStyle({
                ...(__props.tabStyle || {}),
                '--color': tab.color
            }),
        onClick: ($event: any) => (emit('update:value', tab.key))
      }, _toDisplayString(tab.label), 15, _hoisted_1))
    }), 128))
  ], 6))
}
}

})