
import _ from 'lodash'
import { computed, defineComponent, onMounted, onUnmounted } from 'vue'
import { MutationTypes, ActionTypes, useStore } from '@/store'

import Editor from './Editor/index.vue'
import EvScreen from './EvScreen/index.vue'
import Footer from './Footer/index.vue'
import useQianKun from '@/hooks/useQianKun'
import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import useLoadPpt from '@/hooks/useLoadPpt'
import { PptState } from '@/types/pptState'
import { removeFullScreenChange, isFullscreen } from '@/utils/fullscreen'
import useScreening from '@/hooks/useScreening'
import useExport from '@/hooks/useExport'
import useRunTime from '@/hooks/useRunTime'
import useAndroid from '@/hooks/useAndroid'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useTeachingTabPage from '@/hooks/useTeachingTabPage'
import logger from '@evideo/logger'
import useUpLoadResource from '@/hooks/useUpLoadResource'

export default defineComponent({
  name: 'home',
  components: {
    Editor,
    EvScreen,
    Footer
  },
  setup() {
    const store = useStore()
    const { runtime } = useRunTime()
    const { registerAndroidEvent } = useAndroid()
    const {
      sendToHost,
      receiveHostMessage,
      isPowerByTabPage,
      removeAllReceiveHostMessage
    } = useTeachingTabPage()

    const { isMicroApp } = useQianKun()
    const {
      registerPptIsModified,
      registerGetPptStatus
    } = useQianKunEventCenter()
    const { exitScreening } = useScreening()
    const { createOssResource } = useUpLoadResource()

    const screening: any = computed(() => store.state.screening)
    const pptState = computed(() => store.state.pptState)
    if (process.env.NODE_ENV === 'production') {
      window.onbeforeunload = () => false
    }
    const { loadPpt, outsideLoadPpt, outsideLoadKnowledgePoints } = useLoadPpt()
    const { outsideSaveToCloud, saveToCloud } = useExport()
    if (isMicroApp) {
      // 作为子应用，注册外部加载ppt，加载知识点，保存ppt
      outsideLoadPpt()
      outsideLoadKnowledgePoints()
      outsideSaveToCloud()
    }
    registerPptIsModified((res: any) => {
      res.event.reply({
        isModified: pptState.value === PptState.MODIFY
      })
    })
    registerGetPptStatus((res: any) => {
      const state = _.pick(store.state, [
        'pptState',
        'slideIndex',
        'screenType',
        'pptUrl',
        'editingScreening'
      ])
      res.event.reply({
        url: state.pptUrl,
        runtime: _.merge({}, runtime, {
          state
        })
      })
    })

    // 暂时代码，钢琴集体课联调测试
    // const test = () => {
    //   // debugger
    //   loadPpt('/钢琴集体课联调.json')
    //   store.commit(MutationTypes.SET_SCREENING,
    //     {
    //       screening: true,
    //       screenType: 'preview'
    //     })
    // }

    const fullscreenChange = () => {
      if (!isFullscreen() && screening.value) {
        exitScreening()
      }
    }

    // 进入ppt则为非全屏编辑状态
    store.commit(
      MutationTypes.SET_EDITING_FULL_SCREENING,
      runtime?.editPptRule?.mode === 'onlyPpt' ||
        runtime?.editPptRule?.mode === 'multipleTabs'
        ? true
        : false
    )
    // 接收上层键盘事件，手动触发ppt项目的键盘事件分发
    const onKeydownListener = (e: any, key: string) => {
      const event = new KeyboardEvent('keydown', {
        key
      })
      document.dispatchEvent(event)
    }

    // 监听多标签保存律动编辑事件
    const onReceiveSave = async () => {
      try {
        logger.info('监听到来自多标签项目保存Ppt事件')
        await saveToCloud()
        sendToHost('receive-save-result', true)
      } catch (err) {
        sendToHost('receive-save-result', false)
        logger.error('监听到来自多标签项目保存Ppt事件，保存Ppt失败')
      }
    }
    // 监听多标签检查律动是否有未保存事件
    const onCheckShowConfirm = () => {
      logger.info(
        '监听到来自多标签项目检查ppt是否有修改事件:',
        pptState.value === PptState.MODIFY
      )
      sendToHost(
        'receive-show-close-confirm-result',
        pptState.value === PptState.MODIFY
      )
    }

    onMounted(() => {
      createOssResource()
      store.commit(MutationTypes.SET_AVAILABLE_FONTS)
      store.dispatch(ActionTypes.INIT_SNAPSHOT_DATABASE)
      // 注册安卓事件onWebviewHide
      registerAndroidEvent('onWebViewHide', () => {
        emitter.emit(EmitterEvents.ON_WEBVIEW_HIDE)
      })
      receiveHostMessage('hide-page', () => {
        emitter.emit(EmitterEvents.ON_WEBVIEW_HIDE)
        // 隐藏标签时，移除上层键盘事件监听
        removeAllReceiveHostMessage('KEYDOWN', onKeydownListener)
      })
      receiveHostMessage('restore-page', () => {
        emitter.emit(EmitterEvents.ON_WEBVIEW_RESTORE)
        // 恢复标签时，再次注册上层键盘事件监听
        receiveHostMessage('KEYDOWN', onKeydownListener)
      })
      receiveHostMessage('show-workbench-footer', (e, result) => {
        emitter.emit(EmitterEvents.ON_WEBVIEW_TASK_BAR_SHOW, result)
      })
      receiveHostMessage('KEYDOWN', onKeydownListener)

      receiveHostMessage('check-show-confirm', onCheckShowConfirm)
      receiveHostMessage('save', onReceiveSave)
      // 根据vuex的pptUrl加载ppt
      isPowerByTabPage && store.state.pptUrl && loadPpt(store.state.pptUrl)

      window.addEventListener('beforeunload', () => {
        store.getters.snapshotDB.clear()
      })
    })
    onUnmounted(() => {
      removeFullScreenChange(fullscreenChange)
    })

    return {
      screening
    }
  }
})
