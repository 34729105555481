import pako from 'pako'

/**
 * gzip 压缩字符串
 */
export const gzip = (gzipText: string) => {
  const str = pako.gzip(escape(gzipText), {
    to: "string"
  })
  const botaStr = window.btoa(str)
  return botaStr;
}

/**
 * gzip 解压缩字符串
 * @param ungzipText 
 */
export const ungzip = (ungzipText: string) => {
  const stratob = window.atob(ungzipText)
  const charData = stratob.split(',') as unknown as number[]
  const data = pako.ungzip(new Uint8Array(charData), {
    to: 'string'
  })
  return unescape(data)
}
