
import _ from 'lodash'
import {
	onMounted,
	ref,
	onUpdated,
	defineComponent,
	watch,
	computed
} from 'vue'
import BScroll from '@better-scroll/core'
import logger from '@evideo/logger'
export default defineComponent({
	props: {
		width: {
			type: Number,
			default: 275
		},
		scrollOption: {
			type: Object,
			default: () => ({})
		}
	},
	setup(props) {
		const scroll = ref<any>()
		const scrollEl = ref<HTMLDivElement>()
		const scrollContentEl = ref<HTMLDivElement>()
		const canScrollWidth = ref(0)

		const initScroll = () => {
			if (!scroll.value) {
				scroll.value = new BScroll(
					scrollEl.value!,
					_.merge(
						{
							bounce: false,
							scrollX: true,
							click: true,
							disableMouse: false,
							observeDOM: true // 修复移动设备无法拖拽
						},
						props.scrollOption || {}
					)
				)
			}
			scroll.value && scroll.value.refresh()
			if (scrollContentEl.value) {
				canScrollWidth.value = scrollContentEl.value.offsetWidth - props.width
			}
			// 若屏幕分辨率小，顶部操作栏需滚动隐藏时，优先展示最左边功能，隐藏右边功能
			scroll.value && scroll.value.scrollBy(scroll.value.minScrollX, 0, 1000)
		}
		watch(() => scrollEl.value, initScroll)

		watch(
			() => props.width,
			(newVal) => {
				scrollEl.value && (scrollEl.value.style.width = `${newVal}px`)
				setTimeout(initScroll, 100)
			}
		)

		const isScroll = computed(() => {
			return (
				scroll.value &&
				(scroll.value.hasHorizontalScroll || scroll.value.hasVerticalScroll)
			)
		})

		const scrollTo = (x: number, y: number, time = 1000) => {
			if (scrollContentEl.value && scroll.value) {
				if (Math.abs(scroll.value.x + x) < canScrollWidth.value) {
					scroll.value.scrollBy(x, y, time)
				} else {
					let offWidth = 0
					if (scroll.value.x >= 0) {
						offWidth = canScrollWidth.value - Math.abs(scroll.value.x)
					} else {
						offWidth = Math.abs(scroll.value.x) - canScrollWidth.value
					}
					scroll.value.scrollBy(offWidth, y, time)
				}
			}
		}

		onMounted(initScroll)
		onUpdated(() => {
			try {
				scroll.value && scroll.value.refresh()
			} catch (e) {
				logger.warn('scroll-container refresh failed: ', e)
			}
		})
		return {
			scroll,
			scrollEl,
			scrollContentEl,
			isScroll,
			scrollTo
		}
	}
})
