import mitt, { Emitter } from 'mitt'

export const enum EmitterEvents {
	RICH_TEXT_COMMAND = 'RICH_TEXT_COMMAND',
	OPEN_CHART_DATA_EDITOR = 'OPEN_CHART_DATA_EDITOR',
	SWITCH_SLIDE_PAGE = 'SWITCH_SLIDE_PAGE',
	PPT_STOP_PLAY = 'PPT_STOP_PLAT',
	ON_WEBVIEW_HIDE = 'ON_WEBVIEW_HIDE',
	ON_WEBVIEW_RESTORE = 'ON_WEBVIEW_RESTORE',
	ON_WEBVIEW_TASK_BAR_SHOW = 'ON_WEBVIEW_TASK_BAR_SHOW',
	ON_LOAD_PPT = 'ON_LOAD_PPT',
	ON_PPT_HIDE = 'ON_PPT_HIDE',
	NEXT_STEP = 'NEXT_STEP',
	GENERATE_CURRENT_PREVIEW = 'GENERATE_CURRENT_PREVIEW'
}

export interface RichTextCommand {
	command: string
	value?: string
}

export interface UpdateSlidePreview {
	slideIndex: number
	slideId?: string
}

type Events = {
	[EmitterEvents.RICH_TEXT_COMMAND]: RichTextCommand | RichTextCommand[]
	[EmitterEvents.OPEN_CHART_DATA_EDITOR]: void
	[EmitterEvents.SWITCH_SLIDE_PAGE]: void
	[EmitterEvents.PPT_STOP_PLAY]: void
	[EmitterEvents.ON_WEBVIEW_HIDE]: void
	[EmitterEvents.ON_WEBVIEW_RESTORE]: void
	[EmitterEvents.ON_WEBVIEW_TASK_BAR_SHOW]: boolean
	[EmitterEvents.ON_LOAD_PPT]: string | undefined
	[EmitterEvents.ON_PPT_HIDE]: void
	[EmitterEvents.NEXT_STEP]: {
		command: string | string[]
		elementId?: string
		slideId?: string
	}
	[EmitterEvents.GENERATE_CURRENT_PREVIEW]: {
		slideId: string
	}
}

const emitter: Emitter<Events> = mitt<Events>()

export default emitter
