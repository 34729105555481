
import { ref } from 'vue'
import locale from 'ant-design-vue/lib/locale-provider/zh_CN'
import useQianKun from './hooks/useQianKun'

export default {
    name: 'app',
    setup() {
        const { document } = useQianKun()
        const appContainer = ref()
        const getPopupContainer = (el: any, dialogContext: any) => {
            if (dialogContext) {
                return dialogContext.getDialogWrap()
            }
            return document.body
        }

        return {
            locale,
            appContainer,
            getPopupContainer
        }
    }
}
