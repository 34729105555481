import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { withDefaults } from 'vue'

export default _defineComponent({
  props: {
    checked: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    type: { type: String, required: false, default: 'default' },
    size: { type: String, required: false, default: 'normal' },
    first: { type: Boolean, required: false, default: false },
    last: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["click"] as unknown as undefined,
  setup(__props: {
        checked?: boolean
        disabled?: boolean
        type?: 'default' | 'primary' | 'checkbox' | 'radio'
        size?: 'small' | 'normal'
        first?: boolean
        last?: boolean
    }, { emit }: { emit: ({
    (event: 'click'): void
}), expose: any, slots: any, attrs: any }) {

const props = __props




const handleClick = () => {
    if (props.disabled) return
    emit('click')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", {
            disabled: __props.disabled,
            checked: !__props.disabled && __props.checked,
            default: !__props.disabled && __props.type === 'default',
            primary: !__props.disabled && __props.type === 'primary',
            checkbox: !__props.disabled && __props.type === 'checkbox',
            radio: !__props.disabled && __props.type === 'radio',
            small: __props.size === 'small',
            first: __props.first,
            last: __props.last
        }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick()))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})