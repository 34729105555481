import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkButtonDialog = _resolveComponent("LinkButtonDialog")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    class: "check-link",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openLinkDialog && _ctx.openLinkDialog(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_component_Modal, {
      visible: _ctx.linkDialogVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.linkDialogVisible = $event)),
      footer: null,
      centered: "",
      width: 750,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LinkButtonDialog, {
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.linkDialogVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}