import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-67193940")
const _hoisted_1 = { class: "checkBoxDiv" }
const _hoisted_2 = ["onClick"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["item", { itemActive: item.checked }]),
        style: _normalizeStyle(item.checked && { backgroundColor: '#5B9BD5' }),
        key: item.value,
        onClick: ($event: any) => (_ctx.handleClick(item))
      }, _toDisplayString(item.label), 15, _hoisted_2))
    }), 128))
  ]))
}