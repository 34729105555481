import Dexie from 'dexie'
import { Slide, SlideTheme } from '@/types/slides'
import dayjs from 'dayjs'
import logger from '@evideo/logger'

export interface ModifyRecord {
	uuid: string
	time: string
	slides: Slide[]
	theme: SlideTheme
}

export default class ModifyRecordsDatabase extends Dexie {
	private _uuid = 'modifyRecords'
	private _modifyRecords: Dexie.Table<ModifyRecord, number>

	public constructor(uuid: string) {
		super('ModifyRecordsDatabase')
		this._uuid = uuid
		this.version(1).stores({
			modifyRecords: '++id'
		})
		this._modifyRecords = this.table('modifyRecords')
	}
	public get uuid(): string {
		return this._uuid
	}
	public get modifyRecords() {
		return this._modifyRecords
	}
	public setModifyRecordsUuid(uuid: string) {
		this._uuid = uuid
	}
	public async clear() {
		const keys = await this.modifyRecords
			.filter((modifyRecord) => modifyRecord.uuid === this._uuid)
			.keys()
		this.modifyRecords.bulkDelete(keys)
	}
	public async clearByUuid(uuid: string) {
		const keys = await this.modifyRecords
			.filter((modifyRecord) => modifyRecord.uuid === uuid)
			.keys()
		await this.modifyRecords.bulkDelete(keys)
	}
	public async clearByTime(uuid: string, lastTime: string) {
		logger.info(`modifyRecords clearByTime lastTime: ${lastTime}`)
		const keys = await this.modifyRecords
			.filter(
				(modifyRecord) =>
					modifyRecord.uuid === uuid &&
					dayjs(lastTime).isAfter(modifyRecord.time)
			)
			.keys()
		await this.modifyRecords.bulkDelete(keys)
	}
}
