import changeImage from './image'
import changeAudio from './audio'
import changeVideo from './video'

// 主要为过滤视频的第一帧图片元素
const elementCheck = (mediaElementsObject: any) => {
	let copyElementObject: any[] = []
	if (Array.isArray(mediaElementsObject)) {
		mediaElementsObject.forEach((item) => {
			// 本地视频资源不解析，过滤第一帧图片的解析
			if (item?.id && noSupportVideoIds.indexOf(item.id) === -1) {
				copyElementObject.push(item)
			}
		})

		const videoElementIds: string[] = []
		copyElementObject.forEach((item) => {
			if (item?.element?.subtype === 'elf-video') {
				videoElementIds.push(item.id)
			}
		})
		// 非本地视频资源时，解析视频封面

		if (videoElementIds.length > 0) {
			videoElementIds.forEach((id) => {
				let imageEl: any
				copyElementObject.forEach((el) => {
					if (el.id === id && el?.element?.type === 'image') {
						imageEl = el
					}
				})
				if (imageEl) {
					copyElementObject.forEach((el) => {
						if (el.id === id && el?.element?.subtype === 'elf-video') {
							el.element.poster = imageEl.element.src
						}
					})
				}
			})
		}
		copyElementObject = copyElementObject.filter(
			(item) =>
				item?.element?.subtype === 'elf-video' ||
				videoElementIds.indexOf(item.id) === -1
		)
	}
	return copyElementObject
}

const noSupportVideoIds: string[] = []

export default async (
	slideIndex: number,
	pic: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFiles: AnimationPlaybackEvent,
	upload: any
) => {
	const nvPr = pic.nvPicPr.nvPr || {}
	const media = Object.entries(nvPr)
	const mediaElements = []
	let mediaElementsObject = []

	if (
		media.length === 0 ||
		(media.length === 1 && media[0][0] === 'attributes')
	) {
		const element = await changeImage(
			slideIndex,
			pic,
			slideRelsObject,
			slideMaster,
			pptxFiles,
			upload
		)
		if (!element) {
			throw new Error(`第${slideIndex}页PPT 图片解析失败，请重试`)
		}
		if (element === 'noSupportImageType') {
			return
		}
		mediaElements.push(element)
		mediaElementsObject.push({
			id: pic.nvPicPr.cNvPr.attributes.id,
			element: element
		})
	}

	for (let i = 0; i < media.length; i++) {
		const [key, val] = media[i]
		if (key === 'audioFile') {
			const element = await changeAudio(
				slideIndex,
				val,
				pic,
				slideRelsObject,
				pptxFiles,
				upload
			)
			if (!element) {
				throw new Error(`第${slideIndex}页PPT 音频解析失败，请重试`)
			}
			if (element !== 'external') {
				mediaElements.push(element)
				mediaElementsObject.push({
					id: pic.nvPicPr.cNvPr.attributes.id,
					element: element
				})
			}
		} else if (key === 'videoFile') {
			const element = await changeVideo(
				slideIndex,
				val,
				pic,
				slideRelsObject,
				pptxFiles,
				upload
			)
			// 不支持的视频类型不解析
			if (element && element === 'noSupportVideoType') {
				noSupportVideoIds.push(pic.nvPicPr.cNvPr.attributes.id)
				return
			}
			if (!element) {
				throw new Error(`第${slideIndex}页PPT 视频解析失败，请重试`)
			}

			if (element !== 'external') {
				mediaElements.push(element)
				mediaElementsObject.push({
					id: pic.nvPicPr.cNvPr.attributes.id,
					element: element
				})
			} else {
				noSupportVideoIds.push(pic.nvPicPr.cNvPr.attributes.id)
			}
		} else if (key === 'ph') {
			// 使用母版时，图片类型为ph
			const element = await changeImage(
				slideIndex,
				pic,
				slideRelsObject,
				slideMaster,
				pptxFiles,
				upload
			)
			if (!element) {
				throw new Error(`第${slideIndex}页PPT 图片解析失败，请重试`)
			}
			if (element === 'noSupportImageType') {
				return
			}
			mediaElements.push(element)
			mediaElementsObject.push({
				id: pic.nvPicPr.cNvPr.attributes.id,
				element: element
			})
		} else if (key === 'custDataLst') {
			const element = await changeImage(
				slideIndex,
				pic,
				slideRelsObject,
				slideMaster,
				pptxFiles,
				upload
			)
			if (!element) {
				throw new Error(`第${slideIndex}页PPT 图片解析失败，请重试`)
			}
			if (element === 'noSupportImageType') {
				return
			}
			mediaElements.push(element)
			mediaElementsObject.push({
				id: pic.nvPicPr.cNvPr.attributes.id,
				element: element
			})
		}
	}
	mediaElementsObject = elementCheck(mediaElementsObject)
	return mediaElementsObject
}
