
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  createVNode,
  onUnmounted,
} from "vue";
import { MutationTypes, useStore } from "@/store";
import useResourceCenter from "@/hooks/useResourceCenter";
import useRunTime from "@/hooks/useRunTime";
import useDynamicTabs from "./hooks/useDynamicTabs";
import { Modal } from "ant-design-vue";
import {
  ExclamationCircleOutlined,
  FileTextTwoTone,
} from "@ant-design/icons-vue";
import { PptState } from "@/types/pptState";
import emitter, { EmitterEvents } from "@/utils/emitter";
export default defineComponent({
  name: "lessonPlan-panel",
  components: {
    FileTextTwoTone,
  },
  props: {
    onMountCloseSecondToolBar: {
      type: Function,
    },
  },
  setup(props) {
    const store = useStore();
    const screening = computed(() => store.state.screening);
    const isDetail = ref(false);
    // 教案列表
    const planList: any = ref([]);
    // 教案详情
    const planDetails = ref<string>();

    const { runtime, configRunTime } = useRunTime();
    const { getCoursewareDetail } = useResourceCenter();
    const {
      openLessonOrSpeakModal,
      cancelLessonOrSpeakRelate,
      getNewPptDetails,
    } = useDynamicTabs();

    const pptId = computed<number | undefined>(
      () => runtime.extData?.coursewareId
    );
    const pptSource = computed(() => runtime.extData?.coursewareSource);
    const relate_courseware_ids: any = ref([]);
    const Allcoursewares = ref<any>();

    // 获取教案列表
    const getPlanList: any = async (
      pptId: number,
      pptSource: string | undefined
    ) => {
      // 打开教案的弹窗
      const res =
        pptId !== 0
          ? await getCoursewareDetail(pptId, pptSource)
          : await getNewPptDetails("lesson").then((data) => {
              return data;
            });
      Allcoursewares.value = res.data.relate_coursewares;
      planList.value = Allcoursewares.value.filter(
        (item: any) => item.type === "plan"
      );
      // 起始时，若无数据，收起二级面板
      props.onMountCloseSecondToolBar &&
        props.onMountCloseSecondToolBar(planList.value.length > 0);
    };

    // 获取教案详情
    const getPlanContent = async (
      lessonId: number,
      pptSource: string | undefined
    ) => {
      isDetail.value = true;
      planDetails.value = "";
      const coursewareDetails = await getCoursewareDetail(lessonId, pptSource);

      planDetails.value = coursewareDetails.data.plan_content;
      console.log(planDetails.value);
    };

    // 关联教案
    const relatePlan = () => {
      openLessonOrSpeakModal("lesson", runtime.extData).then((data: any) => {
        if (data?.coursewareId !== 0 && data?.coursewareId) {
          if (data?.status_code === 0) {
            runtime.extData = {
              ...(runtime.extData || {}),
              coursewareId: data?.coursewareId,
              coursewareSource: data?.coursewareSource,
            };
            configRunTime(runtime);
            getPlanList(pptId.value, pptSource.value);
          }
        } else {
          getPlanList(pptId.value, pptSource.value);
        }
        store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY);
      });
    };

    // 取消关联教案
    const cancelPlanRelate = (lessonId: number) => {
      Modal.confirm({
        onOk() {
          relate_courseware_ids.value = [];
          relate_courseware_ids.value = Allcoursewares.value.reduce(
            (pre: any, current: any) => {
              if (current.id !== lessonId) {
                pre.push(current.id);
              }
              return pre;
            },
            []
          );
          cancelLessonOrSpeakRelate(
            "lesson",
            relate_courseware_ids.value,
            runtime.extData
          ).then((data: any) => {
            if (data?.coursewareId !== 0 && data?.coursewareId) {
              if (data?.status_code === 0) {
                getPlanList(pptId.value, pptSource.value);
              }
            } else {
              getPlanList(pptId.value, pptSource.value);
            }
            store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY);
          });
        },
        cancelText: "取消",
        onCancel() {
          Modal.destroyAll();
        },
        mask: true,
        maskClosable: true,
        okText: "确认",
        icon: createVNode(ExclamationCircleOutlined),
        title: "提示",
        content: "是否确认取消关联该教案？",
      });
    };

    // 返回
    const backList = () => {
      isDetail.value = false;
    };

    const updateList = () => {
      planList.value = [];
      backList();
      getPlanList(pptId.value, pptSource.value);
    };

    onMounted(() => {
      emitter.on(EmitterEvents.ON_LOAD_PPT, updateList);
      getPlanList(pptId.value, pptSource.value);
    });

    onUnmounted(() => {
      emitter.off(EmitterEvents.ON_LOAD_PPT, updateList);
    });

    return {
      getPlanContent,
      backList,
      relatePlan,
      cancelPlanRelate,
      isDetail,
      planList,
      planDetails,
      screening,
      pptSource,
    };
  },
});
