
import {
	computed,
	PropType,
	defineComponent,
	ref,
	onMounted,
	onUnmounted,
	Ref,
	watch,
	provide
} from 'vue'
import { useStore } from '@/store'
import {
	FormatedAnimation,
	PPTAnimation,
	Slide,
	TriggeredAnimation
} from '@/types/slides'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'
import { findElfByType } from '@/views/components/element/ElfElement/register'

import ScreenElement from './ScreenElement.vue'
import { KEYS } from '@/configs/hotkey'

import useTurnPageSlide from '../EvScreen/hooks/useTurnPageSlide'
import { v4 as uuidv4 } from 'uuid'
import { ANIMATION_NEXT_STEP_EVENTS } from '@/configs/animation'

// import useAndroid from "@/hooks/useAndroid";

export default defineComponent({
	name: 'screen-slide',
	components: {
		ScreenElement
	},
	props: {
		slide: {
			type: Object as PropType<Slide>,
			required: true
		},
		scale: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const store = useStore()

		const viewportRatio = computed(() => store.state.viewportRatio)
		const currentSlide = computed<Slide>(() => store.getters.currentSlide)

		const background = computed(() => props.slide.background)
		const { backgroundStyle } = useSlideBackgroundStyle(background)
		const { updateTriggeredAnimations } = useTurnPageSlide()

		// ref所有的element，方便处理
		const elemRefs: { [key: string]: Ref<HTMLElement | undefined> } = {}
		props.slide.elements.forEach((elem) => {
			elemRefs[elem.id] = ref<HTMLElement>()
		})

		const triggeredAnimations = ref()

		provide('triggeredAnimations', triggeredAnimations)

		// 焦点处理
		const focusSortedElems = props.slide.elements
			.filter((elem) => {
				if (elem.type === 'elf') {
					const config = findElfByType(elem.subtype)
					return config?.config?.focusOrder !== undefined
				}
			})
			.sort((a, b) => a.focusOrder! - b.focusOrder!)

		let curFocusElemIndex = -1
		if (focusSortedElems.length > 0) {
			const moveFocusToNext = () => {
				++curFocusElemIndex
				if (curFocusElemIndex >= focusSortedElems.length) {
					curFocusElemIndex = 0
				}
			}

			onMounted(() => moveFocusToNext())

			// 按键处理
			const keydownListener = (e: KeyboardEvent) => {
				if (props.slide.id !== currentSlide.value.id) {
					return
				}
				const key = e.key.toUpperCase()
				if (key === KEYS.PAGE_DOWN) {
					moveFocusToNext()
				} else if (key === KEYS.SPACE || key === KEYS.TAB) {
					const id = focusSortedElems[curFocusElemIndex].id
					const elemInstance: any = elemRefs[id].value
					elemInstance.doAction()
				}
			}
			// const { registerAndroidKeyDown } = useAndroid()

			onMounted(() => {
				document.addEventListener('keydown', keydownListener)
				// registerAndroidKeyDown({ id: 'screen-page_down', key: KEYS.PAGE_DOWN, func: keydownListener })
				// registerAndroidKeyDown({ id: 'screen-space', key: KEYS.SPACE, func: keydownListener })
				// registerAndroidKeyDown({ id: 'screen-tab', key: KEYS.TAB, func: keydownListener })
			})
			onUnmounted(() => {
				document.removeEventListener('keydown', keydownListener)
			})
		}

		// 页面元素动画
		const initTriggeredAnimation = () => {
			const triggeredAnimations: TriggeredAnimation = {}
			const currentSlide = store.state.slides[store.state.slideIndex]

			const els = currentSlide.elements
			const elIds = els.map((el) => el.id)
			const animations =
				currentSlide?.animations?.filter((animation: PPTAnimation) =>
					elIds.includes(animation.elId)
				) || []

			for (const animation of animations) {
				// 先清空之前已播动画样式
				const elRef: HTMLElement | null = document.querySelector(
					`#screen-element-${animation.elId} [class^=base-element-]`
				)
				if (elRef) {
					elRef.style.removeProperty('transition')
					elRef.style.removeProperty('transform')
				}

				const formatedAnimations: FormatedAnimation[] = []
				const _formatedAnimation =
					triggeredAnimations[animation?.triggerSource || 'next_page']
				if (_formatedAnimation?.formatedAnimation) {
					formatedAnimations.push(..._formatedAnimation?.formatedAnimation)
				}
				animation.id = animation.id || uuidv4()
				animation.trigger = animation.trigger || 'click'
				animation.group = animation.group || 'in'
				if (animation.trigger === 'click' || !formatedAnimations.length) {
					formatedAnimations.push({
						animations: [animation],
						autoNext: false
					})
				} else if (animation.trigger === 'meantime') {
					const last = formatedAnimations[formatedAnimations.length - 1]
					last.animations = last.animations.filter(
						(item) => item.elId !== animation.elId
					)
					last.animations.push(animation)
					formatedAnimations[formatedAnimations.length - 1] = last
				} else if (animation.trigger === 'auto') {
					const last = formatedAnimations[formatedAnimations.length - 1]
					last.autoNext = true
					formatedAnimations[formatedAnimations.length - 1] = last
					formatedAnimations.push({
						animations: [animation],
						autoNext: false
					})
				}
				triggeredAnimations[animation?.triggerSource || 'next_page'] = {
					formatedAnimation: formatedAnimations,
					isReset: false,
					animationIndex: 0
				}
			}
			// 某些元素下一步播放
			const nextStepElements: PPTAnimation[] = []
			els.forEach((el) => {
				if (el?.nextStepEvents) {
					nextStepElements.push({
						id: uuidv4(),
						elId: el.id,
						type: ANIMATION_NEXT_STEP_EVENTS,
						group: 'other',
						trigger: 'click',
						duration: 0
					})
				}
			})
			if (nextStepElements.length > 0) {
				nextStepElements.forEach((nextStepAnimation) => {
					const _formatedAnimations =
						triggeredAnimations['next_page']?.formatedAnimation
					triggeredAnimations['next_page'] = {
						animationIndex: 0,
						isReset: false,
						formatedAnimation: [
							...(_formatedAnimations || []),
							{ animations: [nextStepAnimation], autoNext: false }
						]
					}
				})
			}
			return triggeredAnimations
		}

		watch(
			() => currentSlide.value,
			() => {
				if (currentSlide.value?.id === props.slide?.id) {
					if (elemRefs) {
						for (const elRefKey in elemRefs) {
							console.log()
							;(elemRefs[elRefKey].value as any)?.updateNeedWaitAnimation &&
								(elemRefs[elRefKey].value as any)?.updateNeedWaitAnimation()
						}
					}
					triggeredAnimations.value = initTriggeredAnimation()
					updateTriggeredAnimations(triggeredAnimations.value)
				}
			},
			{ immediate: true }
		)
		return {
			backgroundStyle,
			VIEWPORT_SIZE,
			viewportRatio,
			elemRefs
		}
	}
})
