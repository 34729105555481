/**
 * 新建 -> new
 * 加载 -> load
 * new 修改 -> modify
 * load 修改 -> modify
 * modify 保存 -> save
 * save 修改 -> modify
 */
export const enum PptState {
    NEW = 'new',
    LOAD = 'load',
    MODIFY = 'modify',
    SAVE = 'save'
}
