
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  name: 'counter',
  props: {
    max: {
      type: Number,
      default: 100
    },
    min: {
      type: Number,
      default: 0
    },
    count: Number
  },
  setup(props, ctx) {
    const countValue = ref(props.count || 0)

    watch(countValue, (newVal) => {
      const value = newVal.toString() // .replace(/\D/g, '')
      if (parseInt(value) > props.max) {
        ctx.emit('update:count', props.max)
        ctx.emit('change', props.max)
        return
      }
      if (parseInt(value) < props.min) {
        ctx.emit('update:count', props.min)
        ctx.emit('change', props.min)
        return
      }
      ctx.emit('update:count', value)
      ctx.emit('change', value)

    })
    return {
      countValue
    }
  },
})
