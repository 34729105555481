import { SlideBackground } from '@/types/slides'
import logger from '@evideo/logger'
import { PptxToJsonOption } from '..'
import { hslToRgb, rgbToHsl, toHexColor, toRGB } from './transformColor'
import useCompress from '@/hooks/useCompress'

const { compressImage } = useCompress()

export let slidePer = 1

export const emusToPx = (emus: any) => {
	const point = emus / 12700
	const px = (point * 4) / 3
	return px
}

export const isMaster = (sp: any) => {
	// return !sp?.spPr?.xfrm
	return !sp?.spPr?.xfrm || sp?.nvSpPr?.nvPr?.ph?.attributes
}

export const xmlToJsOption = {
	compact: true,
	ignoreDeclaration: true,
	attributesKey: 'attributes',
	elementNameFn: (s: string) => {
		return s.replace('p:', '').replace('a:', '')
	}
}

let themeColorMap = {} // 主题颜色映射,可能有多个
let clrMap = {} // 颜色名称映射,可能有多个
let defaultTextSize = ''

export const getColorMap = (
	themeFile: any,
	pptxFiles: any,
	slideMasterFiles: any
) => {
	themeColorMap = {}
	clrMap = {}
	const themeFilesXml: any[] = []
	themeFile.forEach((fileName: string) => {
		themeFilesXml.push(pptxFiles[fileName])
	})
	themeFilesXml.forEach(async (xmlItem, index) => {
		const content = await xmlItem.async('string')
		const themeFileObject = window.xml2js(content, xmlToJsOption)
		const clrScheme = themeFileObject?.theme?.themeElements?.clrScheme
		const colorMap = {}
		for (const key in clrScheme) {
			const value = clrScheme[key]
			if (!themeColorMap[key]) {
				if (value?.srgbClr) {
					colorMap[key] = value?.srgbClr?.attributes?.val
				} else if (value?.sysClr) {
					colorMap[key] = value?.sysClr?.attributes?.lastClr
				}
			}
		}
		themeColorMap[index + 1] = colorMap
	})

	const slideMasterFilesXml: any[] = []
	slideMasterFiles.forEach((fileName: string) => {
		slideMasterFilesXml.push(pptxFiles[fileName])
	})
	slideMasterFilesXml.forEach(async (xmlItem, index) => {
		const content = await xmlItem.async('string')
		const slideMasterObject = window.xml2js(content, xmlToJsOption)
		clrMap[index + 1] = slideMasterObject?.sldMaster?.clrMap?.attributes
	})
}

export const getThemeColor = (
	slideMasterNum: number,
	themeColorKey: string
) => {
	if (themeColorMap[slideMasterNum][themeColorKey]) {
		return `#${themeColorMap[slideMasterNum][themeColorKey]}`
	} else if (clrMap[slideMasterNum][themeColorKey]) {
		const key = clrMap[slideMasterNum][themeColorKey]
		return `#${themeColorMap[slideMasterNum][key]}`
	}
}

// 默认字体大小有可能在Presentation文件中
export const getDefaultTextSize = async (
	pptxFiles: any,
	PresentationPath: any
) => {
	const slidePresentationXml = pptxFiles[PresentationPath]
	const slidePresentationContent = await slidePresentationXml.async('string')
	const slidePresentationObject = window.xml2js(
		slidePresentationContent,
		xmlToJsOption
	)
	defaultTextSize =
		slidePresentationObject.presentation.defaultTextStyle.lvl1pPr.defRPr
			.attributes?.sz
}

// 获取使用母版时的元素位置
const getPositionFromMaster = async (
	sp: any,
	slideRelsObject: any,
	pptxFile: any,
	slideMaster?: any
) => {
	let masterElementType =
		sp?.nvSpPr?.nvPr?.ph?.attributes?.type ||
		sp?.nvSpPr?.nvPr?.ph?.attributes?.idx
	const layoutPathRels = slideRelsObject?.Relationships?.Relationship
	let layoutPath: any
	if (Array.isArray(layoutPathRels)) {
		layoutPathRels.forEach((layoutPathRel) => {
			if (layoutPathRel.attributes.Type.includes('slideLayout')) {
				layoutPath = layoutPathRel.attributes?.Target
			}
		})
	} else {
		layoutPath =
			slideRelsObject?.Relationships?.Relationship?.attributes?.Target
	}
	if (layoutPath) {
		const layoutFile = pptxFile[`ppt${layoutPath.replace('..', '')}`]
		const layoutContent = await layoutFile.async('string')
		const layoutObject = window.xml2js(layoutContent, xmlToJsOption)
		const layoutSp = layoutObject?.sldLayout?.cSld?.spTree?.sp
		let targetSp = layoutSp?.find(
			(item: any) =>
				item?.nvSpPr?.nvPr?.ph?.attributes?.type === masterElementType ||
				item?.nvSpPr?.nvPr?.ph?.attributes?.idx === masterElementType
		)
		if (!targetSp?.spPr?.xfrm) {
			const slideMasterPath = pptxFile[`ppt/slideMasters/${slideMaster}`]
			const slideMasterContent = await slideMasterPath.async('string')
			const slideMasterObject = window.xml2js(slideMasterContent, xmlToJsOption)
			if (masterElementType === '1') {
				masterElementType = 'body'
			}
			slideMasterObject.sldMaster.cSld.spTree.sp?.forEach((_sp: any) => {
				if (_sp.nvSpPr.nvPr.ph.attributes.type === masterElementType) {
					targetSp = _sp
				}
			})
		}
		return targetSp
	}
}

// 获取元素位置
export const getPosition = async (
	sp: any,
	slideRelsObject: any,
	pptxFile: any,
	slideMaster?: any
) => {
	let xfrm = sp?.spPr?.xfrm || sp?.xfrm
	if (!xfrm) {
		const fromMaster = isMaster(sp)
		if (fromMaster) {
			const targetSp = await getPositionFromMaster(
				sp,
				slideRelsObject,
				pptxFile,
				slideMaster
			)
			xfrm = targetSp?.spPr?.xfrm
		}
	}
	const left = emusToPx(xfrm?.off?.attributes?.x || 0)
	const top = emusToPx(xfrm?.off?.attributes?.y || 0)
	const width = emusToPx(xfrm?.ext?.attributes?.cx || 9144000)
	const height = emusToPx(xfrm?.ext?.attributes?.cy || 2387600)
	return {
		left,
		top,
		width,
		height
	}
}

// 组合类型元素位置
export const getGrpSPPosition = (grpSp: any, elementXfrm: any) => {
	const grpSpXfrm = grpSp.xfrm
	elementXfrm.left = (elementXfrm.left * 12700 * 3) / 4
	elementXfrm.top = (elementXfrm.top * 12700 * 3) / 4
	elementXfrm.width = (elementXfrm.width * 12700 * 3) / 4
	elementXfrm.height = (elementXfrm.height * 12700 * 3) / 4
	const left = emusToPx(
		(elementXfrm.left / grpSpXfrm.chExt.attributes.cx) *
			grpSpXfrm.ext.attributes.cx || 0
	)

	const top = emusToPx(
		(elementXfrm.top / grpSpXfrm.chExt.attributes.cy) *
			grpSpXfrm.ext.attributes.cy || 0
	)
	const width = emusToPx(
		(elementXfrm.width / grpSpXfrm.chExt.attributes.cx) *
			grpSpXfrm.ext.attributes.cx || 9144000
	)
	const height = emusToPx(
		(elementXfrm.height / grpSpXfrm.chExt.attributes.cy) *
			grpSpXfrm.ext.attributes.cy || 9144000
	)
	return {
		left,
		top,
		width,
		height
	}
}

const getLumColor = (color: string, lumMod: string, lumOff: string) => {
	let mod
	let off
	if (lumMod) {
		mod = +lumMod / 100000
	}
	if (off) {
		off = +lumOff / 100000
	}
	const rgbColor = toRGB(color)
	const hslColor = rgbToHsl(rgbColor[0], rgbColor[1], rgbColor[2])

	if (mod && hslColor?.[2]) {
		hslColor[2] = hslColor[2] * mod
	}
	if (off && hslColor?.[2]) {
		hslColor[2] = hslColor[2] + off
	}
	const changeRgbColor = hslToRgb(
		hslColor?.[0] || 0,
		hslColor?.[1] || 0,
		hslColor?.[2] || 0
	)
	const changeColor = toHexColor(changeRgbColor)
	return changeColor
}

export const getTextAttributes = (
	underLine: number,
	value: any,
	targetSp: any,
	textStyleDef: any,
	slideMasterNum: number,
	lstStyleObject: any
) => {
	const textStyle: string[] = []
	// 母版样式
	if (targetSp) {
		const txSize =
			targetSp?.txBody?.lstStyle?.lvl1pPr?.defRPr?.attributes?.sz ||
			textStyleDef?.txBody.lstStyle.lvl1pPr.defRPr?.attributes?.sz
		if (txSize && !value.rPr?.attributes?.baseline) {
			let size = ((txSize / 100) * 4) / 3
			if (slidePer !== 1) {
				size = Math.round(size / slidePer)
			}
			textStyle.push(`font-size:${size}px;`)
		} else if (value.rPr?.attributes?.baseline && value?.rPr?.attributes.sz) {
			let size = ((value.rPr.attributes.sz / 100) * 4) / 3
			if (slidePer !== 1) {
				size = Math.round(size / slidePer)
			}
			textStyle.push(`font-size:${size}px;`)
		}
	}

	// 下划线
	if (underLine) {
		textStyle.push('text-decoration: underline;')
	}

	// 字体大小
	// && !value.rPr?.attributes?.baseline
	if (value.rPr?.attributes?.sz) {
		let size = ((value.rPr.attributes.sz / 100) * 4) / 3
		if (slidePer !== 1) {
			size = Math.round(size / slidePer)
		}
		textStyle.push(`font-size:${size}px;`)
	} else if (lstStyleObject && lstStyleObject?.defRPr?.attributes?.sz) {
		let size = ((lstStyleObject.defRPr.attributes.sz / 100) * 4) / 3
		if (slidePer !== 1) {
			size = Math.round(size / slidePer)
		}
		textStyle.push(`font-size:${size}px;`)
	}

	// 字体颜色
	if (value.rPr?.solidFill?.srgbClr?.attributes?.val) {
		textStyle.push(`color:#${value.rPr.solidFill.srgbClr.attributes.val};`)
	} else if (value.rPr?.solidFill?.schemeClr?.attributes?.val) {
		let color = getThemeColor(
			slideMasterNum,
			value.rPr.solidFill.schemeClr.attributes.val
		)
		if (
			color &&
			(value.rPr?.solidFill?.schemeClr?.lumMod ||
				value.rPr?.solidFill?.schemeClr?.lumOff)
		) {
			color = getLumColor(
				color,
				value.rPr?.solidFill?.schemeClr?.lumMod?.attributes.val || 0,
				value.rPr?.solidFill?.schemeClr?.lumOff?.attributes.val || 0
			)
		}

		textStyle.push(`color:${color};`)
	}

	// 文字高亮
	if (value.rPr?.highlight?.srgbClr?.attributes?.val) {
		textStyle.push(
			`background-color: #${value.rPr.highlight.srgbClr.attributes.val};`
		)
	}

	return textStyle.join('')
}

export const getTextAlign = (pPr: any, masterStyle: any, bodyPr: any) => {
	let textAlign = ''
	// 对齐方式
	if (pPr && pPr?.attributes?.algn) {
		if (pPr.attributes.algn === 'ctr') {
			textAlign = 'text-align:center;'
		} else if (pPr.attributes.algn === 'r') {
			textAlign = 'text-align:right;'
		}
	} else if (masterStyle) {
		// 使用母版时
		if (masterStyle?.lvl1pPr?.attributes?.algn) {
			if (masterStyle.lvl1pPr.attributes.algn === 'ctr') {
				textAlign = 'text-align:center;'
			} else if (masterStyle.lvl1pPr.attributes.algn === 'r') {
				textAlign = 'text-align:right;'
			}
		}
	} else if (bodyPr) {
		if (bodyPr.attributes?.anchorCtr) {
			textAlign = 'text-align:center;'
		}
	}
	return textAlign
}

// 颜色亮度调亮
const brightnessColor = (hex: string, off: number) => {
	hex = hex.replace(/^\s*#|\s*$/g, '')
	if (hex.length === 3) {
		hex = hex.replace(/(.)/g, '$1$1')
	}
	const r = parseInt(hex.substr(0, 2), 16),
		g = parseInt(hex.substr(2, 2), 16),
		b = parseInt(hex.substr(4, 2), 16)
	return (
		'' +
		(0 | ((1 << 8) + r + ((256 - r) * off) / 100)).toString(16).substr(1) +
		(0 | ((1 << 8) + g + ((256 - g) * off) / 100)).toString(16).substr(1) +
		(0 | ((1 << 8) + b + ((256 - b) * off) / 100)).toString(16).substr(1)
	)
}
// 颜色亮度调暗
const darkenColor = (col: string, amt: number) => {
	let usePound = false
	if (col[0] === '#') {
		col = col.slice(1)
		usePound = true
	}
	const num = parseInt(col, 16)
	let r = (num >> 16) + amt
	if (r > 255) r = 255
	else if (r < 0) r = 0
	let b = ((num >> 8) & 0x00ff) + amt

	if (b > 255) b = 255
	else if (b < 0) b = 0
	let g = (num & 0x0000ff) + amt
	if (g > 255) g = 255
	else if (g < 0) g = 0
	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

// 获取背景颜色
export const getBackGroundColor = (
	slideMasterNum: number,
	type: any,
	lumMod: string,
	lumOff: string
) => {
	let color = 'ffffff'
	if (clrMap) {
		const key = clrMap[slideMasterNum][type]
		if (key) {
			color = themeColorMap[slideMasterNum][key]
		}
	}

	if (lumMod || lumOff) {
		// lumMod亮度调制 lumOff亮度偏移
		const brightColor = brightnessColor(color, +lumOff / 1000)
		if (brightColor) {
			color = brightColor
		}
	}
	return color
}

// 获取背景图片
export const getBackGroundImage = async (
	pptxFiles: any,
	upload: any,
	slideObject: any,
	slideRelsObject: any,
	slideLayoutObject: any,
	slideLayoutRelsObject: any,
	slideMasterObject: any,
	slideMasterRelsObject: any
) => {
	// 遵循 幻灯片——版式——母版的顺序
	let bgImageRels: any
	if (slideObject?.sld?.cSld?.bg?.bgPr?.blipFill?.blip) {
		// 幻灯片背景图片填充
		const bgImageId =
			slideObject.sld.cSld.bg.bgPr.blipFill.blip.attributes['r:embed']
		if (!bgImageId) {
			return
		}
		bgImageRels = slideRelsObject.Relationships.Relationship.find(
			(r: any) => r.attributes.Id === bgImageId
		)
	} else if (slideObject?.sld?.cSld?.bg?.bgPr?.noFill) {
		return
	} else if (slideLayoutObject?.sldLayout?.cSld?.bg?.bgPr?.blipFill?.blip) {
		// 版式背景图片填充
		const bgLayoutImageId =
			slideLayoutObject.sldLayout.cSld.bg.bgPr.blipFill.blip.attributes[
				'r:embed'
			]
		if (!bgLayoutImageId) {
			return
		}
		bgImageRels = slideLayoutRelsObject.Relationships.Relationship.find(
			(r: any) => r.attributes.Id === bgLayoutImageId
		)
	} else if (slideLayoutObject.sldLayout.attributes?.showMasterSp === '0') {
		return
	} else if (
		slideMasterObject?.sldMaster?.cSld?.bg?.bgPr?.blipFill?.blip?.attributes
	) {
		// 母版背景图片填充
		const bgImageId =
			slideMasterObject.sldMaster.cSld.bg.bgPr.blipFill.blip.attributes[
				'r:embed'
			]
		if (!bgImageId) {
			return
		}
		bgImageRels = slideMasterRelsObject.Relationships.Relationship.find(
			(r: any) => r.attributes.Id === bgImageId
		)
	}
	if (!bgImageRels) {
		return
	}

	const bgImagePath = bgImageRels.attributes.Target
	const bgImage = pptxFiles[`ppt${bgImagePath.replace('..', '')}`]
	try {
		const imageArrayBuffer = await bgImage.async('arraybuffer')
		const imageTypeArray = bgImage.name.split('.')
		const imageType = `image/${imageTypeArray[imageTypeArray.length - 1]}`
		const imageBlob = new Blob([imageArrayBuffer], { type: imageType })

		const imageName = bgImage.name.split('/').reverse()[0]
		let imageFile = new File([imageBlob], imageName, { type: imageType })

		if (imageFile && imageFile?.size > 1024 * 1024) {
			// 图片大小大于1M，压缩成700kb
			imageFile = await compressImage(imageFile, 700)
		}
		logger.time(`ppt-file parse background image:(${imageName}) upload time`)
		const imageUploadData = await upload(imageFile)
		logger.timeEnd(`ppt-file parse background image:(${imageName}) upload time`)
		return imageUploadData
	} catch (e) {
		logger.error(`ppt-file parse  background image upload failed`, e)
		throw new Error('背景图片上传失败')
	}
}

// 获取页面背景
export const getSlidBackground = async (
	pptxFiles: any,
	options: PptxToJsonOption,
	slideObject: any,
	slideRelsObject: any,
	slideLayoutObject: any,
	slideLayoutRelsObject: any,
	slideMaster: string
) => {
	const background: SlideBackground = {
		type: 'solid',
		color: '#fff'
	}
	const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')

	const slideMasterRelsFile =
		pptxFiles[`ppt/slideMasters/_rels/${slideMaster}.rels`]
	const slideMasterRelsContent = await slideMasterRelsFile.async('string')
	const slideMasterRelsObject = window.xml2js(
		slideMasterRelsContent,
		xmlToJsOption
	)

	const slideMasterPath = pptxFiles[`ppt/slideMasters/${slideMaster}`]
	const slideMasterContent = await slideMasterPath.async('string')
	const slideMasterObject = window.xml2js(slideMasterContent, xmlToJsOption)

	const bgImageData = await getBackGroundImage(
		pptxFiles,
		options.upload,
		slideObject,
		slideRelsObject,
		slideLayoutObject,
		slideLayoutRelsObject,
		slideMasterObject,
		slideMasterRelsObject
	)
	if (bgImageData) {
		background.type = 'image'
		background.imageSize = '100% 100%'
		background.image = bgImageData.url
		return background
	}

	// 单章背景颜色填充 或版式背景颜色 或母版背景颜色填充
	let bgColorKey
	if (slideObject?.sld?.cSld?.bg?.bgPr?.solidFill?.schemeClr?.attributes?.val) {
		bgColorKey = slideObject.sld.cSld.bg.bgPr.solidFill.schemeClr.attributes.val
	} else if (
		slideLayoutObject.sldLayout?.cSld?.bg?.bgPr?.solidFill?.schemeClr
			?.attributes?.val
	) {
		bgColorKey =
			slideLayoutObject.sldLayout.cSld.bg.bgPr.solidFill.schemeClr.attributes
				.val
	} else if (
		slideMasterObject?.sldMaster?.cSld?.bg?.bgPr?.solidFill?.schemeClr
			?.attributes?.val
	) {
		bgColorKey =
			slideMasterObject.sldMaster.cSld.bg.bgPr.solidFill.schemeClr.attributes
				.val
	}

	const bgColorLumMod =
		slideObject?.sld?.cSld?.bg?.bgPr?.solidFill?.schemeClr?.lumMod?.attributes
			?.val
	const bgColorLumOff =
		slideObject?.sld?.cSld?.bg?.bgPr?.solidFill?.schemeClr?.lumOff?.attributes
			?.val

	if (bgColorKey) {
		const bgColor = getBackGroundColor(
			slideMasterNum,
			bgColorKey,
			bgColorLumMod,
			bgColorLumOff
		)
		if (bgColor) {
			if (!bgColor.includes('#')) {
				background.color = '#' + bgColor
			} else {
				background.color = bgColor
			}
		}
	} else {
		let bgColor
		if (slideObject?.sld?.cSld?.bg?.bgPr?.solidFill?.srgbClr?.attributes?.val) {
			// 幻灯片
			bgColor = slideObject.sld.cSld.bg.bgPr.solidFill.srgbClr.attributes.val
		} else if (
			slideLayoutObject.sldLayout?.cSld?.bg?.bgPr?.solidFill?.srgbClr
				?.attributes?.val
		) {
			// 版式
			bgColor =
				slideLayoutObject.sldLayout.cSld.bg.bgPr.solidFill.srgbClr.attributes
					.val
		} else if (
			slideMasterObject?.sldMaster?.cSld?.bg?.bgPr?.solidFill?.srgbClr
				?.attributes?.val
		) {
			// 母版
			bgColor =
				slideMasterObject.sldMaster.cSld.bg.bgPr.solidFill.srgbClr.attributes
					.val
		}

		if (bgColor) {
			background.color = '#' + bgColor
		}
	}

	return background
}

// 获取每页宽高
export const getSlideSize = async (pptxFiles: any, PresentationPath: any) => {
	const slidePresentationXml = pptxFiles[PresentationPath]
	const slidePresentationContent = await slidePresentationXml.async('string')
	const slidePresentationObject = window.xml2js(
		slidePresentationContent,
		xmlToJsOption
	)

	const slideSize = slidePresentationObject.presentation.sldSz.attributes
	const width = emusToPx(slideSize.cx)
	const height = emusToPx(slideSize.cy)
	return {
		width,
		height
	}
}

// 从master文件中获取文本框类型基本样式信息，主要有title 、body和other类型
export const getTextDefStyleFormMaster = (
	slideMasterObject: any,
	type?: string
) => {
	let masterSp
	if (
		type === 'title' &&
		slideMasterObject.sldMaster.txStyles?.titleStyle?.lvl1pPr
	) {
		masterSp = {
			txBody: {
				lstStyle: {
					lvl1pPr: slideMasterObject.sldMaster.txStyles.titleStyle.lvl1pPr
				}
			}
		}
	} else if (
		type === 'body' &&
		slideMasterObject.sldMaster.txStyles?.bodyStyle?.lvl1pPr
	) {
		masterSp = {
			txBody: {
				lstStyle: {
					lvl1pPr: slideMasterObject.sldMaster.txStyles.bodyStyle.lvl1pPr
				}
			}
		}
	} else if (
		defaultTextSize ||
		slideMasterObject.sldMaster.txStyles?.otherStyle?.lvl1pPr
	) {
		let lvl1pPr
		if (defaultTextSize) {
			lvl1pPr = { defRPr: { attributes: { sz: defaultTextSize } } }
		} else {
			lvl1pPr = slideMasterObject.sldMaster.txStyles.otherStyle.lvl1pPr
		}

		masterSp = {
			txBody: {
				lstStyle: {
					lvl1pPr: lvl1pPr
				}
			}
		}
	}
	return masterSp
}

export const getSlideSizePer = async (pptxFile: any, viewportRatio: number) => {
	const slideSize = await getSlideSize(pptxFile, 'ppt/presentation.xml')
	const defaultCanvasSize = {
		width: 1920,
		height: viewportRatio === 0.5625 ? 1080 : 1080 / viewportRatio
	}
	const per = Math.max(
		slideSize.width / defaultCanvasSize.width,
		slideSize.height / defaultCanvasSize.height
	)
	slidePer = per
	return per
}
