import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { withDefaults } from 'vue'


export default _defineComponent({
  props: {
    type: { type: String, required: false, default: 'horizontal' },
    margin: { type: Number, required: false, default: 0 }
  } as unknown as undefined,
  setup(__props: {
  type?: 'horizontal' | 'vertical'
  margin?: number
}) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['divider', __props.type]),
    style: _normalizeStyle({
      margin: __props.type === 'horizontal' ? `${__props.margin || 24}px 0` : `0 ${__props.margin || 8}px`
    })
  }, null, 6))
}
}

})