import {
	PPTTextElement,
	PPTElementOutline,
	PPTElementShadow
} from '@/types/slides'
import { createRandomCode } from '@/utils/common'
import _ from 'lodash'

import {
	getPosition,
	getTextAlign,
	getTextAttributes,
	xmlToJsOption,
	isMaster,
	getBackGroundColor,
	getThemeColor,
	getTextDefStyleFormMaster,
	slidePer
} from '../../attribute'

let textStyleOtherDef: any

const getPContent = (
	partObject: any,
	partObjectNoCompact: any,
	masterSp: any,
	slideMasterNum: number,
	textStyleDef: any
) => {
	let pContent = ''
	const brNum = partObject?.br?.length || 0
	const endPara = partObject?.endParaRPr?.length || 0
	let parseTextNum = 0
	if (partObjectNoCompact) {
		partObjectNoCompact = partObjectNoCompact?.filter(
			(item: any) => item.name === 'r' || item.name === 'br'
		)
	}
	const tContentArray: string[] = []
	// 段落默认文字样式
	const pDefStyle = partObject?.lstStyle
	Object.entries(partObject).forEach(([key, value]) => {
		switch (key) {
			case 'r':
				{
					const values = Array.isArray(value) ? value : [value]

					values.forEach((value) => {
						const text = []
						const textContent = value.t._text || ''
						// 加粗 斜体 下划线
						const textTag = {
							// bold: value?.rPr?.attributes?.b || masterSp?.txBody?.bodyPr?.attributes?.anchor === 'b' ? 1 : 0,
							bold:
								value?.rPr?.attributes?.b === '1' ||
								pDefStyle?.defRPr?.attributes?.b === '1' ||
								masterSp?.txBody?.bodyPr?.attributes?.anchor === 'b'
									? 1
									: 0,
							em: value?.rPr?.attributes?.i === '1' ? 1 : 0,
							underLine:
								value?.rPr?.attributes?.u === '1' ||
								value?.rPr?.attributes?.u === 'sng'
									? 1
									: 0,
							baseLine: value?.rPr?.attributes?.baseline
						}
						let textAttributes = getTextAttributes(
							textTag.underLine,
							value,
							masterSp,
							textStyleDef,
							slideMasterNum,
							partObject?.lstStyle
						)

						let fontFamily =
							value.rPr?.latin?.attributes?.typeface || 'Sans-serif'
						if (!(fontFamily in ['Sans-serif', 'Serif'])) {
							fontFamily = 'Sans-serif'
						}
						if (!textAttributes.includes('font-size')) {
							if (
								textStyleOtherDef?.txBody.lstStyle.lvl1pPr.defRPr?.attributes
									?.sz
							) {
								let txSize =
									((textStyleOtherDef?.txBody.lstStyle.lvl1pPr.defRPr.attributes
										.sz /
										100) *
										4) /
									3

								if (slidePer !== 1) {
									txSize = Math.round(txSize / slidePer)
								}
								textAttributes = `${textAttributes}font-size:${txSize}px`
							}
						}

						let spanContent = `<span style="font-family:${fontFamily}"><span style="${textAttributes}">${textContent}</span></span>`
						text.push(spanContent)

						if (textTag.em) {
							text.unshift('<em>')
							text.push('</em>')
						}
						if (textTag.bold) {
							text.unshift('<strong>')
							text.push('</strong>')
						}
						if (textTag.baseLine) {
							if (textTag.baseLine > 0) {
								text.unshift('<sup>')
								text.push('</sup>')
							} else if (textTag.baseLine < 0) {
								text.unshift('<sub>')
								text.push('</sub>')
							}
						}
						spanContent = text.join('')

						if (brNum > 0 && endPara === brNum) {
							// 兼容ppt使用母版时的情况
							spanContent = `<p>${spanContent}</p>`
						}

						if (partObjectNoCompact && partObjectNoCompact[parseTextNum + 1]) {
							if (partObjectNoCompact[parseTextNum + 1].name === 'br') {
								parseTextNum++
								spanContent = `${spanContent}<br/>`
							}
						}

						parseTextNum++
						tContentArray.push(spanContent)
					})
					pContent = tContentArray.join('')
				}
				break
			default:
				break
		}
	})
	if (tContentArray.length <= 0) {
		pContent = '<span></span>'
	}
	return pContent
}

let listType = ''
const getPList = (partObject: any, masterSp: any) => {
	if (listType) {
		if (
			(!partObject?.pPr?.buChar &&
				!partObject?.pPr?.buAutoNum &&
				!masterSp?.txBody?.lstStyle?.lvl1pPr?.buChar) ||
			partObject?.pPr?.buNone
		) {
			const tag = listType === 'bulletList' ? '</ul>' : '</ol>'
			listType = ''
			return tag
		}
	} else {
		// 项目符号
		if (partObject?.pPr?.buChar) {
			listType = 'bulletList'
			return '<ul>'
		} else if (
			!partObject?.pPr?.buNone &&
			masterSp?.txBody?.lstStyle?.lvl1pPr?.buChar
		) {
			listType = 'bulletList'
			return '<ul>'
		}
		// 编号
		if (partObject?.pPr?.buAutoNum) {
			listType = 'orderedList'
			return '<ol>'
		}
	}
	return ''
}

const getTextBoxContent = async (
	sp: any,
	_spNoCompact: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFile: any
) => {
	const contentArray: string[] = []
	const partObject = sp?.txBody.p
	const lstStyleObject = sp?.txBody?.lstStyle?.lvl1pPr
	if (lstStyleObject) {
		partObject.lstStyle = lstStyleObject
	}

	// 获取自动缩放文本框的实际字号
	// debugger
	let p = sp.txBody.p
	if (!Array.isArray(p)) {
		p = [p]
	}
	p.map((_p: any) => {
		if (_p?.r) {
			if (sp?.txBody?.bodyPr?.normAutofit?.attributes?.fontScale) {
				let r = _p.r
				if (!Array.isArray(r)) {
					r = [r]
				}
				const scale =
					(sp?.txBody?.bodyPr?.normAutofit?.attributes?.fontScale || 100000) /
					100000
				r.map((item: any) => {
					if (item?.rPr?.attributes?.sz && !item.rPr?.attributes?.baseline) {
						item.rPr.attributes.sz *= scale
					} else {
						item = {
							...item,
							rpr: { attributes: { sz: item.rPr.attributes.sz *= scale } }
						}
					}
				})
			}
		}
	})

	let masterSp: any
	let textStyleDef: any

	// 判断是否使用母版
	if (isMaster(sp)) {
		const layoutPathRels = slideRelsObject?.Relationships?.Relationship
		let layoutPath: any
		if (Array.isArray(layoutPathRels)) {
			layoutPathRels.forEach((layoutPathRel) => {
				if (layoutPathRel.attributes.Type.includes('slideLayout')) {
					layoutPath = layoutPathRel.attributes?.Target
				}
			})
		} else {
			layoutPath =
				slideRelsObject?.Relationships?.Relationship?.attributes?.Target
		}

		if (layoutPath) {
			const layoutFile = pptxFile[`ppt${layoutPath.replace('..', '')}`]
			const layoutContent = await layoutFile.async('string')
			const layoutObject = window.xml2js(layoutContent, xmlToJsOption)
			const layoutSp = layoutObject?.sldLayout?.cSld?.spTree?.sp
			const masterElementType =
				sp?.nvSpPr?.nvPr?.ph?.attributes?.type ||
				sp?.nvSpPr?.nvPr?.ph?.attributes?.idx

			const slideMasterPath = pptxFile[`ppt/slideMasters/${slideMaster}`]
			const slideMasterContent = await slideMasterPath.async('string')
			const slideMasterObject = window.xml2js(slideMasterContent, xmlToJsOption)

			textStyleDef = getTextDefStyleFormMaster(
				slideMasterObject,
				masterElementType === '1' ? 'body' : masterElementType
			)
			textStyleOtherDef = getTextDefStyleFormMaster(slideMasterObject, 'other')
			masterSp = layoutSp?.find(
				(item: any) =>
					(item?.nvSpPr?.nvPr?.ph?.attributes?.type ||
						item?.nvSpPr?.nvPr?.ph?.attributes?.idx) === masterElementType
			)
			if (!masterSp || masterElementType === '1') {
				masterSp = textStyleDef
			}

			const txPosition = [...slideMasterObject.sldMaster.cSld.spTree.sp]

			let position
			if (!(sp.spPr.xfrm || sp.xfrm)) {
				position = txPosition?.find(
					(item: any) =>
						item?.nvSpPr?.nvPr?.ph?.attributes?.type === masterElementType ||
						item?.nvSpPr?.nvPr?.ph?.attributes?.idx === masterElementType
				)
				position = position?.spPr?.xfrm
				sp = { ...sp, xfrm: position }
			}
		}
	}

	let content = ''
	let multiP = false
	listType = ''
	const keys = Object.keys(partObject)
	keys.forEach((key) => {
		if (/^\d+$/.test(key)) {
			multiP = true
		}
	})
	const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
	if (multiP) {
		// 多段落情况
		Object.entries(partObject).forEach(([, value]: any) => {
			// 排序编号
			const listTag = getPList(value, masterSp)
			if (listTag) {
				contentArray.push(listTag)
			}
			content = getPContent(value, '', masterSp, slideMasterNum, textStyleDef)
			if (!content) {
				return
			}
			if (
				(value?.pPr?.buChar ||
					value?.pPr?.buAutoNum ||
					masterSp?.txBody?.lstStyle?.lvl1pPr?.buChar) &&
				!value?.pPr?.buNone &&
				content
			) {
				contentArray.push(`<li>`)
			}
			// 行间距
			// const lineHeight = value?.pPr?.lnSpc?.spcPct?.attributes?.val / 100000 || '1.2'
			// if (value?.pPr?.lnSpc?.spcPct?.attributes?.val) {
			//   contentArray.push(`<p style="line-height: ${lineHeight};">`)
			// }
			const textAlign = getTextAlign(
				value?.pPr,
				masterSp?.txBody?.lstStyle?.lvl1pPr
					? masterSp?.txBody?.lstStyle
					: textStyleDef?.txBody?.lstStyle,
				sp?.txBody?.bodyPr
			)
			contentArray.push(`<p style=${textAlign}>${content}</p>`)

			// if (value?.pPr?.lnSpc?.spcPct?.attributes?.val) {
			//   contentArray.push(`</p>`)
			// }
			if (
				(value?.pPr?.buChar ||
					value?.pPr?.buAutoNum ||
					masterSp?.txBody?.lstStyle?.lvl1pPr?.buChar) &&
				!value?.pPr?.buNone &&
				content
			) {
				contentArray.push('</li>')
			}
		})
	} else {
		// 单段落情况
		const textAlign = getTextAlign(
			partObject?.pPr,
			masterSp?.txBody?.lstStyle?.lvl1pPr
				? masterSp?.txBody?.lstStyle
				: textStyleDef?.txBody?.lstStyle,
			sp?.txBody?.bodyPr
		)
		content = getPContent(
			partObject,
			_spNoCompact?.[0].elements,
			masterSp,
			slideMasterNum,
			textStyleDef
		)
		// 文本框里无内容，则过滤
		if (!content || content === '<span></span>') {
			return
		}
		const listTag = getPList(partObject, masterSp)
		if (listTag) {
			contentArray.push(listTag)
			contentArray.push('<li>')
		}
		if (content.includes('</p>')) {
			content = content.replaceAll('<p>', `<p style=${textAlign}>`)
			contentArray.push(content)
		} else {
			if (content.includes('<br/>')) {
				const tempContents = content.split('<br/>')
				tempContents.forEach((tempContent) => {
					contentArray.push(`<p style=${textAlign}>${tempContent}</p>`)
				})
			} else {
				contentArray.push(`<p style=${textAlign}>${content}</p>`)
			}
		}
		if (listTag) {
			contentArray.push('</li>')
			const endListTag = listTag === '<ul>' ? '</ul>' : '<ol>'
			contentArray.push(endListTag)
		}
	}
	return { textContent: contentArray.join(''), sp }
}

const changeElement = async (
	sp: any,
	_spNoCompact: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFile: any
) => {
	const textElement: PPTTextElement = {
		type: 'text',
		id: createRandomCode(),
		left: 0,
		top: 0,
		width: 0,
		height: 0,
		content: '',
		rotate: 0,
		defaultFontName: 'Sans-serif',
		defaultColor: '#000',
		lineHeight: 1.2
	}
	const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
	const content = await getTextBoxContent(
		sp,
		_spNoCompact,
		slideRelsObject,
		slideMaster,
		pptxFile
	)
	if (content) {
		textElement.content = content.textContent
		sp = content.sp
	}
	// 文本框旋转角度
	if (sp?.spPr?.xfrm?.attributes?.rot) {
		textElement.rotate = sp?.spPr.xfrm.attributes.rot / 60000
	}
	// 文本框填充颜色
	if (sp?.spPr?.solidFill?.schemeClr?.attributes?.val) {
		const fillColor = getBackGroundColor(
			slideMasterNum,
			sp.spPr.solidFill.schemeClr.attributes.val,
			sp.spPr.solidFill.schemeClr?.lumMod?.attributes?.val,
			sp.spPr.solidFill.schemeClr?.lumOff?.attributes?.val
		)
		if (fillColor) {
			textElement.fill = '#' + fillColor
		}
	} else if (sp?.spPr?.solidFill?.srgbClr) {
		textElement.fill = '#' + sp?.spPr?.solidFill?.srgbClr?.attributes.val
	}

	if (!textElement.content) {
		return
	}

	const position = await getPosition(sp, slideRelsObject, pptxFile, slideMaster)

	// 边框
	if (sp?.spPr?.ln) {
		if (!sp.spPr.ln?.noFill) {
			const outline: PPTElementOutline = {
				style:
					sp.spPr.ln?.prstDash?.attributes?.val
						.toLowerCase()
						.indexOf('solid') === -1
						? 'dashed'
						: 'solid',
				width: (sp.spPr.ln?.attributes?.w || 12700) / 12700,
				color: `#${sp.spPr.ln?.solidFill?.srgbClr?.attributes.val ||
					sp.spPr.ln?.solidFill?.schemeClr?.attributes?.val ||
					'000000'}`
			}

			textElement.outline = outline
		}
	}
	// 阴影
	if (sp.spPr?.effectLst?.outerShdw) {
		const shadow: PPTElementShadow = {
			blur: 1,
			color: `#000000`,
			h: (sp.spPr.effectLst.outerShdw?.attributes?.sx || 12700) / 12700,
			v: (sp.spPr.effectLst.outerShdw?.attributes?.sy || 12700) / 12700
		}
		if (sp.spPr.effectLst.outerShdw?.attributes?.blurRad) {
			shadow.blur =
				(sp.spPr.effectLst.outerShdw?.attributes?.blurRad / 12700 / 100) * 20
		}
		if (sp.spPr?.effectLst?.outerShdw?.srgbClr?.attributes?.val) {
			shadow.color = `#${sp.spPr.effectLst.outerShdw.srgbClr.attributes.val}`
		} else if (sp.spPr?.effectLst?.outerShdw?.schemeClr?.attributes?.val) {
			const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
			const color = getThemeColor(
				slideMasterNum,
				sp.spPr.effectLst.outerShdw.schemeClr.attributes.val
			)
			if (color) {
				shadow.color = color
			}
		}
		textElement.shadow = shadow
	} else if (sp?.txBody?.p[0]?.r?.rPr?.effectLst?.outerShdw) {
		const shadow: PPTElementShadow = {
			blur: 1,
			color: `#000000`,
			h:
				(sp.txBody.p[0].r.rPr.effectLst.outerShdw?.attributes?.sx || 12700) /
				12700,
			v:
				(sp.txBody.p[0].r.rPr.effectLst.outerShdw?.attributes?.sy || 12700) /
				12700
		}
		if (sp.txBody.p[0].r.rPr.effectLst.outerShdw?.attributes?.blurRad) {
			shadow.blur =
				(sp.txBody.p[0].r.rPr.effectLst.outerShdw?.attributes?.blurRad /
					12700 /
					100) *
				20
		}
		if (sp.txBody.p[0].r.rPr.effectLst.outerShdw?.srgbClr?.attributes?.val) {
			shadow.color = `#${sp.txBody.p[0].r.rPr.effectLst.outerShdw.srgbClr.attributes.val}`
		} else if (
			sp.txBody.p[0].r.rPr.effectLst.outerShdw?.schemeClr?.attributes?.val
		) {
			const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
			const color = getThemeColor(
				slideMasterNum,
				sp.txBody.p[0].r.rPr.effectLst.outerShdw.schemeClr.attributes.val
			)
			if (color) {
				shadow.color = color
			}
		}
		textElement.shadow = shadow
	} else if (sp?.txBody?.p?.r?.rPr?.effectLst?.outerShdw) {
		const shadow: PPTElementShadow = {
			blur: 1,
			color: `#000000`,
			h:
				(sp.txBody.p.r.rPr.effectLst.outerShdw?.attributes?.sx || 12700) /
				12700,
			v:
				(sp.txBody.p.r.rPr.effectLst.outerShdw?.attributes?.sy || 12700) / 12700
		}
		if (sp.txBody.p.r.rPr.effectLst.outerShdw?.attributes?.blurRad) {
			shadow.blur =
				(sp.txBody.p.r.rPr.effectLst.outerShdw?.attributes?.blurRad /
					12700 /
					100) *
				20
		}
		if (sp.txBody.p.r.rPr.effectLst.outerShdw?.srgbClr?.attributes?.val) {
			shadow.color = `#${sp.txBody.p.r.rPr.effectLst.outerShdw.srgbClr.attributes.val}`
		} else if (
			sp.txBody.p.r.rPr.effectLst.outerShdw?.schemeClr?.attributes?.val
		) {
			const slideMasterNum = +slideMaster.replace(/[^0-9]/gi, '')
			const color = getThemeColor(
				slideMasterNum,
				sp.txBody.p.r.rPr.effectLst.outerShdw.schemeClr.attributes.val
			)
			if (color) {
				shadow.color = color
			}
		}
		textElement.shadow = shadow
	}
	// 透明度
	if (sp?.txBody?.p?.r) {
		let r = sp.txBody.p.r
		if (!Array.isArray(r)) {
			r = [r]
		}
		if (r[0]?.rPr?.solidFill?.schemeClr?.alpha?.attributes?.val) {
			const opacity = r[0].rPr.solidFill.schemeClr.alpha.attributes.val / 100000
			textElement.opacity = opacity
		}
	}
	// 行间距
	if (sp?.txBody?.p) {
		let p = sp.txBody.p
		if (!Array.isArray(p)) {
			p = [p]
		}
		if (p[0]?.pPr?.lnSpc?.spcPct?.attributes?.val) {
			const lineHeight = p[0].pPr.lnSpc.spcPct.attributes.val / 100000
			// 其他平台的行间距与本平台比例不一致
			// 兼容解析时行间距小于1的情况（本平台行间距小于1，两行字间会有重叠）
			if (lineHeight < 1) {
				textElement.lineHeight = 1
			} else {
				textElement.lineHeight = lineHeight
			}
		}
	}

	_.merge(textElement, position)
	return textElement
}

// 元素内容可能来自幻灯片 、 版式 、 母版
const spTreeFrom = {
	slide: 0,
	layout: 1,
	master: 2
}

export default async (
	sp: any,
	spTreeFromNum: number,
	spTreeEntryNoCompact: any,
	slideRelsObject: any,
	slideMaster: string,
	pptxFile: any
) => {
	const element: PPTTextElement[] = []
	const elementObject: any = []
	// 当只有一个文本元素时，需转化为数组形式
	if (!Array.isArray(sp)) {
		sp = [sp]
	}
	const spNoCompact = spTreeEntryNoCompact.elements?.filter(
		(element: any) => element.name === 'sp'
	)
	for (let i = 0; i < sp.length; i++) {
		const _sp = sp[i]
		// if (_sp?.spPr?.prstGeom?.attributes?.prst !== "rect") continue
		if (!_sp.txBody) continue
		if (_sp.style) continue
		if (
			spTreeFromNum === spTreeFrom.layout ||
			spTreeFromNum === spTreeFrom.master
		) {
			if (!_sp?.txBody?.bodyPr?.spAutoFit) {
				continue
			}
		}
		const textId = _sp?.nvSpPr?.cNvPr?.attributes?.id

		let _spNoCompact
		spNoCompact.forEach((element: any) => {
			element.elements.forEach((el: any) => {
				if (el.name === 'nvSpPr') {
					el.elements.forEach((nvSpEl: any) => {
						if (nvSpEl.name === 'cNvPr') {
							if (nvSpEl.attributes.id === textId) {
								const txBodyElement = element.elements.filter(
									(txBodyEl: any) => txBodyEl.name === 'txBody'
								)[0]
								_spNoCompact = txBodyElement.elements.filter(
									(pEl: any) => pEl.name === 'p'
								)
							}
						}
					})
				}
			})
		})

		const textElement = await changeElement(
			_sp,
			_spNoCompact,
			slideRelsObject,
			slideMaster,
			pptxFile
		)
		if (textElement) {
			element.push(textElement)
			// id用于后续层级排序，element为实际所需元素
			elementObject.push({
				id: _sp?.nvSpPr?.cNvPr?.attributes?.id,
				element: textElement
			})
		}
	}
	return elementObject
}
