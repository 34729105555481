/*
 * @description:  运行配置
 * @version: 1.0
 * @author: xuhuale
 * @email: xuhuale@star-net.cn
 * @date: 2021-09-22 13:58:07
 * @lastEditors: xuhuale
 * @lastEditTime: 2022-12-07 16:19:37
 */

import { ScreenType, State } from '@/store/state'
import { ToolBarTab } from './toolbar'

// 导入导出控制
export interface ImportExportControl {
	import: boolean
	export: boolean
}

export interface Runtime {
	uploadMode: 'local' | 'cloud' // 上传模式
	state?: Partial<State> // 状态值
	isSavePpt: boolean // 是否显示保存
	isShowDocument: boolean // 是否显示文件（导入、导出）
	importExportControl: ImportExportControl // 导入，导出菜单配置
	playPptRule: {
		// 放映 结束放映规则
		play: ScreenType
		stop: ScreenType
		showFinishPlayButton: boolean
		showSlideThumbnails: boolean
	}
	editPptRule: {
		// 编辑PPT显示规则
		mode: 'onlyPpt' | 'multiple' | 'multipleTabs' // 只有ppt | 不止有ppt | 多标签
	}
	demMoreResourceBtn: boolean // 放映时是否显示更多按钮
	pagingScreenNum: number // 分页放映时的页数
	dynamicEditorTabs: Array<ToolBarTab>
	dynamicPreviewerTabs: Array<ToolBarTab> // 预览时右侧动态tab
	extData?: { [key: string]: any } // 而外的数据，由外部传入，一般是传递有关课件的详细数据
	autoLoadPNPpt: boolean // 是否开启“再次点击跳转上下课时的内容”
	isShowWorkbenchFooter: boolean // 多标签页情况，打开时，任务栏是否展开
	baseBurialPointData?: { [key: string]: any } // 游戏相关埋点配置
	gameEnvConfig?: { [key: string]: any } // 游戏环境配置
	isPersonalPC?: boolean // PC个人版（妙极课用户）
	subject?: string // 用户当前登陆的学科 默认music
}

const runtime: Runtime = {
	uploadMode: 'cloud',
	isSavePpt: true,
	isShowDocument: true,
	importExportControl: {
		import: true,
		export: true
	},
	playPptRule: {
		play: 'demonstrate',
		stop: 'edit-check',
		showFinishPlayButton: true,
		showSlideThumbnails: false
	},
	editPptRule: {
		mode: 'multiple'
	},
	demMoreResourceBtn: false,
	pagingScreenNum: 2,
	dynamicEditorTabs: [],
	dynamicPreviewerTabs: [],
	extData: {},
	autoLoadPNPpt: false,
	isShowWorkbenchFooter: true,
	baseBurialPointData: {},
	gameEnvConfig: {},
	isPersonalPC: false,
	subject: 'music'
}

export default runtime
