import { PPTElfAudioElement } from '@/types/slides'
import { createRandomCode } from '@/utils/common'
import logger from '@evideo/logger'
import { getPosition } from '../../attribute'

const getAudioData = async (
	slideIndex: number,
	media: any,
	audioInfo: any,
	slideRelsObject: any,
	pptxFiles: any,
	upload: any
) => {
	let audioLinkId: any
	if (audioInfo) {
		audioLinkId =
			audioInfo['p14:media']?.attributes?.['r:embed'] ||
			audioInfo['p14:media']?.attributes?.['r:link']
	} else if (media) {
		audioLinkId = media.attributes?.['r:link']
	}

	// 裁剪音频
	// const startTime = audioInfo['p14:media']?.['p14:trim']?.attributes?.st
	// const endTime = audioInfo['p14:media']?.['p14:trim']?.attributes?.end
	if (!audioLinkId) {
		return
	}
	const relationships = slideRelsObject.Relationships.Relationship || []
	const audioRels = relationships.find(
		(r: any) => r.attributes.Id === audioLinkId
	)
	if (!audioRels) {
		return
	}

	const audioPath = audioRels.attributes.Target
	// 兼容链接音频情况，链接时不解析
	if (audioRels.attributes?.TargetMode === 'External') {
		return 'external'
	}
	const audio = pptxFiles[`ppt${audioPath.replace('..', '')}`]

	if (!audio) {
		return
	}
	try {
		const audioArraybuffer = await audio.async('arraybuffer')
		const audioTypeArray = audio.name.split('.')
		const audioType = `audio/${audioTypeArray[audioTypeArray.length - 1]}`
		const audioBlob = new Blob([audioArraybuffer], { type: audioType })

		const audioName = audio.name.split('/').reverse()[0]
		const audioFile = new File([audioBlob], audioName, { type: audioType })

		logger.time(`ppt-file parse audio:(${audioName}) upload time`)
		const audioUploadData = await upload(audioFile)
		logger.timeEnd(`ppt-file parse audio:(${audioName}) upload time`)

		audioUploadData.name = audioName.split('.')[0]
		return audioUploadData
	} catch (e) {
		logger.error(`ppt-file parse audio upload failed`, e)
		throw new Error(`第${slideIndex}页PPT 音频上传失败,请重试`)
	}
}
export default async (
	slideIndex: number,
	media: any,
	pic: any,
	slideRelsObject: any,
	pptxFiles: any,
	upload: any
) => {
	const audioElement: PPTElfAudioElement = {
		type: 'elf',
		subtype: 'elf-audio',
		id: createRandomCode(),
		left: 0,
		top: 0,
		width: 400,
		height: 50,
		src: '',
		name: '',
		editPreview: true
	}
	const audioInfo = pic?.nvPicPr?.nvPr?.extLst?.ext
	const audioData = await getAudioData(
		slideIndex,
		media,
		audioInfo,
		slideRelsObject,
		pptxFiles,
		upload
	)
	// 兼容链接音频情况，链接时不解析
	if (audioData === 'external') {
		return 'external'
	}
	if (audioData) {
		audioElement.src = audioData.url
		audioElement.size = audioData.size
		audioElement.md5 = audioData.md5
		audioElement.name = audioData.name
	}

	if (!audioElement.src) {
		return
	}
	const position = await getPosition(pic, slideRelsObject, pptxFiles)
	if (position) {
		audioElement.left = position?.left
		audioElement.top = position?.top
	}
	return audioElement
}
