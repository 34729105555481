import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Editor = _resolveComponent("Editor")!
  const _component_EvScreen = _resolveComponent("EvScreen")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.screening)
      ? (_openBlock(), _createBlock(_component_Editor, { key: 0 }))
      : (_openBlock(), _createBlock(_component_EvScreen, { key: 1 })),
    _createVNode(_component_Footer, {
      style: _normalizeStyle(
      !_ctx.screening
        ? 'visibility:visible;height:25px'
        : 'visibility:hidden;height:0px'
    )
    }, null, 8, ["style"])
  ], 64))
}