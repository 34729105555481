
import { defineComponent, ref, watch, computed} from 'vue'
import LinkButtonDialog from '../views/Editor/CanvasTool/LinkButtonDialog.vue'
import { MutationTypes, useStore } from '@/store'

export default defineComponent({
	name: 'check-link',
	components: {
		LinkButtonDialog
	},
	setup() {
		const linkDialogVisible = ref(false)
		const openLinkDialog = () => (linkDialogVisible.value = true)

		const store = useStore()
    const isCreatingLinkButton = computed(() => store.state.creatingElement)

		watch(linkDialogVisible, () => {
			if (!linkDialogVisible.value && isCreatingLinkButton && isCreatingLinkButton.value?.type === 'link-button') {
				store.commit(MutationTypes.SET_CREATING_ELEMENT, null)
			}
		})
		return {
			linkDialogVisible,
			openLinkDialog
		}
	}
})
