import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ConfigProvider = _resolveComponent("ConfigProvider")!

  return (_openBlock(), _createElementBlock("div", {
    ref: $setup.appContainer,
    class: "appContainer"
  }, [
    _createVNode(_component_ConfigProvider, {
      getPopupContainer: $setup.getPopupContainer,
      locale: $setup.locale
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["getPopupContainer", "locale"])
  ], 512))
}