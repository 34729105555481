import { Slide } from '@/types/slides'
import { createRandomCode } from '@/utils/common'

export const slides: Slide[] = [
	{
		id: createRandomCode(),
		elements: [
		],
		background: {
			type: 'solid',
			color: '#ffffff'
		}
	}
]
