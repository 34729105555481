// 进入全屏
export const enterFullscreen = (dom?: any) => {
  const docElm = dom || document.documentElement
  if (docElm.requestFullscreen) docElm.requestFullscreen()
  else if (docElm.mozRequestFullScreen) docElm.mozRequestFullScreen()
  else if (docElm.webkitRequestFullScreen) docElm.webkitRequestFullScreen()
}

// 退出全屏
export const exitFullscreen = () => {
  if (document.exitFullscreen) document.exitFullscreen()
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
  else if (document.webkitCancelFullScreen) document.webkitCancelFullScreen()
}


// 监听全部事件变化
export const registerFullScreenChange = (callBack: EventListenerOrEventListenerObject) => {
  if (!callBack) {
    return
  }
  ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach((key) => {
    window.addEventListener(key, callBack)
  })
}
// 移除全屏事件变化
export const removeFullScreenChange = (callBack: EventListenerOrEventListenerObject) => {
  if (!callBack) {
    return
  }
  ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'].forEach((key) => {
    window.removeEventListener(key, callBack)
  })
}

// 判断是否全屏
export const isFullscreen = () => (
  document.mozFullScreen ||
  document.webkitIsFullScreen ||
  document.webkitFullScreen
)