import * as minimatch from 'minimatch'
import { MutationTypes, store } from '@/store'
import { getSlideSize } from '../attribute'

const pptxFilePath = {
	ContentTypesPath: '[Content_Types].xml',
	PresentationRelsPath: 'ppt/_rels/presentation.xml.rels',
	SlidePath: 'ppt/slides/slide[0-9]*.xml',
	SlideRelsPath: 'ppt/slides/_rels/slide[0-9]*.xml.rels',
	MediaPath: 'ppt/media/**/*.**',
	themePath: 'ppt/theme/theme[0-9]*.xml',
	PresentationPath: 'ppt/presentation.xml',
	SlideLayoutsPath: 'ppt/slideLayouts/slideLayout[0-9]*.xml'
}

// 紧凑型，元素按类型分类，无层级
const xmlToJsOption = {
	compact: true,
	ignoreDeclaration: true,
	attributesKey: 'attributes',
	elementNameFn: (s: string) => {
		return s.replace('p:', '').replace('a:', '')
	}
}

export default async (pptxFiles: any) => {
	const allFilesNames = Object.keys(pptxFiles)
	const themeFiles =
		minimatch.match(allFilesNames, pptxFilePath.themePath, {
			matchBase: true
		}) || []
	const themeFile = pptxFiles[themeFiles[0]]
	const themeContent = await themeFile.async('string')
	const themeObject = window.xml2js(themeContent, xmlToJsOption)

	const layoutFiles =
		minimatch.match(allFilesNames, pptxFilePath.SlideLayoutsPath, {
			matchBase: true
		}) || []
	let layout = {}
	for (let i = 0; i < layoutFiles.length; i++) {
		const layoutFile = pptxFiles[layoutFiles[i]]
		const layoutContent = await layoutFile.async('string')
		const layoutObject = window.xml2js(layoutContent, xmlToJsOption)
		layout = { ...layout, ...layoutObject.sldLayout.cSld.spTree.sp }
	}

	const colorTheme = themeObject.theme.themeElements.clrScheme
	// const fontTheme = themeObject.theme.themeElements.fontScheme
	const defaultSettings = themeObject.theme.objectDefaults
	// 获取默认背景颜色、字体颜色、主题颜色
	const backgroundColor = `#${colorTheme.lt1.sysClr?.attributes.lastClr ||
		colorTheme.lt1.srgbClr?.attributes.val}`
	const fontColor = `#${colorTheme.dk1.sysClr?.attributes.lastClr ||
		colorTheme.dk1.srgbClr?.attributes.val}`
	const themeColor = `#${colorTheme.accent1.sysClr?.attributes.lastClr ||
		colorTheme.accent1.srgbClr?.attributes.val}`
	// 获取默认字体名称
	const font =
		defaultSettings?.txDef?.lstStyle?.defPPr?.defRPr?.latin?.attributes
			?.typeface
	const availableFonts = store.state.availableFonts.map((font) =>
		font.value.toLowerCase()
	)
	const fontName = availableFonts.includes(font) ? font : 'Sans-serif'
	// 获取默认字体大小
	// const fontSize = `${defaultSettings?.txDef?.lstStyle?.defPPr?.defRPr?.attributes?.sz / 100 || '40'}px`
	const fontSize = '40px'
	const richTextAttrs = store.state.richTextAttrs
	store.commit(MutationTypes.SET_RICHTEXT_ATTRS, {
		...richTextAttrs,
		fontsize: fontSize
	})

	// 获取幻灯片长宽比
	const slideSize = await getSlideSize(pptxFiles, 'ppt/presentation.xml')
	let viewportRatio = 0.5625
	if (slideSize.height / slideSize.width === 9 / 16) {
		viewportRatio = 0.5625
	} else if (slideSize.height / slideSize.width === 3 / 4) {
		viewportRatio = 0.75
	} else if (+(slideSize.height / slideSize.width).toFixed(3) === 0.626) {
		viewportRatio = 0.625
	}
	const bestViewportRatio = viewportRatio

	return {
		backgroundColor,
		fontColor,
		themeColor,
		fontName,
		fontSize,
		viewportRatio,
		layout,
		bestViewportRatio
	}
}
