
  import { watch, defineComponent, reactive, ref } from 'vue';

  export default defineComponent({
    name: 'CommonRadio',
    props: {
      isCheckAll: {
        type: Boolean,
        default: false,
      },
      allValue: {
        type: Number || String,
      },
      value: {
        type: Number,
      },
      options: {
        type: Array,
        default: () => {
          return [];
        },
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const allItem = reactive({
        label: '全部',
        value: props.allValue,
        checked: false,
      });
      const list = ref<any[]>([]);
      watch(
        () => props,
        () => {
          list.value = props.isCheckAll
            ? [allItem, ...props.options]
            : props.options;
          list.value.map((i) => {
            i.checked = props.value === i.value;
            return i;
          });
        },
        { deep: true, immediate: true }
      );
      const handleClick = (item: any) => {
        if (props.readOnly) {
          return;
        }
        if (!item.checked) {
          list.value.map((i) => {
            i.checked = i.value === item.value;
          });
        } else {
          list.value.map((i) => {
            i.checked = i.value === allItem.value;
          });
        }

        const res = list.value
          .filter((i) => i.checked)
          .map((i) => {
            return i.value;
          });
        emit('update:value', res[0]);
        emit('change', res[0]);
      };
      return {
        allItem,
        list,
        handleClick,
      };
    },
  });
