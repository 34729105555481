import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2451c9af")
const _hoisted_1 = ["id", "title"]
const _hoisted_2 = ["id", "d"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementLinkIcon = _resolveComponent("ElementLinkIcon")!
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["screen-element", { link: _ctx.elementInfo.link }]),
    id: `screen-element-${_ctx.elementInfo.id}`,
    style: _normalizeStyle({
			zIndex: _ctx.elementIndex,
			color: _ctx.theme.fontColor,
			fontFamily: _ctx.theme.fontName,
			visibility: _ctx.needWaitAnimation ? 'hidden' : 'visible'
		}),
    title: _ctx.linkTip,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClickScreenElement && _ctx.handleClickScreenElement(...args)))
  }, [
    (_ctx.elementInfo?.link && _ctx.elementInfo.type !== 'link-button')
      ? (_openBlock(), _createBlock(_component_ElementLinkIcon, {
          key: 0,
          elementInfo: _ctx.elementInfo,
          style: _normalizeStyle({
				zIndex: _ctx.elementIndex,
				color: _ctx.theme.fontColor,
				fontFamily: _ctx.theme.fontName,
				visibility: _ctx.needWaitAnimation ? 'hidden' : 'visible'
			})
        }, null, 8, ["elementInfo", "style"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      key: _ctx.elementInfo.id,
      elementInfo: _ctx.elementInfo,
      inScreening: true,
      ref: "component"
    }, null, 8, ["elementInfo"])),
    _createElementVNode("div", {
      class: "animation-custom-path",
      style: _normalizeStyle({
				top: _ctx.elementAnimationLineData?.offsetTop + 'px',
				left: _ctx.elementAnimationLineData?.offsetLeft + 'px',
				display: 'none'
			})
    }, [
      _createVNode(_component_SvgWrapper, {
        overflow: "visible",
        left: 0,
        top: 0,
        width: _ctx.elementAnimationLineData?.svgWidth,
        height: _ctx.elementAnimationLineData?.svgHeight
      }, {
        default: _withCtx(() => [
          _createElementVNode("path", {
            id: `animation-custom-path-${_ctx.elementInfo.id}`,
            d: _ctx.elementAnimationLineData?.path,
            stroke: "#c1c1c1",
            fill: "none",
            "stroke-width": "2",
            "stroke-dasharray": "5,5",
            "stroke-linecap": "",
            "stroke-linejoin": "",
            "stroke-miterlimit": ""
          }, null, 8, _hoisted_2)
        ]),
        _: 1
      }, 8, ["width", "height"])
    ], 4)
  ], 14, _hoisted_1))
}