import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0841a8a7")
const _hoisted_1 = { class: "editable-input" }
const _hoisted_2 = ["value"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "input-content",
      value: _ctx.val,
      onInput: _cache[0] || (_cache[0] = $event => _ctx.handleInput($event))
    }, null, 40, _hoisted_2)
  ]))
}