import _ from 'lodash'
type Point = {
	x: number
	y: number
}


export const getLineDistance = (p1: Point, p2: Point) => {
	return Math.sqrt(Math.pow(p1.x + p2.x, 2) + Math.pow(p1.y + p1.y, 2))
}

export const getLineControlPoints = (
	point: Point,
	point1: Point,
	point2: Point
) => {
	// 调整  把控制点放在俩个点的矩形内
	const ratio = 0.5
	// 三个点出两个控制点
	const p01 = getLineDistance(point, point1)
	const p12 = getLineDistance(point1, point2)
	const p02 = p01 + p12
	const vector = [point2.x - point.x, point2.y - point.y]
	let x1 = Math.round(point1.x - (vector[0] * ratio * p01) / p02)
	let y1 = Math.round(point1.y - (vector[1] * ratio * p01) / p02)

	// if (x1 < point.x) {
	// 	x1 = point.x - (point.x - x1) * ratio
	// }
	// if (x1 > point1.x) {
	// 	x1 = point1.x + (x1 - point1.x) * ratio
	// }

	if (point.x < point1.x) {
		if (x1 < point.x) {
			x1 = point.x - (point.x - x1) * ratio
		}
		if (x1 > point1.x) {
			x1 = point1.x + (x1 - point1.x) * ratio
		}
	} else {
		if (x1 < point1.x) {
			x1 = point1.x - (point1.x - x1) * ratio
		}
		if (x1 > point.x) {
			x1 = point.x + (x1 - point.x) * ratio
		}
	}

	if (point.y < point1.y) {
		if (y1 < point.y) {
			y1 = point.y - (point.y - y1) * ratio
		}
		if (y1 > point1.y) {
			y1 = point1.y + (y1 - point1.y) * ratio
		}
	} else {
		if (y1 < point1.y) {
			y1 = point1.y - (point1.y - y1) * ratio
		}
		if (y1 > point.y) {
			y1 = point.y + (y1 - point.y) * ratio
		}
	}

	let x2 = Math.round(point1.x + (vector[0] * ratio * p01) / p02)
	let y2 = Math.round(point1.y + (vector[1] * ratio * p01) / p02)

	// if (x2 < point1.x) {
	// 	x2 = point1.x - (point1.x - x2) * ratio
	// }
	// if (x2 > point2.x) {
	// 	x2 = point2.x + (x2 - point2.x) * ratio
	// }

	if (point1.x < point2.x) {
		if (x2 < point1.x) {
			x2 = point1.x - (point1.x - x2) * ratio
		}
		if (x2 > point2.x) {
			x2 = point2.x + (x2 - point2.x) * ratio
		}
	} else {
		if (x2 < point2.x) {
			x2 = point2.x - (point2.x - x2) * ratio
		}
		if (x2 > point1.x) {
			x2 = point1.x + (x2 - point1.x) * ratio
		}
	}

	if (point1.y < point2.y) {
		if (y2 < point1.y) {
			y2 = point1.y - (point1.y - y2) * ratio
		}
		if (y2 > point2.y) {
			y2 = point2.y + (y2 - point2.y) * ratio
		}
	} else {
		if (y2 < point2.y) {
			y2 = point2.y - (point2.y - y2) * ratio
		}
		if (y2 > point1.y) {
			y2 = point1.y + (y2 - point1.y) * ratio
		}
	}

	return [
		{
			x: x1,
			y: y1
		},
		{
			x: x2,
			y: y2
		}
	]
}

export const getCurveLinePath = (data: Point[], isEnd = false) => {
	const points = _.cloneDeep(data)
	const len = data.length
	let dPath = ''
	let a: any = []
	let i = 1
	if (len === 2) {
		return `M ${points[0].x},${points[0].y} L ${points[1].x},${points[1].y}`
	}
	for (; i < len - 1; i++) {
		a = a.concat(getLineControlPoints(data[i - 1], data[i], data[i + 1]))
	}
	dPath =
		'M' +
		data[0].x +
		',' +
		data[0].y +
		' Q' +
		a[0].x +
		',' +
		a[0].y +
		' ' +
		data[1].x +
		',' +
		data[1].y +
		''

	// 从二开始画曲线
	for (let i = 2; i < len - 1; i++) {
		const point = data[i]
		const x = point.x
		const y = point.y
		dPath +=
			' C' +
			a[(i - 2) * 2 + 1].x +
			',' +
			a[(i - 2) * 2 + 1].y +
			' ' +
			a[(i - 1) * 2].x +
			',' +
			a[(i - 1) * 2].y +
			' ' +
			x +
			',' +
			y +
			''
	}

	if (i > 1) {
		// /最后画贝塞尔曲线
		dPath +=
			' Q' +
			a[(i - 2) * 2 + 1].x +
			',' +
			a[(i - 2) * 2 + 1].y +
			',' +
			data[i].x +
			',' +
			data[i].y +
			''
	}
	const _dPath = dPath.split('Q')
	_dPath.pop()
	return isEnd ? _dPath.join('Q') : dPath
}