import { PPTAnimation } from '@/types/slides'

export const runCustomAnimation = (
	elRef: HTMLElement,
	animation: PPTAnimation,
	endStay = false,
	setTimeIdFunc?: (timeId: any) => void
) => {
	return new Promise<any>((resolve) => {
		let start: number | undefined = undefined
		let previousTimeStamp: number | undefined = undefined
		let done = false

		const { elId, duration, runTimes } = animation || {}
		if (!elId) {
			return
		}
		const animationPath = document.getElementById(
			`animation-custom-path-${elId}`
		)
		if (!animationPath) {
			return
		}
		const totalLengthPath = ((animationPath as unknown) as SVGPathElement).getTotalLength()
		const pathSpeed = totalLengthPath / duration
		const originPointStr = animationPath
			.getAttribute('d')
			?.split('L')?.[0]
			?.split(',')
		const originPoint = [0, 0]
		if (originPointStr) {
			originPoint[0] =
				+originPointStr[0].replaceAll(' ', '').replaceAll('M', '') || 0
			originPoint[1] = +originPointStr[1].replaceAll(' ', '') || 0
		}
		let timeId: any = undefined
		let currentIndex = 0

		const setAnimationTimeId = (timeId: any) => {
			setTimeIdFunc && setTimeIdFunc(timeId)
		}

		const endCustomPath = () => {
			currentIndex++
			if (!endStay) {
				elRef.style.removeProperty('transform')
			}
			if (currentIndex < (runTimes || 1)) {
				// elRef.style.removeProperty('transform')
				start = undefined
				previousTimeStamp = undefined
				done = false
				timeId = window.requestAnimationFrame(step)
				setAnimationTimeId(timeId)
			} else {
				resolve('')
			}
		}

		const step = (timestamp: number) => {
			if (start === undefined) {
				start = timestamp
			}
			const elapsed = timestamp - start
			if (previousTimeStamp !== timestamp) {
				const count = Math.min(pathSpeed * elapsed, totalLengthPath)
				const movePoint = ((animationPath as unknown) as SVGPathElement).getPointAtLength(
					pathSpeed * elapsed
				)
				elRef.style.transform = `translate(${movePoint?.x -
					originPoint[0]}px,${movePoint?.y - originPoint[1]}px)`
				if (count === totalLengthPath) done = true
			}
			if (elapsed < duration) {
				previousTimeStamp = timestamp
				if (!done) {
					timeId = window.requestAnimationFrame(step)
					setAnimationTimeId(timeId)
				} else {
					endCustomPath()
				}
			} else {
				endCustomPath()
			}
		}
		timeId = window.requestAnimationFrame(step)
		setAnimationTimeId(timeId)
	})
}
