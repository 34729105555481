// 16进制转RGB
export const toRGB = (hexColor: string) => {
	let sColor = hexColor.toLowerCase()
	// 十六进制颜色值的正则表达式
	const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
	const sColorChange = []
	if (sColor && reg.test(sColor)) {
		if (sColor.length === 4) {
			let sColorNew = '#'
			for (let i = 1; i < 4; i += 1) {
				sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
			}
			sColor = sColorNew
		}

		for (let i = 1; i < 7; i += 2) {
			sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
		}
	}
	return sColorChange
}

// RGB转16进制
export const toHexColor = (rgb: number[]) => {
	let strHex = '#'
	for (let i = 0; i < 3; i++) {
		let hex = Number(rgb[i]).toString(16)
		if (hex.length < 2) {
			hex = '0' + hex
		}
		strHex = strHex + hex
	}
	return strHex
}

// RGB转Hsl
export const rgbToHsl = (red: number, green: number, blue: number) => {
	const r = red / 255
	const g = green / 255
	const b = blue / 255

	const max = Math.max(r, g, b)
	const min = Math.min(r, g, b)
	let h
	let s
	const l = (max + min) / 2

	if (max === min) {
		h = s = 0 // achromatic
	} else {
		const d = max - min
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0)
				break
			case g:
				h = (b - r) / d + 2
				break
			case b:
				h = (r - g) / d + 4
				break
			default:
				return
		}
		h /= 6
	}
	return [h, s, l]
}

// Hsl转RGB
export const hslToRgb = (h: number, s: number, l: number) => {
	let r
	let g
	let b

	if (s === 0) {
		r = g = b = l // achromatic
	} else {
		const hue2rgb = (p: number, q: number, t: number) => {
			if (t < 0) t += 1
			if (t > 1) t -= 1
			if (t < 1 / 6) return p + (q - p) * 6 * t
			if (t < 1 / 2) return q
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
			return p
		}

		const q = l < 0.5 ? l * (1 + s) : l + s - l * s
		const p = 2 * l - q
		r = hue2rgb(p, q, h + 1 / 3)
		g = hue2rgb(p, q, h)
		b = hue2rgb(p, q, h - 1 / 3)
	}

	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}
