type GroupInterface = {
	[group: string]: {
		// eslint-disable-next-line @typescript-eslint/ban-types
		[key: string]: Function
	}
}
let uid = 1
export default class IframeProxy {
	iframe: HTMLIFrameElement
	// eslint-disable-next-line @typescript-eslint/ban-types
	handlers: Record<string, Function> | undefined
	interface: GroupInterface | undefined
	pending_cmds: Map<
		number,
		{ resolve: (value: unknown) => void; reject: (reason?: any) => void }
	>
	handle_event: (e: any) => void

	/**
	 * @param iframe iframe
	 * @param _handlers  注册postMessage方法
	 * @param _interface 注册window方法
	 */
	constructor(
		iframe: HTMLIFrameElement,
		// eslint-disable-next-line @typescript-eslint/ban-types
		_handlers: Record<string, Function> | undefined = undefined,
		// eslint-disable-next-line @typescript-eslint/ban-types
		onload: Function | undefined = undefined,
		_interface: GroupInterface | undefined = undefined
	) {
		this.iframe = iframe
		this.handlers = _handlers
		this.interface = _interface
		this.init(onload)
		this.registerInterface()
		this.pending_cmds = new Map()
		this.handle_event = (e) => this.handle_repl_message(e)
		window.addEventListener('message', this.handle_event, false)
	}
	// eslint-disable-next-line @typescript-eslint/ban-types
	init(onload: Function | undefined) {
		if (this.iframe && onload) {
			(this.iframe as any).onload = onload
		}
	}

	destroy() {
		this.logOffInterface()
		window.removeEventListener('message', this.handle_event)
	}

	registerInterface() {
		Object.entries(this.interface || {}).forEach(
			([key, value]: [string, any]) => {
				if (!(window as any)[key]) {
					(window as any)[key] = {}
				}
				(window as any)[key][this.iframe.id] = value
			}
		)
	}

	logOffInterface() {
		Object.entries(this.interface || {}).forEach(([key]: [string, any]) => {
			if ((window as any)[key]) {
				delete (window as any)[key][this.iframe.id]
			}
		})
	}

	handle_repl_message(event: any) {
		if (event.source !== this.iframe.contentWindow) {
			return
		}

		const { type, data } = event.data
		if (this.handlers?.[type]) {
			const func = this.handlers[type]
			return func(data)
		}
	}

	iframe_command(type: string, data: any, iframeUrl?: string) {
		return new Promise((resolve, reject) => {
			const cmd_id = uid++

			this.pending_cmds.set(cmd_id, { resolve, reject })

			this.iframe.contentWindow?.postMessage({ type, cmd_id, data }, '*')
		})
	}
}
