import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, unref as _unref, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-4c08ceed")
const _hoisted_1 = {
  key: 0,
  class: "select-wrap"
}
const _hoisted_2 = { class: "selector" }
const _hoisted_3 = { class: "icon" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "selector" }
const _hoisted_6 = { class: "icon" }
_popScopeId()

import {
	computed,
	onMounted,
	onUnmounted,
	ref,
	withDefaults
} from 'vue'
import Popover from './Popover.vue'

interface SelectOption {
	label: string
	value: string | number
	disabled?: boolean
}


export default _defineComponent({
  props: {
    value: { type: [String, Number], required: true },
    options: { type: Array, required: true },
    popoverAppendTo: { type: [String, null], required: false },
    disabled: { type: Boolean, required: false, default: false }
  } as unknown as undefined,
  emits: ["update:value"] as unknown as undefined,
  setup(__props: {
		value: string | number
		options: SelectOption[]
		popoverAppendTo?: string | HTMLElement | 'parent'
		disabled?: boolean
	}, { emit }: { emit: ({
	(event: 'update:value', payload: string | number): void
}), expose: any, slots: any, attrs: any }) {

const props = __props


const showLabel = computed(() => {
	return (
		props.options.find((item) => item.value === props.value)?.label ||
		props.value
	)
})



const popoverVisible = ref(false)
const selectRef = ref<HTMLElement>()
const width = ref(0)

const updateWidth = () => {
	if (!selectRef.value) return
	width.value = selectRef.value.clientWidth
}
const resizeObserver = new ResizeObserver(updateWidth)
onMounted(() => {
	if (!selectRef.value) return
	resizeObserver.observe(selectRef.value)
})
onUnmounted(() => {
	if (!selectRef.value) return
	resizeObserver.unobserve(selectRef.value)
})

const handleSelect = (option: SelectOption) => {
	if (option.disabled) return
	popoverVisible.value = false
	setTimeout(() => {
		emit('update:value', option.value)
	}, 0)
}

return (_ctx: any,_cache: any) => {
  const _component_IconDown = _resolveComponent("IconDown")!

  return (__props.disabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "select disabled",
          ref: selectRef
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(__props.value), 1),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "icon", {}, () => [
              _createVNode(_component_IconDown, { size: 14 })
            ])
          ])
        ], 512)
      ]))
    : (_openBlock(), _createBlock(Popover, {
        key: 1,
        class: "select-wrap",
        trigger: "click",
        value: popoverVisible.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (popoverVisible.value = $event)),
        placement: "bottom",
        appendTo: __props.popoverAppendTo,
        contentStyle: {
			padding: 0,
			boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08)'
		}
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", {
            class: "options",
            style: _normalizeStyle({ width: width.value + 2 + 'px' })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["option", {
						disabled: option.disabled,
						selected: option.value === __props.value
					}]),
                key: option.value,
                onClick: ($event: any) => (handleSelect(option))
              }, _toDisplayString(option.label), 11, _hoisted_4))
            }), 128))
          ], 4)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "select",
            ref: selectRef
          }, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(showLabel)), 1),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "icon", {}, () => [
                _createVNode(_component_IconDown, { size: 14 })
              ])
            ])
          ], 512)
        ]),
        _: 3
      }, 8, ["value", "appendTo", "contentStyle"]))
}
}

})