import _ from 'lodash'

/**
 * 生成随机码
 * @param len 随机码长度
 */
export const createRandomCode = (len = 6) => {
	const charset = `_0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz`
	const maxLen = charset.length
	let ret = ''
	for (let i = 0; i < len; i++) {
		const randomIndex = Math.floor(Math.random() * maxLen)
		ret += charset[randomIndex]
	}
	return ret
}

/**
 * 补足数字位数
 * @param digit 数字
 * @param len 位数
 */
export const fillDigit = (digit: number, len: number) => {
	return _.padStart('' + digit, len, '0')
}

/**
 * 将对象转成小写头峰
 * @param obj
 * @param newObj
 * @returns
 */
export const toCameCaseObj = (obj: Record<string, any>, newObj = {}) => {
	if (typeof obj !== 'object') {
		return {}
	}

	Object.entries(obj).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			const newArray: any[] = []
			value.forEach(([, v]) => {
				newArray.push(toCameCaseObj(v))
			})
			newObj[_.camelCase(key)] = newArray
		} else {
			if (typeof value === 'object' && value !== null && value !== undefined) {
				newObj[_.camelCase(key)] = toCameCaseObj(value)
			} else {
				newObj[_.camelCase(key)] = value
			}
		}
	})
	return newObj
}

/**
 * 将ascii编码字符串转成中文
 * @param asciiString
 * @returns 
 */
export const asciiToUtf8 = (asciiString: string) => {
	// 创建一个TextDecoder实例，指定编码为'utf-8'
	const decoder = new TextDecoder('utf-8')

	// 使用TextDecoder解码ASCII字符串
	const utf8String = decoder.decode(
		new Uint8Array(asciiString.split('').map((char) => char.charCodeAt(0)))
	)

	return utf8String
}
