import { PptJson, Slide } from '@/types/slides'
import logger from '@evideo/logger'
import { getSlideSize, getSlideSizePer } from './attribute'
import getSlide from './slide'
import getTheme from './theme'

export interface PptxToJsonOption {
	upload: any
	beforeParse?: (pptxFile: any, pptJson: any) => void
	afterParse?: (pptxFile: any, pptJson: any) => void
	beforeParseSlide?: (
		slideFile: any,
		slide: any,
		index: number,
		total: number
	) => void
	afterParseSlide?: (
		slideFile: any,
		slide: any,
		index: number,
		total: number
	) => void
	beforeParseElement?: (
		elmentFile: any,
		element: any,
		index: number,
		total: number
	) => void
	afterParseElement?: (
		elmentFile: any,
		element: any,
		index: number,
		total: number
	) => void
}

const changeSlideSize = async (
	pptxFile: any,
	slides: Slide[],
	viewportRatio: number
) => {
	const slideSize = await getSlideSize(pptxFile, 'ppt/presentation.xml')
	const defaultCanvasSize = {
		width: 1920,
		height: viewportRatio === 0.5625 ? 1080 : 1080 / viewportRatio
	}
	if (slideSize) {
		// 放大比例
		const per = Math.max(
			slideSize.width / defaultCanvasSize.width,
			slideSize.height / defaultCanvasSize.height
		)

		if (per !== 1) {
			// ppt每页放大后宽高
			// const currentSlideWidth =
			// 	slideSize.width / per > defaultCanvasSize.width
			// 		? defaultCanvasSize.width
			// 		: slideSize.width / per
			// const currentSlideHeight =
			// 	slideSize.height / per > defaultCanvasSize.height
			// 		? defaultCanvasSize.height
			// 		: slideSize.height / per
			// 居中偏移量
			// const widthOffset = (defaultCanvasSize.width - currentSlideWidth) / 2
			// const heightOffset = (defaultCanvasSize.height - currentSlideHeight) / 2

			slides.forEach((slide) => {
				slide.elements.forEach((element) => {
					element.left = element.left / per
					element.top = element.top / per
					// 音频控件宽高固定，不放大或缩小
					if (!(element.type === 'elf' && element.subtype === 'elf-audio')) {
						element.width = element.width / per
					}

					if (element.type === 'elf') {
						if (element.subtype !== 'elf-audio') {

							element.height = element?.height / per
						}
					} else {
						if (element.type !== 'line') {
							element.height = element?.height / per
						}
					}
				})
			})
		}
	}
	return slides
}

export default async (pptxFile: any, options: PptxToJsonOption) => {
	const pptJson: PptJson = {
		slides: [],
		theme: {
			themeColor: '#5b9bd5',
			fontColor: '#333',
			fontName: 'Sans-serif',
			fontSize: '40px',
			backgroundColor: '#fff',
			viewportRatio: 0.5625
		}
	}
	try {
		logger.time(`ppt-file parse time`)
		options.beforeParse && options.beforeParse(pptxFile, pptJson)
		const theme = await getTheme(pptxFile)
		pptJson.theme = theme
		await getSlideSizePer(pptxFile, pptJson.theme.viewportRatio)
		let slides = await getSlide(pptxFile, options)
		slides = await changeSlideSize(
			pptxFile,
			slides,
			pptJson.theme.viewportRatio
		)
		pptJson.slides = slides
		logger.timeEnd(`ppt-file parse time`)
		logger.info('fininsh parse ppt', pptJson)
		options.beforeParse && options.beforeParse(pptxFile, pptJson)
		return pptJson
	} catch (e:any) {
		throw new Error(e.message)
	}
}
