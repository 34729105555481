
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
  createVNode,
  onUnmounted,
  nextTick,
  markRaw,
} from "vue";
import { MutationTypes, useStore } from "@/store";
import useDynamicTabs from "./hooks/useDynamicTabs";
import {
  FileTextTwoTone,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import useResourceCenter from "@/hooks/useResourceCenter";
import useRunTime from "@/hooks/useRunTime";
import xgplayer from "@/utils/xgplayer";
import { PptState } from "@/types/pptState";
import emitter, { EmitterEvents } from "@/utils/emitter";
export default defineComponent({
  name: "speakLesson-panel",
  components: {
    FileTextTwoTone,
  },
  props: {
    onMountCloseSecondToolBar: {
      type: Function,
    },
  },
  setup(props) {
    const { runtime, configRunTime } = useRunTime();
    const store = useStore();
    const screening = computed(() => store.state.screening);
    const pptid = computed<number | undefined>(
      () => runtime.extData?.coursewareId
    );
    const pptsource = computed<string | undefined>(
      () => runtime.extData?.coursewareSource
    );

    const {
      getNewPptDetails,
      openLessonOrSpeakModal,
      cancelLessonOrSpeakRelate,
    } = useDynamicTabs();
    const { getCoursewareDetail } = useResourceCenter();

    // 获取到的说课列表
    const speakList: any = ref([]);
    const speaksrc: Ref<string> = ref("");

    const previewVideo = ref<HTMLDivElement>();

    const player = ref<any>();

    const xgplayerOption = ref<any>({
      url: speaksrc.value,
    });

    const Allcoursewares = ref<any>();

    // 弹窗方法(关联说课)
    const openAllSpeak = () => {
      openLessonOrSpeakModal("speak", runtime.extData).then((data: any) => {
        if (data?.coursewareId !== 0 && data?.coursewareId) {
          if (data.status_code === 0) {
            runtime.extData = {
              ...(runtime.extData || {}),
              coursewareId: data.coursewareId,
              coursewareSource: data.coursewareSource,
            };
            configRunTime(runtime);
            getSpeakList(pptid.value, pptsource.value);
          }
        } else {
          getSpeakList(pptid.value, pptsource.value);
        }
        store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY);
      });
    };

    // 说课详情
    const getSpeakDetail = async (id: number, source: string | undefined) => {
      const speakDetails = await getCoursewareDetail(id, source);
      if (!speaksrc.value) {
        init();
      }
      speaksrc.value = speakDetails.data.url;
    };

    const cancelRelate = (id: number) => {
      Modal.confirm({
        title: "提示",
        icon: createVNode(ExclamationCircleOutlined),
        content: "是否确认取消关联该说课？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          speaksrc.value = "";
          player.value && player.value.src && player.value.destroy();

          const relate_courseware_ids = Allcoursewares.value.reduce(
            (ids: any, currentValue: any) => {
              if (currentValue.id !== id) {
                ids.push(currentValue.id);
              }
              return ids;
            },
            []
          );
          cancelLessonOrSpeakRelate(
            "speak",
            relate_courseware_ids,
            runtime.extData
          ).then((data: any) => {
            if (data?.coursewareId !== 0 && data?.coursewareId) {
              if (data.status_code === 0) {
                getSpeakList(pptid.value, pptsource.value);
              }
            } else {
              getSpeakList(pptid.value, pptsource.value);
            }
            store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY);
          });
        },
      });
    };

    // 获取该PPT的说课列表
    const getSpeakList: any = async (
      id: number,
      source: string | undefined
    ) => {
      const res =
        id !== 0
          ? await getCoursewareDetail(id, source)
          : await getNewPptDetails("speak").then((data) => {
              return data;
            });
      Allcoursewares.value = res.data.relate_coursewares;

      speakList.value = Allcoursewares.value.filter(
        (item: any) => item.type === "speak"
      );
      // 起始时，若无数据，收起二级面板
      props.onMountCloseSecondToolBar &&
        props.onMountCloseSecondToolBar(speakList.value.length > 0);
    };

    const init = () => {
      nextTick(() => {
        player.value = xgplayer(
          previewVideo.value!,
          markRaw(xgplayerOption.value)
        );
      });
    };

    const updateList = () => {
      speakList.value = [];
      player.value && player.value.src && player.value.destroy();
      speaksrc.value = "";
      getSpeakList(pptid.value, pptsource.value);
    };

    onMounted(() => {
      emitter.on(EmitterEvents.ON_LOAD_PPT, updateList);
      getSpeakList(pptid.value, pptsource.value);
    });

    onUnmounted(() => {
      emitter.off(EmitterEvents.ON_LOAD_PPT, updateList);
    });

    watch(
      () => speaksrc.value,
      (newVal) => {
        if (!newVal) {
          return;
        }
        xgplayerOption.value.url = newVal;
        if (player.value && player.value.src) {
          player.value.src = newVal;
          player.value.reload();
        } else {
          init();
        }
      }
    );

    return {
      previewVideo,
      pptid,
      speaksrc,
      openAllSpeak,
      cancelRelate,
      getSpeakDetail,
      speakList,
      screening,
      pptsource,
    };
  },
});
