import Player from 'xgplayer'
import { VideoPlayer } from '@/utils/xgplayer'

export default function (this: Player) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias,consistent-this
  const player = this as VideoPlayer
  if (!player.config.clip) {
    return
  }
  console.debug('xgplayer install plugin s_clip')

  // 去除原有的video元素的循环播放，采用自己控制
  player.video.loop = false
  player.videoConfig.loop = false
  Object.defineProperty(player, 'loop', {
    get: () => player.config.loop,
    set: (value: boolean) => player.config.loop = value
  })

  const clipStart = () => {
    if (player.config.clipStart) {
      return player.config.clipStart
    }
    if (player.config.getClipStart) {
      return player.config.getClipStart()
    }
    return 0
  }
  const clipEnd = () => {
    if (player.config.clipEnd) {
      return player.config.clipEnd
    }
    if (player.config.getClipEnd) {
      return player.config.getClipEnd()
    }
    return player.duration * 1000
  }

  Object.defineProperty(player, 'clipStart', {
    get: () => clipStart()
  })
  Object.defineProperty(player, 'clipEnd', {
    get: () => clipEnd()
  })


  // 重写播放
  player.__start__ = player.start
  player.start = function (...args: any[]) {
    player.currentTime = player.clipStart / 1000 || 0
    player.__start__(...args)
  }

  // 重写回放
  player._replay = function () {
    player.currentTime = player.clipStart! / 1000 || 0
    // eslint-disable-next-line handle-callback-err
    const playPromise = player.play()
    if (playPromise !== undefined && playPromise) {
      playPromise.catch()
    }
  }

  // 重写结束

  const onClipTimeUpdate = () => {
    const clipEnd = player.clipEnd || player.duration * 1000
    const currentTime = player.currentTime * 1000
    if (currentTime >= clipEnd) {
      player.emit('ended')
    }
  }
  const onClipTimeEnd = () => {
    if (player.config.loop) {
      player.currentTime = player.clipStart / 1000 || 0
      return
    }
    player.pause()
  }


  // 重写时间标识
  const onTimeChange = () => {
    const root = player.root as HTMLDivElement
    const clipStart = player.clipStart || 0
    const clipEnd = player.clipEnd || player.duration * 1000
    const xgTime = root.querySelector('xg-time')
    if (xgTime && (clipStart || clipEnd)) {
      if (player.videoConfig.mediaType !== 'audio' || !player.isProgressMoving || !player.dash) {
        const currentTime = (player.currentTime - (clipStart / 1000 || 0)) || 0
        const duration = (clipEnd - clipStart) / 1000
        xgTime.innerHTML = `<span class="xgplayer-time-current">${Player.util.format(currentTime)}</span>` + `<span>${Player.util.format(duration)}</span>`
      }
    }
  }


  player.on('ended', onClipTimeEnd)
  player.on('durationchange', onTimeChange)
  player.on('timeupdate', onTimeChange)
  player.on('timeupdate', onClipTimeUpdate)


  function onDestroy() {
    player.off('ended', onClipTimeEnd)
    player.off('durationchange', onTimeChange)
    player.off('timeupdate', onTimeChange)
    player.off('timeupdate', onClipTimeUpdate)
    player.off('destroy', onDestroy)
  }

  player.once('destroy', onDestroy)

  Object.defineProperty(player, 'ended', {
    get: () => {
      if (player.video) {
        const clipEnd = player.clipEnd
        const currentTime = player.currentTime * 1000
        if (clipEnd) {
          return currentTime >= clipEnd
        }
        return player.video.ended || false
      }
      return true
    }
  })
  Object.defineProperty(player, 'clipDuration', {
    get: () => {
      if (player.clipEnd && player.clipStart) {
        return player.clipEnd - player.clipStart
      }
    }
  })
  Object.defineProperty(player, 'clipCurrentTime', {
    get: () => {
      if (player.currentTime && player.clipStart) {
        return player.currentTime - player.clipStart
      }
    }
  })
}