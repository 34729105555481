import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-106bb024")
const _hoisted_1 = { class: "scroll-container" }
const _hoisted_2 = {
  class: "scroll-wrapper",
  ref: "scrollEl"
}
const _hoisted_3 = {
  class: "scroll-content",
  ref: "scrollContentEl"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconLeft = _resolveComponent("IconLeft")!
  const _component_IconRight = _resolveComponent("IconRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isScroll)
      ? _renderSlot(_ctx.$slots, "left-arrow", { key: 0 }, () => [
          _createElementVNode("div", {
            class: "left-arrow",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollTo(100, 0)))
          }, [
            _createVNode(_component_IconLeft)
          ])
        ], {}, true)
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 512)
    ], 512),
    (_ctx.isScroll)
      ? _renderSlot(_ctx.$slots, "right-arrow", { key: 1 }, () => [
          _createElementVNode("div", {
            class: "right-arrow",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.scrollTo(-100, 0)))
          }, [
            _createVNode(_component_IconRight)
          ])
        ], {}, true)
      : _createCommentVNode("", true)
  ]))
}