export const enum MutationTypes {
	// reset  微应用退出没有删除，或者说进入没有清空
	RESET = 'reset',

	// token
	SET_TOKEN = 'setToken',

	// uuid
	SET_UUID = 'setUuid',

	SET_PPT_STATE = 'setPptState',

	// editor
	SET_ACTIVE_ELEMENT_ID_LIST = 'setActiveElementIdList',
	SET_HANDLE_ELEMENT_ID = 'setHandleElementId',
	SET_ACTIVE_GROUP_ELEMENT_ID = 'setActiveGroupElementId',
	SET_CANVAS_PERCENTAGE = 'setCanvasPercentage',
	SET_CANVAS_SCALE = 'setCanvasScale',
	SET_THUMBNAILS_FOCUS = 'setThumbnailsFocus',
	SET_EDITORAREA_FOCUS = 'setEditorAreaFocus',
	SET_DISABLE_HOTKEYS_STATE = 'setDisableHotkeysState',
	SET_GRID_LINES_STATE = 'setGridLinesState',
	SET_CREATING_ELEMENT = 'setCreatingElement',
	SET_AVAILABLE_FONTS = 'setAvailableFonts',
	SET_TOOLBAR_STATE = 'setToolbarState',
	SET_CLIPING_IMAGE_ELEMENT_ID = 'setClipingImageElementId',
	SET_RICHTEXT_ATTRS = 'setRichTextAttrs',
	SET_SELECTED_TABLE_CELLS = 'setSelectedTableCells',
	SET_SCALING_STATE = 'setScalingState',
	SET_EDITING_SHAPE_ELEMENT_ID = 'setEditingShapeElementId',

	// slides
	SET_THEME = 'setTheme',
	SET_VIEWPORT_RATIO = 'setViewportRatio',
	SET_SLIDES = 'setSlides',
	ADD_SLIDE = 'addSlide',
	UPDATE_SLIDE = 'updateSlide',
	UPDATE_SLIDE_PREVIEW = 'updateSlidePreview',
	DELETE_SLIDE = 'deleteSlide',
	UPDATE_SLIDE_INDEX = 'updateSlideIndex',
	UPDATE_SELECTED_SLIDES_INDEX = 'updateSelectedSlidesIndex',
	ADD_ELEMENT = 'addElement',
	UPDATE_ELEMENT = 'updateElement',
	DELETE_ELEMENT = 'deleteElement',
	REMOVE_ELEMENT_PROPS = 'removeElementProps',
	REMOVE_ELEMENT_PROPS_IN_SLIDES = 'removeElementPropsInSlides',

	// snapshot
	SET_SNAPSHOT_CURSOR = 'setSnapshotCursor',
	SET_SNAPSHOT_LENGTH = 'setSnapshotLength',

	// keyboard
	SET_CTRL_KEY_STATE = 'setCtrlKeyState',
	SET_SHIFT_KEY_STATE = 'setShiftKeyState',

	// screen
	SET_SCREENING = 'setScreening',
	SET_EDITING_FULL_SCREENING = 'SET_EDITING_SCREENING',
	SET_ANIMATION_INDEX = 'setAnimationIndex',
	SET_NEXT_ACTION_STEP_INDEX = 'SET_NEXT_ACTION_STEP_INDEX',
	SET_NEXT_ACTION_PLAYED_IDS = 'SET_NEXT_ACTION_PLAYED_IDS',
	SET_SHOW_SCREEN_CONTROLS = 'SET_SHOW_SCREEN_CONTROLS',

	// topiframe
	SET_TOP_IFRAME = 'setTopIframe',

	// load ppt
	LOAD_PPT = 'loadPPT',
	SET_LOADPNPPTSIGN = 'setLoadPNPptSign',

	// page loading
	PAGE_LOADING = 'pageLoading',
	PAGE_LOADING_TEXT = 'pageLoadingText'
}

export const enum ActionTypes {
	INIT_SNAPSHOT_DATABASE = 'initSnapshotDatabase',
	DELETE_LAST_SNAPSHOT = 'deleteLastSnapshot',
	ADD_SNAPSHOT = 'addSnapshot',
	UN_DO = 'undo',
	RE_DO = 'redo'
}
