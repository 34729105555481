import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1d383956")
const _hoisted_1 = ["accept"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "file-input",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClick()))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("input", {
      class: "input",
      type: "file",
      name: "upload",
      ref: "inputRef",
      accept: _ctx.accept,
      onChange: _cache[0] || (_cache[0] = $event => _ctx.handleChange($event))
    }, null, 40, _hoisted_1)
  ]))
}