import { PPTElfVideoElement } from '@/types/slides'
import { createRandomCode } from '@/utils/common'
import logger from '@evideo/logger'
import _ from 'lodash'
import { getPosition } from '../../attribute'

const noSupportedVideoType = ['avi', 'mpg']

const getVideoData = async (
	slideIndex: number,
	media: any,
	slideRelsObject: any,
	pptxFiles: any,
	upload: any
) => {
	const linkId = media.attributes['r:link']
	if (!linkId) {
		return
	}
	const relationships = slideRelsObject.Relationships.Relationship || []

	const videoRels = relationships.find((r: any) => r.attributes.Id === linkId)
	// 兼容视频地址为本地的情况，地址为本地不解析
	if (videoRels.attributes?.TargetMode === 'External') {
		return 'external'
	}
	if (!videoRels) {
		return
	}

	const videoPath = videoRels.attributes.Target
	const video = pptxFiles[`ppt${videoPath.replace('..', '')}`]

	if (!video) {
		return
	}
	try {
		const videoArraybuffer = await video.async('arraybuffer')
		const videoTypeArray = video.name.split('.')
		const videoType = `video/${videoTypeArray[videoTypeArray.length - 1]}`

		if (noSupportedVideoType.indexOf(videoTypeArray[videoTypeArray.length - 1]) !== -1) {
			return 'noSupportVideoType'
		}
		const videoBlob = new Blob([videoArraybuffer], { type: videoType })

		const videoName = video.name.split('/').reverse()[0]
		const videoFile = new File([videoBlob], videoName, { type: videoType })

		logger.time(`ppt-file parse video:(${videoName}) upload time`)
		const videoUploadData = await upload(videoFile)
		logger.timeEnd(`ppt-file parse video:(${videoName}) upload time`)

		videoUploadData.name = videoName.split('.')[0]
		return videoUploadData
	} catch (e) {
		logger.error(`ppt-file parse video upload failed`, e)
		throw new Error(`第${slideIndex}页PPT 视频上传失败，请重试`)
	}
}
export default async (
	slideIndex: number,
	media: any,
	pic: any,
	slideRelsObject: any,
	pptxFiles: any,
	upload: any
) => {
	const videoElement: PPTElfVideoElement = {
		type: 'elf',
		subtype: 'elf-video',
		id: createRandomCode(),
		left: 0,
		top: 0,
		width: 0,
		height: 0,
		name: '',
		src: '',
		poster: '',
		videoSize: 0,
		posterSize: 0,
	    editPreview: true,
	}

	const videoData = await getVideoData(
		slideIndex,
		media,
		slideRelsObject,
		pptxFiles,
		upload
	)
	// 兼容视频地址为本地的情况，地址为本地不解析
	if (videoData === 'external') {
		return 'external'
	}
	if (videoData === 'noSupportVideoType') {
		return 'noSupportVideoType'
	}

	if (videoData) {
		videoElement.src = videoData.url
		videoElement.videoSize = videoData.size
		videoElement.md5 = videoData.md5
		videoElement.name = videoData.name
	}

	if (!videoElement.src) {
		return
	}
	const position = await getPosition(pic, slideRelsObject, pptxFiles)
	_.merge(videoElement, position)

	return videoElement
}
