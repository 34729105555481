import { createApp, App as VueApp } from 'vue'

import '@icon-park/vue-next/styles/index.css'
import 'prosemirror-view/style/prosemirror.css'

import '@/assets/styles/antd.scss'
import '@/assets/styles/font.scss'
import '@/assets/styles/global.scss'
import '@/assets/styles/prosemirror.scss'

import Component from '@/plugins/component'
import Directive from '@/plugins/directive'
import Icon from '@/plugins/icon'

import useTeachingTabPage from '@/hooks/useTeachingTabPage'
import logger from '@evideo/logger'
import App from './App.vue'
import useAntDesign from './hooks/useAntDesign'
import { initTeachingPen } from './hooks/useGlobalPencilNext'
import useQianKun from './hooks/useQianKun'
import useRunTime from './hooks/useRunTime'
import useUpLoadResource from './hooks/useUpLoadResource'
import router from './router'
import { key, MutationTypes, store } from './store'

export * from './hooks/useQianKun' // 导出QianKun 生命周期

let app: VueApp

const initSheetLibResult = window.initSheetLib()
// 渲染函数
const render = async (props: any = {}) => {
	logger.setNamespace('魔欢PPT')
	const { container } = props
	const { setDocument } = useQianKun()
	const { configRunTime } = useRunTime()
	isMicroApp && setDocument(container)
	try {
		await initSheetLibResult
	} catch (e) {
		console.error('initSheetLib error', e)
	}

	if (props?.args?.runtime) {
		configRunTime(props?.args?.runtime)
	}

	app = createApp(App)
		.use(router)
		.use(store, key)
	app.use(Icon)
	app.use(Component)
	app.use(Directive)
	useAntDesign(app) // 注册ant-design组件

	if (props.screening !== undefined && props.screenType !== undefined) {
		store.commit(MutationTypes.SET_SCREENING, {
			screening: props.screening,
			screenType: props.screenType,
			editMode: props.editMode
		})
	}
	app.mount(container ? container.querySelector('#ppt') : '#ppt')
	logger.debug('ppt mount success :', JSON.stringify(props?.args?.runtime))
}

const destroyedAll = () => {
	const loadingEls =
		document.querySelectorAll('.global-loading-container') || []
	loadingEls.forEach((el) => {
		el.remove()
	})
}
// 初始化QianKun
const { isMicroApp } = useQianKun({
	mount: async (props: any = {}) => {
		window.__MH_PPT__ = true
		await render(props)
	},
	unmount: () => {
		window.__MH_PPT__ = false
		const { destroyOssResource } = useUpLoadResource()
		destroyOssResource()
		destroyedAll()
		store.commit(MutationTypes.RESET)
		app.unmount()
		app._container.innerHTML = ''
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		app = null
	}
})

const { isPowerByTabPage, receiveHostMessage } = useTeachingTabPage()

// 由多标签页启动则注册onload监听器接收数据并render，否则判断，没有在QianKun就默认渲染
isPowerByTabPage
	? receiveHostMessage('onload', (e, data) => {
			setTimeout(() => {
				logger.debug('ppt receive onload data:', JSON.stringify(data))
			}, 100)
			window.__MH_PPT__ = true
			const style = document.createElement('style')
			style.innerHTML = `
        html, body {
            background-color: transparent !important;
        }
         .pptist-screen {
           background-color: transparent !important;
        }
        `
			document.head.appendChild(style)
			initTeachingPen(data.penOpened || false, data.penDrawMode || false)
			render({
				args: {
					runtime: data.runtime
				}
			})
	  })
	: !isMicroApp && render()
