
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "@/store";
import {
  CheckCircleOutlined,
  CloseOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons-vue";
import useMediaTranscode, {
  MediaTranscodeListItem,
} from "@/hooks/useMediaTranscode";
import logger from "@evideo/logger";
import _ from "lodash";

export default defineComponent({
  name: "ppt-footer",
  components: {
    CheckCircleOutlined,
    CloseOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
  },
  setup() {
    const store = useStore();
    const slides = computed(() => store.state.slides);
    const slideIndex = computed(() => store.state.slideIndex);
    const { mediaTranscodeList, handleMediaCancel } = useMediaTranscode();

    const stateTo = {
      doing: ["转换中", "active"],
      fail: ["转换失败", "exception"],
      ready: ["等待中", "normal"],
      success: ["转换成功", "success"],
    };
    const currentStateIcon = (type: string) => {
      const currentStateTypeMap = {
        success: {
          comp: CheckCircleOutlined,
          color: "##29d19e",
        },
        fail: { comp: CloseCircleOutlined, color: "red" },
        ready: { comp: ClockCircleOutlined, color: "grey" },
      };
      return currentStateTypeMap[type] || null;
    };

    const showResourceList = ref(false);
    const resourceList = computed(() => {
      const list: any = [];
      mediaTranscodeList.value.forEach((item: MediaTranscodeListItem) => {
        const _size =
          item.size >= 1024 * 1024
            ? (item.size / 1024 / 1024).toFixed(2) + "MB"
            : (item.size / 1024).toFixed(2) + "KB";
        let _stateCN = stateTo[item.state][0];
        if (item?.state === "doing" && item.mode === "upload") {
          _stateCN = "上传中";
        }
        const slideIndex = item?.slideId
          ? slides.value.findIndex((slide) => slide.id === item.slideId)
          : undefined;
        list.push({
          ...item,
          originSize: _size,
          stateCN: _stateCN,
          slideIndex,
          progressState: stateTo[item.state][1],
        });
      });
      return list;
    });
    const getMoreToolsPopupContainer = (triggerNode: HTMLElement) => {
      return triggerNode.parentNode;
    };
    const isAllSuccess = computed(() => {
      let success = true;
      for (let i = 0; i < resourceList.value.length; i++) {
        if (resourceList.value[i].state !== "success") {
          success = false;
        }
      }
      return success;
    });
    const successNum = computed(() => {
      let num = 0;
      for (let i = 0; i < resourceList.value.length; i++) {
        if (resourceList.value[i].state === "success") {
          num++;
        }
      }
      return num;
    });
    const showCurrentProgress = computed(() => {
      const currentTranscodingTask = mediaTranscodeList.value.find(
        (item: MediaTranscodeListItem) => item.state === "doing"
      );
      if (currentTranscodingTask) {
        return currentTranscodingTask.progress;
      }
      return undefined;
    });
    const deleteTransList = _.debounce(async (id: string) => {
      try {
        await handleMediaCancel(id);
      } catch (e) {
        logger.error("删除资源转码上传失败：", (e as any)?.message);
      }
    }, 100);
    watch(
      () => resourceList.value.length,
      () => {
        setTimeout(() => {
          showResourceList.value = true;
        }, 10);
      }
    );
    return {
      showResourceList,
      slides,
      slideIndex,
      resourceList,
      getMoreToolsPopupContainer,
      isAllSuccess,
      successNum,
      deleteTransList,
      showCurrentProgress,
      currentStateIcon,
    };
  },
});
