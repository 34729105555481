import { ToolbarStates } from '@/types/toolbar'
export type ShowTabType = 'nothing' | 'multi' | 'click' // 没有点击 | 多选 | 当前点击

export const ShowTabTypes = {
	NOTHING: 'nothing',
	MULTI: 'multi',
	CLICK: 'click'
}

export interface ToolBarTab {
	id: number // 0为一级标签，依次累加
	parentId: number // 父级id值;
	name: string // 名称
	tabType: string // 标签类型
	state?: boolean // 状态值用于选中
	keepAlive?: boolean // '是否保持状态',
	comp?: any // 所采用的组件，若是一级，有二级数据，渲染时自动忽略 ; 有下一级数据时为空，没有下一级数据时填充对应内容组件
	showType?: string[] // 条件类型，如是没有点击/多选元素/当前点击元素 ; 无则默认三种条件下都显示
	elementTypes?: string[] // 正在处理的PPT元素，才显示，取决于showType
}

const toolbarsTabs: ToolBarTab[] = [
	{
		id: 1,
		parentId: 0,
		name: '属性',
		tabType: 'attributes'
	},
	{
		id: 2,
		parentId: 0,
		name: '动画',
		showType: [ShowTabTypes.CLICK, ShowTabTypes.NOTHING],
		tabType: ToolbarStates.EL_ANIMATION
	},
	{
		id: 3,
		parentId: 1,
		name: '设计',
		showType: [ShowTabTypes.NOTHING],
		tabType: ToolbarStates.SLIDE_DESIGN
	},
	{
		id: 4,
		parentId: 1,
		name: '切换',
		showType: [ShowTabTypes.NOTHING],
		tabType: ToolbarStates.SLIDE_ANIMATION
	},
	{
		id: 5,
		parentId: 1,
		name: '排版',
		showType: [ShowTabTypes.MULTI],
		tabType: ToolbarStates.MULTI_POSITION
	},
	{
		id: 6,
		parentId: 1,
		name: '样式',
		showType: [ShowTabTypes.MULTI, ShowTabTypes.CLICK],
		tabType: ToolbarStates.EL_STYLE,
		elementTypes: [
			'shape',
			'text',
			'image',
			'elf-audio',
			'elf-video',
			'line',
			'table',
			'chart',
			'elf-sing-play',
			'elf-carouse-image',
			'elf-questions-bank',
			'elf-emsemble-practice',
			'elf-pk-questions',
		]
	},
	{
		id: 7,
		parentId: 1,
		name: '排版',
		showType: [ShowTabTypes.CLICK],
		tabType: ToolbarStates.EL_POSITION
	}
]

export default toolbarsTabs
