import { GetterTree } from 'vuex'
// import tinycolor from 'tinycolor2'
import {
	FormatedAnimation,
	PPTAnimation,
	TriggeredAnimation
} from '@/types/slides'
import { State } from './state'
// import { layouts } from '@/mocks/layout'
import { v4 as uuidv4 } from 'uuid'
import SnapshotDatabase from '@/utils/database'

export const getters: GetterTree<State, State> = {
	currentSlide(state) {
		return state.slides[state.slideIndex] || null
	},

	currentSlideAnimations(state) {
		const currentSlide = state.slides[state.slideIndex]
		if (!currentSlide) return []
		const animations = currentSlide.animations
		if (!animations) return []

		const els = currentSlide.elements
		const elIds = els.map((el) => el.id)
		return animations.filter((animation) => elIds.includes(animation.elId))
	},
	// 格式化的当前页动画
	// 将触发条件为“与上一动画同时”的项目向上合并到序列中的同一位置
	// 为触发条件为“上一动画之后”项目的上一项添加自动向下执行标记
	formatedAnimations(state) {
		const currentSlide = state.slides[state.slideIndex]
		if (!currentSlide?.animations) return []

		const els = currentSlide.elements
		const elIds = els.map((el) => el.id)
		const animations = currentSlide.animations.filter((animation) =>
			elIds.includes(animation.elId)
		)

		const formatedAnimations: FormatedAnimation[] = []
		for (const animation of animations) {
			animation.id = animation.id || uuidv4()
			animation.trigger = animation.trigger || 'click'
			animation.group = animation.group || 'in'
			if (animation.trigger === 'click' || !formatedAnimations.length) {
				formatedAnimations.push({
					animations: [animation],
					autoNext: false
				})
			} else if (animation.trigger === 'meantime') {
				const last = formatedAnimations[formatedAnimations.length - 1]
				last.animations = last.animations.filter(
					(item) => item.elId !== animation.elId
				)
				last.animations.push(animation)
				formatedAnimations[formatedAnimations.length - 1] = last
			} else if (animation.trigger === 'auto') {
				const last = formatedAnimations[formatedAnimations.length - 1]
				last.autoNext = true
				formatedAnimations[formatedAnimations.length - 1] = last
				formatedAnimations.push({
					animations: [animation],
					autoNext: false
				})
			}
		}
		return formatedAnimations
	},

	triggeredAnimations(state) {
		const triggeredAnimations: TriggeredAnimation = {}
		const currentSlide = state.slides[state.slideIndex]
		if (!currentSlide?.animations) return {}

		const els = currentSlide.elements
		const elIds = els.map((el) => el.id)
		const animations = currentSlide.animations.filter(
			(animation: PPTAnimation) => elIds.includes(animation.elId)
		)

		for (const animation of animations) {
			const formatedAnimations: FormatedAnimation[] = []
			const _formatedAnimation =
				triggeredAnimations[animation?.triggerSource || 'next_page']
			if (_formatedAnimation?.formatedAnimation) {
				formatedAnimations.push(..._formatedAnimation?.formatedAnimation)
			}
			animation.id = animation.id || uuidv4()
			animation.trigger = animation.trigger || 'click'
			animation.group = animation.group || 'in'
			if (animation.trigger === 'click' || !formatedAnimations.length) {
				formatedAnimations.push({
					animations: [animation],
					autoNext: false
				})
			} else if (animation.trigger === 'meantime') {
				const last = formatedAnimations[formatedAnimations.length - 1]
				last.animations = last.animations.filter(
					(item) => item.elId !== animation.elId
				)
				last.animations.push(animation)
				formatedAnimations[formatedAnimations.length - 1] = last
			} else if (animation.trigger === 'auto') {
				const last = formatedAnimations[formatedAnimations.length - 1]
				last.autoNext = true
				formatedAnimations[formatedAnimations.length - 1] = last
				formatedAnimations.push({
					animations: [animation],
					autoNext: false
				})
			}
			triggeredAnimations[animation?.triggerSource || 'next_page'] = {
				formatedAnimation: formatedAnimations,
				isReset: false,
				animationIndex: 0
			}
		}
		return triggeredAnimations
	},

	// layouts(state) {
	//   const {
	//     themeColor,
	//     fontColor,
	//     fontName,
	//     backgroundColor,
	//   } = state.theme

	//   const subColor = tinycolor(fontColor).isDark() ? 'rgba(230, 230, 230, 0.5)' : 'rgba(180, 180, 180, 0.5)'

	//   const layoutsString = JSON.stringify(layouts)
	//     .replaceAll('{{themeColor}}', themeColor)
	//     .replaceAll('{{fontColor}}', fontColor)
	//     .replaceAll('{{fontName}}', fontName)
	//     .replaceAll('{{backgroundColor}}', backgroundColor)
	//     .replaceAll('{{subColor}}', subColor)

	//   return JSON.parse(layoutsString)
	// },

	activeElementList(state) {
		const currentSlide = state.slides[state.slideIndex]
		if (!currentSlide || !currentSlide.elements) return []
		return currentSlide.elements.filter((element) =>
			state.activeElementIdList.includes(element.id)
		)
	},

	handleElement(state) {
		const currentSlide = state.slides[state.slideIndex]
		if (!currentSlide || !currentSlide.elements) return null
		return (
			currentSlide.elements.find(
				(element) => state.handleElementId === element.id
			) || null
		)
	},

	canUndo(state) {
		return state.snapshotCursor > 0
	},

	canRedo(state) {
		return state.snapshotCursor < state.snapshotLength - 1
	},

	ctrlOrShiftKeyActive(state) {
		return state.ctrlKeyState || state.shiftKeyState
	},
	snapshotDB(state) {
		let snapshotDb: SnapshotDatabase | undefined = undefined
		if (!snapshotDb) {
			snapshotDb = new SnapshotDatabase(state.uuid)
		} else {
			if (snapshotDb && (snapshotDb as SnapshotDatabase).uuid !== state.uuid) {
				console.log('1')
				;(snapshotDb as SnapshotDatabase).setSnapshotsUuid(state.uuid)
			}
		}
		return snapshotDb
	}
}
