import useQianKunEventCenter from '@/hooks/useQianKunEventCenter'
import { Modal } from 'ant-design-vue'
import { createVNode, getCurrentInstance, render } from 'vue'
import RelateCourseware from '../RelateCourseware.vue'
import useFetchApi from '@/hooks/useFetchApi'
import useShowMessage from '@/hooks/useShowMessage'

export default () => {
	const { fetchApi } = useFetchApi()
	const { showMeassage } = useShowMessage()
	const isMicroApp = !!window.__POWERED_BY_QIANKUN__
	const appContext = getCurrentInstance()!.appContext

	const {
		openLessonOrSpeakModal: openLessonOrSpeakModalByQianKun,
		cancelLessonOrSpeakRelate: cancelLessonOrSpeakRelateByQianKun,
		getNewPptDetails: getNewPptDetailsByQianKun
	} = useQianKunEventCenter()

	const openLessonOrSpeakModal = (type: 'lesson' | 'speak', extData = {}) => {
		if (isMicroApp) {
			return openLessonOrSpeakModalByQianKun(type, extData)
		}
		return new Promise<void>((resolve, reject) => {
			try {
				const container = document.createElement('div')
				const destroy = () => {
					render(null, container)
					container.remove()
				}
				const vNode = createVNode(
					Modal,
					{
						visible: true,
						appContext: appContext,
						footer: null,
						width: 600,
						title:
							type === 'lesson' ? '请选择要关联的教案' : '请选择要关联的说课',
						destroyOnClose: true,
						onCancel: () => {
							destroy()
							reject('config modal cancelled')
						},
						style: {
							top: '20px'
						}
					},
					{
						default: () => [
							createVNode(RelateCourseware, {
								type: type === 'lesson' ? 'plan' : type,
								extData,
								onSubmits: () => {
									destroy()
									resolve()
								}
							})
						]
					}
				)
				vNode.appContext = appContext
				render(vNode, container)
				document.body.appendChild(container)
			} catch (e) {
				reject(e)
			}
		})
	}

	const cancelLessonOrSpeakRelate = (
		type: 'lesson' | 'speak',
		relate_courseware_ids: any[],
		extData: any = {}
	) => {
		if (isMicroApp) {
			return cancelLessonOrSpeakRelateByQianKun(
				type,
				relate_courseware_ids,
				extData
			)
		}
		return new Promise<void>((resolve, reject) => {
			return fetchApi({
				url: `https://${process.env.VUE_APP_USER_API_HOST}/teacher/resource/teacher/courseware/${extData.coursewareId}/edit`,
				method: 'POST',
				data: {
					relate_courseware_ids
				}
			})
				.then(() => {
					showMeassage('取消关联成功', 'success')
					resolve()
				})
				.catch(() => {
					showMeassage('取消关联失败，请重试', 'error')
					reject()
				})
		})
	}

	const getNewPptDetails = (type: 'lesson' | 'speak') => {
		if (isMicroApp) {
			return getNewPptDetailsByQianKun(type)
		}
		return new Promise<unknown>((resolve) => {
			resolve({})
		})
	}

	return {
		openLessonOrSpeakModal,
		cancelLessonOrSpeakRelate,
		getNewPptDetails
	}
}
